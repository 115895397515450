import { useEffect, useState } from "react";
import { StyleSheet, FlatList, Image, ScrollView } from "react-native";
import { View, Text } from "../../constants/Themed";
import { IBadge, IMapping, IOpportunity } from "../../models/types";
import { RouteProp, useRoute } from "@react-navigation/native";
import { BoldText } from "../../constants/StyledText";
import Colors from "../../constants/Colors";
import ThumbnailOpportunity from "../../components/ThumbnailOpportunity";
import useColorScheme from "../../hooks/useColorScheme";
import BadgeIcon from "../../../assets/icons/Badge";
import {
  RootTabScreenProps,
  TimelinesStackParamList,
} from "../../navigation/types";
import { API, graphqlOperation } from "aws-amplify";
import { Storage } from "aws-amplify";
import BADGESAWARDED from "../../models/DummyQueryBadgesAwarded.json";
import { getOpportunity } from "./OpportunitiesDetail";
import { DrawerContentScrollView } from "@react-navigation/drawer";

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      surname
      firstName
      preferredFirstName
      title
      type
      photoURL

      email
      gender
      isActive
      Enrolments {
        nextToken
        startedAt
        items {
          contextData1
          contextData2
          contextData3
          groupingData1
          groupingData2
          groupingData3
          scoringData1
          scoringData2
          scoringData3
        }
      }
      AwardedBadges {
        nextToken
        startedAt
        items {
          id
          scoringData3Value
          dateAwarded
        }
      }

      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export default function TimelinesDetail() {
  const route =
    useRoute<RouteProp<TimelinesStackParamList, "TimelinesDetail">>();

  const { userIDForQuery } = route.params;

  //the cloud query for a list (that has a filter applied here)
  const [userData, setUserData] = useState([]);
  const [downloadedImageURI, setDownloadedImageURI] = useState<string | null>(
    null
  ); //state variable for imageURI
  const [enrolmentData, setEnrolmentData] = useState(null);
  const [awardedBadgesData, setAwardedBadgesData] = useState([]);
  const fetchUserData = async () => {
    const response = await API.graphql(
      graphqlOperation(getUser, { id: userIDForQuery })
    );

    setUserData(response.data.getUser);
    setEnrolmentData(response.data.getUser.Enrolments.items[0]);
    setAwardedBadgesData(response.data.getUser.AwardedBadges.items);
    const file = await Storage.get(response.data.getUser.photoURL, {
      level: "public",
    }); //get file using query data
    setDownloadedImageURI(file); //store imageURI in state variable to be used elsewhere on the page
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const { preferredFirstName, surname } = userData;
  const {
    contextData1,
    contextData2,
    contextData3,
    groupingData1,
    groupingData2,
    groupingData3,
    scoringData1,
    scoringData2,
    scoringData3,
  } = enrolmentData || {};
  //COULD NOT GET THIS TO WORK AS IT WAS RETURNING AN ARRAY OF OBJECTS BECUASE CHILDREN CAN HAVE MORE THAN ONE ENROLMENT

  // console.warn(awardedBadgesData);

  // console.warn(preferredFirstName)

  const colorScheme = useColorScheme();

  const calculateHousePoints = () => {
    //add up the points from awardedBadgesData array stored in scoringData3Value

    const housePointsForThisPupil = awardedBadgesData.reduce(
      (a, curr) => a + curr.scoringData3Value,
      0
    );
    return housePointsForThisPupil;
  };

  //filter out the badges from this year
  const badgesAwardedThisYear = awardedBadgesData.filter((item) => {
    //this really needs to convert the awardedDate string into an ISO date field then filter on a date range, but this works for now...!
    return (
      item.dateAwarded.startsWith("2022-09") ||
      item.dateAwarded.startsWith("2022-10") ||
      item.dateAwarded.startsWith("2022-11") ||
      item.dateAwarded.startsWith("2022-12") ||
      item.dateAwarded.startsWith("2023-01") ||
      item.dateAwarded.startsWith("2023-02") ||
      item.dateAwarded.startsWith("2023-03") ||
      item.dateAwarded.startsWith("2023-04") ||
      item.dateAwarded.startsWith("2023-05") ||
      item.dateAwarded.startsWith("2023-06") ||
      item.dateAwarded.startsWith("2023-07") ||
      item.dateAwarded.startsWith("2023-08")
    );
  });

  //compare the above filtered list with the rest of the data to get a list of all of the previous badges
  const badgesAwardedPreviously = awardedBadgesData.filter(
    ({ id: id1 }) => !badgesAwardedThisYear.some(({ id: id2 }) => id2 === id1)
  );

  return (
    // <ScrollView>
    <ScrollView
      style={[
        styles.root,
        { backgroundColor: Colors[colorScheme].almostBackground },
      ]}
    >
      <View
        style={[
          styles.topBox,
          {
            backgroundColor:
              // 'red'
              Colors[colorScheme].background,
          },
        ]}
      >
        <View
          style={[
            styles.metaDataContainer,
            // { backgroundColor: Colors[colorScheme].almostButtons },
          ]}
        >
          <View style={{backgroundColor:'transparent', marginRight:30}}>
            {/* pupils name */}
            <View style={styles.nameContainer}>
              <BoldText
                style={[{ color: Colors[colorScheme].text }, styles.badgeName]}
              >
                {preferredFirstName} {surname}
              </BoldText>
            </View>

            {/* Year group  and house */}
            <View
              style={{ marginVertical: 10, backgroundColor: "transparent" }}
            >
              <View style={styles.nameContainer}>
                <Text
                  style={{
                    color: Colors[colorScheme].textLessEmphasised,
                    fontSize: 15,
                  }}
                >
                  {groupingData3 ? groupingData3 : ''} • {groupingData1 ? groupingData1 : ''} • {groupingData2 ? groupingData2 : ''}
                  {/* {enrolmentData[0]?.groupingData3} • {enrolmentData[0]?.groupingData1} • {enrolmentData[0]?.groupingData2} */}
                  {/* {enrolmentData[0].groupingData1} {enrolmentData[0].groupingData2} {enrolmentData[0].groupingData3} */}
                </Text>
              </View>
            </View>
            {/* house points, volunteering hours, attendance */}
            <View
              style={{
                marginVertical: 10,
                // marginBottom:100,
                backgroundColor: "transparent",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <View
                style={{
                  borderRadius: 5,
                  borderColor: Colors.palette.grey,
                  borderWidth: 1,
                  alignItems: "center",
                  padding: 5,
                }}
              >
                <BoldText
                  style={{
                    color: Colors[colorScheme].text,
                    fontSize: 20,
                  }}
                >
                  {calculateHousePoints()}
                </BoldText>
                <Text
                  style={{
                    color: Colors[colorScheme].textLessEmphasised,
                    fontSize: 10,
                  }}
                >
                  HOUSE POINTS
                </Text>
              </View>
            </View>
          </View>

          {/* pupil image */}
          <View style={{alignSelf:'center', backgroundColor:'transparent', alignItems:'center', flex:1}}>
          <Image
            source={{ uri: downloadedImageURI }}
            style={[styles.photo, { borderColor: Colors[colorScheme].edging }]}
          /></View>

          

          
        </View>
      </View>
      {/* awardedbadges */}

      <View
        style={{
          backgroundColor: Colors[colorScheme].almostBackground,
          width: 800,
          padding: 20,
          alignSelf: "center",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            // marginVertical: 20,
            backgroundColor: "transparent",
          }}
        >
          <View style={styles.iconContainer}>
            <BadgeIcon color={Colors.palette.logoGreen} />
          </View>
          <BoldText
            style={{
              color: Colors[colorScheme].text,
              fontSize: 18,
              alignSelf: "center",
            }}
          >
            {preferredFirstName}'s Badges
          </BoldText>
        </View>
        <View style={styles.yearLabelAndFlatlistContainer}>
          <View style={styles.yearLabelContainer}>
            <Text
              style={{
                color: Colors[colorScheme].text,
                marginRight: 20,
                alignSelf: "center",
              }}
            >
              2022 - 2023
            </Text>
          </View>
          <FlatList
            // horizontal={true}
            scrollEnabled={false}
            numColumns={5}
            data={badgesAwardedThisYear}
            renderItem={({ item }) => (
              <ThumbnailOpportunity opportunityToDownload={item} />
            )}
            keyExtractor={(item) => item.id}
          />
        </View>
        <View style={styles.yearLabelAndFlatlistContainer}>
          <View style={styles.yearLabelContainer}>
            <Text
              style={{
                color: Colors[colorScheme].text,
                marginRight: 20,
                alignSelf: "center",
              }}
            >
              Previous years
            </Text>
          </View>
          <FlatList
            // horizontal={true}
            scrollEnabled={false}
            numColumns={5}
            data={badgesAwardedPreviously}
            renderItem={({ item }) => (
              <ThumbnailOpportunity opportunityToDownload={item} />
            )}
            keyExtractor={(item) => item.id}
          />
        </View>
      </View>

      {/* shows a couple of sample badges */}
      {/* <FlatList
        horizontal={true}
        data={BADGESAWARDED}
        renderItem={({ item }) => <ThumbnailOpportunity opportunity={item} />}
        keyExtractor={(item) => item.id}
      /> */}
    </ScrollView>
    // {/* </ScrollView> */}
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    // paddingHorizontal: 20,
    // backgroundColor: "transparent",
  },
  topBox: {
    // borderBottomLeftRadius: 10,
    // borderBottomRightRadius: 10,
    borderRadius: 10,
    width: 800,
    padding: 20,
    alignSelf: "center",
    alignItems: "center",
  },
  iconContainer: {
    width: 50,
    height: 50,
    backgroundColor: "transparent",
  },
  descriptionContainer: {
    marginBottom: 20,
  },
  mappingRowContainer: {
    flexDirection: "row",
  },
  nameContainer: {
    marginHorizontal: 20,
    marginVertical: 0,
    backgroundColor: "transparent",
    alignItems: "center",
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    marginRight: 10,
  },
  metaDataPairContainer: {
    flexDirection: "row",
    backgroundColor: "transparent",
    // alignItems: 'center',
    alignSelf: "center",
    marginHorizontal: 20,
    marginVertical: 5,
  },
  badgeName: {
    fontSize: 30,
  },
  yearLabelContainer: {
    width: 150,
    height: 150,
    backgroundColor: "transparent",
    top: 50,
  },
  metaData: {
    fontSize: 18,
  },
  yearLabelAndFlatlistContainer: {
    flexDirection: "row",
    padding: 10,
    backgroundColor: "transparent",
  },
  photo: {
    height: 250,
    width: 250,
    borderRadius: 200,
    // alignSelf: "center",
    // marginLeft: 50,
    borderWidth: 1,
  },
  metaDataContainer: {
    // justifyContent: "space-between",
    // borderRadius: 10,
    // paddingBottom: 10,
    flex: 1,
    flexDirection: "row",
    // justifyContent: "center",
    alignItems: "center",
    marginBottom: 20,
    backgroundColor: "transparent",
  },
});
