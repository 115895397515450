import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Image,
  ScrollView,
  ActivityIndicator,
  Pressable,
  Linking,
} from "react-native";
import { View, Text } from "../../constants/Themed";
import { RouteProp, useRoute } from "@react-navigation/native";
import { BoldText } from "../../constants/StyledText";
import Colors from "../../constants/Colors";
import MappedCriteria from "../../components/desktop/MappedCriteria";
import useColorScheme from "../../hooks/useColorScheme";
import { RootStackParamList } from "../../navigation/types";

//these imports are for the cloud query
import { API, graphqlOperation } from "aws-amplify";
import DynamicBadge from "../../components/DynamicBadge";
//this import is for getting the image out of storage
import { Storage } from "aws-amplify";
import TimelineIcon from "../../../assets/icons/Timeline";

export const getOpportunity = /* GraphQL */ `
  query GetOpportunity($id: ID!) {
    getOpportunity(id: $id) {
      id
      isActive
      name
      description
      logoURL
      availableTo
      location
      colour
      
      opportunitycategoryID
      OpportunityCategory {
        id
        name
        logoWhiteURI
        logoColourURI
        logoGreyURI
        colour
      }
      engagementLevel
      regularMonday
      regularTuesday
      regularWednesday
      regularThursday
      regularFriday
      regularStartTime
      regularEndTime
      dateType
      oneOffDate
      regularStartDat
      regularEndDate
      multipleStartDate
      multipleEndDate
      totalHours
      userID
      User {
        id
        surname
        firstName
        preferredFirstName
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Mappings {
        items {
          Criteria {
            id
            name
            logoWhiteURI
            colour
          }
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export default function OpportunityDetail() {
  const route = useRoute<RouteProp<RootStackParamList, "OpportunityDetail">>();
  const [isLoading, setIsLoading] = useState(true);

  const { opportunityID } = route.params; //this isn't type checked and is bad practice. According to the Istagram course by Vadim (video 4.3) we should only be sending the shoutout ID and then querying the database on the detail screen

  const [downloadedContactPhotoURI, setDownloadedContactPhotoURI] = useState(); //state variable for imageURI

  const [downloadedImageURI, setDownloadedImageURI] = useState<string | null>(
    null
  ); //state variable for imageURI

  //the cloud query for a single record (that has a filter applied here)
  const [opportunitySingleRecordData, setOpportunitySingleRecordData] =
    useState([]);
  const [downloadedMappings, setDownloadedMappings] = useState([]);
  const fetchOpportunity = async () => {
    const response = await API.graphql(
      graphqlOperation(getOpportunity, {
        id: opportunityID,
      })
    );

    setOpportunitySingleRecordData(response.data.getOpportunity); //all data in a variable to be destructured further down
    setDownloadedMappings(response.data.getOpportunity.Mappings.items); //Mappings data in a variable

    const file = await Storage.get(response.data.getOpportunity.logoURL, {
      level: "public",
    }); //get file using query data

    setDownloadedImageURI(file); //store imageURI in state variable to be used elsewhere on the page

    const contactPhotoURL = await Storage.get(
      response.data.getOpportunity.User.photoURL,
      {
        level: "public",
      }
    );
    setDownloadedContactPhotoURI(contactPhotoURL);

    setIsLoading(false);
  };
  useEffect(() => {
    fetchOpportunity();
  }, []);

  const {
    id,
    name,
    description,
    logoURL,
    engagementLevel,
    Criteria,
    colour,
    multipleStartDate,
    oneOffDate,
    availableTo,
    Mappings,
    multipleEndDate,
    dateType,
    location,
    regularStartTime,
    regularMonday,
    regularTuesday,
    regularWednesday,
    regularThursday,
    regularFriday,
    OpportunityCategory: { name: categoryName } = {}, // Destructing the property name in the object opportunityCategory in the object pulled from the query, then sticking it in a new variable called cateogryName. Not sure why the = {} is needed at the end, but it is.
    User: {
      title: contactTitle,
      surname: contactSurname,
      email: contactEmail,
    } = {},
  } = opportunitySingleRecordData;

  //Timing line
  const DateTypeSelector = () => {
    switch (dateType) {
      case "REGULAR":
        return (
          <Text numberOfLines={1}>
            {regularStartTime} on{regularMonday ? " Monday" : null}
            {regularTuesday ? " Tuesday" : null}
            {regularWednesday ? " Wednesday" : null}
            {regularThursday ? " Thursday" : null}
            {regularFriday ? " Friday" : null}
          </Text>
        );

      case "MULTIPLEDATES":
        const mutipleStartDateObject = new Date(multipleStartDate);
        const multipleEndDateObject = new Date(multipleEndDate);
        const formattedMultipleStartDate =
          mutipleStartDateObject.toLocaleDateString("en-GB", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          });
        const formattedMultipleEndDate =
          multipleEndDateObject.toLocaleDateString("en-GB", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          });
        return (
          <Text numberOfLines={1}>
            {formattedMultipleStartDate} until {formattedMultipleEndDate}{" "}
          </Text>
        );

      case "ONEOFFDATE":
        const dateObject = new Date(oneOffDate);
        const formattedDate = dateObject.toLocaleDateString("en-GB", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        });

        return <Text numberOfLines={1}>{formattedDate}</Text>;
      default:
        return <Text>Error, no timing information</Text>;
    }
  };

  const colorScheme = useColorScheme();

  // navigation.setOptions({
  //   title:opportunity.name
  // })
  const emailString = `mailto:${contactEmail}?subject=${name}&body=Hi ${contactTitle} ${contactSurname}. I'm interested in signing up to ${name}. Please can you reply to this email with more information? Thanks!`;

  return (
    <ScrollView>
    <View style={styles.root}>
      {isLoading ? (
        <View
          style={{
            flex: 1,
            backgroundColor: "transparent",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ActivityIndicator size="large" color={Colors.palette.logoGreen} />
        </View>
      ) : (
        <>
          <View style={styles.headlineDetailsContainer}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 20,
              }}
            >
              <View style={styles.metaDataContainer}>
                <View style={styles.nameContainer}>
                  {/* Badge Name */}

                  <BoldText style={[styles.badgeName, {color:Colors[colorScheme].text}]}>{name}</BoldText>
                </View>
                {availableTo ? 
                <View style={styles.metaDataPairContainer}>
                  <Text
                    style={[
                      styles.title,
                      { color: Colors[colorScheme].textLessEmphasised },
                    ]}
                  >
                    Available to:
                  </Text>
                  <Text
                    style={[
                      styles.metaData,
                      { color: Colors[colorScheme].text },
                    ]}
                  >
                    {availableTo}
                  </Text>
                </View>: null}
                <View style={styles.metaDataPairContainer}>
                  <Text
                    style={[
                      styles.title,
                      { color: Colors[colorScheme].textLessEmphasised },
                    ]}
                  >
                    Category:
                  </Text>
                  <Text
                    style={[
                      styles.metaData,
                      { color: Colors[colorScheme].text },
                    ]}
                  >
                    {categoryName}
                  </Text>
                </View>


                    {location ? 
                  <View style={styles.metaDataPairContainer}>
                  <Text
                    style={[
                      styles.title,
                      { color: Colors[colorScheme].textLessEmphasised },
                    ]}
                  >
                    Location:
                  </Text>
                  <Text
                    style={[
                      styles.metaData,
                      { color: Colors[colorScheme].text },
                    ]}
                  >
                    {location}
                  </Text>
                </View>:null  
                  }
                





                <View style={styles.metaDataPairContainer}>
                  <Text
                    style={[
                      styles.title,
                      { color: Colors[colorScheme].textLessEmphasised },
                    ]}
                  >
                    Date:
                  </Text>
                  <Text
                    style={[
                      styles.metaData,
                      { color: Colors[colorScheme].text },
                    ]}
                  >
                    {DateTypeSelector()}
                  </Text>
                </View>
                <View
              style={{
                
                justifyContent: "space-between",
                flexDirection: "row",
                flex: 1,
                backgroundColor: "transparent",
                marginVertical: 10,
                // marginHorizontal: 20,
              }}
            >
              <View style={styles.metaDataPairContainer}>
              <Text
                    style={[
                      styles.title,
                      { color: Colors[colorScheme].textLessEmphasised },
                    ]}
                  >
                  Contact:
                </Text>

                <View>
                  <Text
                    style={[
                      styles.metaData,
                      { color: Colors[colorScheme].textLessEmphasised },
                    ]}
                  >
                    {contactTitle} {contactSurname}
                  </Text>

                  <Pressable onPress={() => Linking.openURL(emailString)}>
                    <Text style={styles.hyperlink}>{contactEmail}</Text>
                  </Pressable>
                </View>
                <View style={{ width: 50, height: 50, marginHorizontal: 20 }}>
                  <TimelineIcon color={"#ccc"} />
                  <Image
                    style={[
                      styles.contactPhoto,
                      { borderColor: Colors[colorScheme].edging },
                    ]}
                    source={{ uri: downloadedContactPhotoURI }}
                  />
                </View>
              </View>
            </View>
              </View>

              {/* badge image */}
              <View style={styles.badgeIcon}>
                {/* Badge logo */}
                <DynamicBadge
                  size={200}
                  edgeColour={colour}
                  engagementLevel={3}
                  logo={downloadedImageURI}
                />
              </View>
            </View>

            <View style={styles.descriptionContainer}>
              <Text>{description}</Text>
            </View>
          </View>

          <View
            style={[
              styles.descriptionContainer,
              // { backgroundColor: Colors[colorScheme].almostButtons },
            ]}
          >
            

            <BoldText
              style={[
                styles.title,
                { color: Colors[colorScheme].text, marginBottom: 10 },
              ]}
            >
              Character attributes you will develop:
            </BoldText>
          </View>

          <ScrollView
            horizontal
            style={[
              styles.mappingRowContainer,
              { backgroundColor: Colors[colorScheme].almostBackground },
            ]}
          >
            {downloadedMappings.map((item) => (
              <MappedCriteria key={item.id} mapping={item} />
            ))}
          </ScrollView>
        </>
      )}
    </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    // alignItems: "center",
    backgroundColor: "transparent",
    // backgroundColor: 'red',
    width: 800,
    alignSelf: "center",
  },
  descriptionContainer: {
    marginTop: 20,
    backgroundColor: "transparent",
  },
  mappingRowContainer: {
    flexDirection: "row",
  },
  nameContainer: {
    marginBottom: 20,
    flex: 1,
    maxWidth: 500,
    // width: '50%'
  },
  contactPhoto: {
    width: 50,
    height: 50,
    borderRadius: 25,
    borderWidth: 1,
    position: "absolute",
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    marginRight: 10,
  },
  metaDataPairContainer: {
    flexDirection: "row",
    marginVertical: 5,
  },
  badgeName: {
    fontSize: 30,
  },
  metaData: {
    fontSize: 16,
    padding: 2,
  },
  hyperlink: {
    color: "blue",
    textDecorationLine: "underline",
    // marginLeft: 10,
  },
  metaDataContainer: {
    backgroundColor: "transparent",
    justifyContent: "center",
  },
  badgeIcon: {
    alignSelf: "center",
    height: 200,
    width: 200,
    marginBottom: 20,
  },
  headlineDetailsContainer: {
    borderRadius: 10,
    padding: 30,
  },
});
