import {AmplifyTheme} from 'aws-amplify-react-native'
import Colors from "../../src/constants/Colors";

const customNativeAppTheme = {...AmplifyTheme, 
  sectionHeaderText:{
    ...AmplifyTheme.sectionHeaderText,
    color: Colors.palette.logoGreen,
    // fontFamily: "avenir-next-bold",
  },
  input:{
    ...AmplifyTheme.input,
    borderRadius: 10,
    // fontFamily: "avenir-next",
    color: Colors.palette.darkGrey,
  },
  button: {
    ...AmplifyTheme.button,
    backgroundColor: Colors.palette.logoGreen,
    borderRadius: 10,
  },
  buttonDisabled: {
    ...AmplifyTheme.buttonDisabled,
    backgroundColor: Colors.palette.menuInactive,
    borderRadius: 10,
  },

  buttonText: {
    ...AmplifyTheme.buttonText,
    // fontFamily: "avenir-next",
    fontSize: 18,
  },
  sectionFooterLink:{
    ...AmplifyTheme.sectionFooterLink,
    // fontFamily: "avenir-next",
    fontSize: 16,
    color: Colors.palette.logoGreen,
  },
  sectionFooterLinkDisabled:{
    ...AmplifyTheme.sectionFooterLinkDisabled,
    color: Colors.palette.menuInactive,
    // fontFamily: "avenir-next",
    fontSize: 16,
  },
  errorRowText:{
    ...AmplifyTheme.errorRowText,
    // fontFamily: "avenir-next",
    fontSize: 14,
    
  },
  errorRow:{
    ...AmplifyTheme.errorRow,
    alignItems: 'center',
  },
  errorRowIcon:{
    ...AmplifyTheme.errorRowIcon,
    height: 40,
    width: 40,
  },
  inputLabel:{
    ...AmplifyTheme.inputLabel,
    color: Colors.palette.darkGrey,
  },
  signedOutMessage:{
    ...AmplifyTheme.signedOutMessage,
    color: '#fff',
  },
  
};

const customBrowserTheme = {...AmplifyTheme, 
  sectionHeaderText:{
    ...AmplifyTheme.sectionHeaderText,
    color: Colors.palette.logoGreen,
    fontSize: 20,
    // fontFamily: "avenir-next",
  },
  input:{
    ...AmplifyTheme.input,
    borderRadius: 10,
    padding: 16,
		borderWidth: 1,
		borderColor: Colors.palette.grey,
		color: Colors.palette.darkGrey,
  },
  button: {
    ...AmplifyTheme.button,
    backgroundColor: Colors.palette.logoGreen,
    borderRadius: 10,
    alignItems: 'center',
		padding: 16,
  },
  buttonDisabled: {
    ...AmplifyTheme.buttonDisabled,
    backgroundColor: Colors.palette.menuInactive,
    borderRadius: 10,
    alignItems: 'center',
		padding: 16,
  },
  buttonText: {
    ...AmplifyTheme.buttonText,
    // fontFamily: "avenir-next",
    fontSize: 18,
    color: '#fff',
		fontWeight: '600',
  },
  sectionFooterLink:{
    ...AmplifyTheme.sectionFooterLink,
    // fontFamily: "avenir-next",
    fontSize: 16,
    color: Colors.palette.logoGreen,
		alignItems: 'baseline',
		textAlign: 'center',
  },
  sectionFooterLinkDisabled:{
    ...AmplifyTheme.sectionFooterLinkDisabled,
    color: Colors.palette.menuInactive,
    // fontFamily: "avenir-next",
    fontSize: 16,
		alignItems: 'baseline',
		textAlign: 'center',
  },
  errorRowText:{
    ...AmplifyTheme.errorRowText,
    // fontFamily: "avenir-next",
    fontSize: 14,
    marginLeft: 10,
  },
  errorRow:{
    ...AmplifyTheme.errorRow,
    alignItems: 'center',
    flexDirection: 'row',
		justifyContent: 'center',
  },
  errorRowIcon:{
    ...AmplifyTheme.errorRowIcon,
    height: 40,
    width: 40,
  },
  inputLabel:{
    ...AmplifyTheme.inputLabel,
    color: Colors.palette.darkGrey,
    marginBottom: 8,
  },
  signedOutMessage:{
    ...AmplifyTheme.signedOutMessage,
    color: '#fff',
    textAlign: 'center',
		padding: 20,
  },
};





export default {
  customNativeAppTheme,
  customBrowserTheme,
};
