import {
  StyleSheet,
  Image,
  Modal,
  ActivityIndicator,
  useWindowDimensions,
  ScrollView,
  TextInput,
  FlatList,
  Pressable,
  Switch,
  Button,
  Alert,
} from "react-native";
import { UserContext } from "../../components/Context";
import React, { useState, useEffect, useContext } from "react";

import UserList from "../../components/desktop/MasterListUsers";



const AdminUserManagement = ({ navigation }) => {
  

  return (
    
    <UserList />
  );
};
export default AdminUserManagement;

const styles = StyleSheet.create({
  textInputBox: {
    borderWidth: 1,
    margin: 10,
    padding: 10,
    borderRadius: 8,
    fontSize: 16,
    fontFamily: "avenir-next",
    width: 280,
  },
  textInputLabel: { fontSize: 16, marginHorizontal: 10, fontWeight: "800" },
  textInputContainer: {
    flexDirection: "row",
    padding: 5,
    width: 600,
    backgroundColor: "transparent",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  textInputLabelAndIconContainer: {
    backgroundColor: "tranparent",
    flexDirection: "row",
    alignItems: "flex-end",
    width: 150,
  },
  switchRowContainer: {
    backgroundColor: "transparent",
    flexDirection: "row",
    flex: 1,
    padding: 5,
    // alignItems: "s",
    justifyContent: "flex-start",
    alignSelf: "stretch",
    marginLeft: 20,
  },
  switchGroupContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    margin: 10,
    padding: 10,
    borderRadius: 8,
    fontSize: 16,
    width: 300,
  },
  modalContainer: {
    flex: 1,
    // backgroundColor: 'rgba(0, 0, 0, 0.5)',
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  modalImage: {
    width: "100%",
    aspectRatio: 1,
  },
  switchLabel: { fontSize: 16, marginHorizontal: 40 },
  tagIcon: {
    position: "absolute",
    top: 20,
    right: 25,
    backgroundColor:'transparent'
  },
});
