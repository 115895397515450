import {
  StyleSheet,
  Image,
  Modal,
  ActivityIndicator,
  ScrollView,
  TextInput,
  FlatList,
  Pressable,
  Button,
  Alert,
} from "react-native";
import { UserContext } from "../../components/Context";
import React, { useState, useEffect, useContext, useMemo } from "react";
import { BoldText } from "../../constants/StyledText";
import { Text, View } from "../../constants/Themed";
import Colors from "../../constants/Colors";
import useColorScheme from "../../hooks/useColorScheme";
import { API, graphqlOperation, Storage } from "aws-amplify";
import * as ImagePicker from "expo-image-picker";
import { Feather } from "@expo/vector-icons";
import { Picker } from "@react-native-picker/picker";
import SearchBarMobile from "../../components/mobile/SearchBar";
import SignedURLImage from "../../components/SignedURLImage";

//note, you have removed the line about limit from the top of this query (where it gets defined as an int) and added a limit of 2000. If you don't do this, you don't get all of the results!
export const listUsers = /* GraphQL */ `
  query ListUsers($filter: ModelUserFilterInput, $nextToken: String) {
    listUsers(filter: $filter, limit: 9999, nextToken: $nextToken) {
      items {
        id
        surname
        firstName
        aaa
        preferredFirstName
        preferredSurname
        type
        photoURL
        isActive
        Enrolments {
          items {
            groupingData1
            groupingData2
            groupingData3
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const createMedia = /* GraphQL */ `
  mutation CreateMedia(
    $input: CreateMediaInput!
    $condition: ModelMediaConditionInput
  ) {
    createMedia(input: $input, condition: $condition) {
      id
      s3Key
      momentID
      organisationID

      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      mediaID

      userID

      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createMoment = /* GraphQL */ `
  mutation CreateMoment(
    $input: CreateMomentInput!
    $condition: ModelMomentConditionInput
  ) {
    createMoment(input: $input, condition: $condition) {
      id
      dateUploaded
      dateMomentHappened
      caption
      contributorID
      actuallyUploadedByID

      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
const AdminAddShoutOut = ({ navigation }) => {
  const userDetailsFromUserContext = useContext(UserContext);
  const colorScheme = useColorScheme();
  const [clicked, setClicked] = useState(false);
  const [caption, setCaption] = useState("");
  const [momentDateDay, setMomentDateDay] = useState("");
  const [momentDateMonth, setMomentDateMonth] = useState("");
  const [momentDateYear, setMomentDateYear] = useState("");
  const [momentDate, setMomentDate] = useState("");
  const [triggerWarning, setTriggerWarning] = useState(false);
  const [tagged, setTagged] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [userArrayJustStaff, setUserArrayJustStaff] = useState([]);
  const [searchUsers, setSearchUsers] = useState("");
  const [search, setSearch] = useState("");
  const [filteredDataUsers, setFilteredDataUsers] = useState([]);
  const [clickedUsers, setClickedUsers] = useState(false);
  const [staffLeader, setStaffLeader] = useState([]);
  const today = new Date().toISOString().slice(0, 10);
  const [arrayOfImageURIs, setArrayOfImageURIs] = useState([]);
  const [modalVisible, setModalVisible] = useState(false); // to control the visibility of the modal
  const [selectedImage, setSelectedImage] = useState(null); // to store the selected image
  const [currentIndex, setCurrentIndex] = useState(0); // to store the current index of the selected image
  const [taggedUsers, setTaggedUsers] = useState({});
  const [fileKey, setFileKey] = useState("");
  const [uploadingImages, setUploadingImages] = useState(false);
  const [successfulUploads, setSuccessfulUploads] = useState([]);
  // const imageList = useMemo(() => arrayOfImageURIs.map(...), [arrayOfImageURIs]);
  const [iDofCurrentImage, setIDofCurrentImage] = useState(null);
  //  generate ID for moment
  const momentPrefix = "CARRMANOR-MO-";
  const randomNumber = Math.floor(Math.random() * 10000000);
  const [momentID, setMomentID] = useState(
    momentPrefix.concat(Date.now(), "-", randomNumber)
  );

  const fetchImageFromS3 = async (s3Key) => {
    const file = await Storage.get(s3Key, {
      level: "public",
    });
    return file; //store imageURI in state variable to be used elsewhere on the page
  };

  // Function to tag a user in an image
  const tagUser = (user, iDofCurrentImage) => {
    // Create a copy of the taggedUsers object
    const newTaggedUsers = { ...taggedUsers };
    // If there are no previously tagged users for the selected image,
    // create an empty array for the image
    if (!newTaggedUsers[iDofCurrentImage]) {
      newTaggedUsers[iDofCurrentImage] = [];
    }
    // Check if the user is already in the array
    if (newTaggedUsers[iDofCurrentImage].find((u) => u.id === user.id)) {
      // Show an alert if the user is already in the array
      alert("You can't add the same user twice!");
    } else {
      // Add the user to the array if they are not already in it
      newTaggedUsers[iDofCurrentImage].push(user);
    }
    console.warn("newTaggedUsers", newTaggedUsers);
    // Update the taggedUsers state variable with the updated object
    setTaggedUsers(newTaggedUsers);
    console.warn("taggedUSERS:", taggedUsers);
    setSearchUsers("");
  };

  const removeUser = (user, iDofCurrentImage) => {
    const newTaggedUsers = { ...taggedUsers };
    if (newTaggedUsers[iDofCurrentImage]) {
      // Filter the array to exclude the user with the specified ID
      newTaggedUsers[iDofCurrentImage] = newTaggedUsers[
        iDofCurrentImage
      ].filter((u) => u.id !== user.id);
    }
    setTaggedUsers(newTaggedUsers);
  };

  const TaggedImage = ({ image }) => {
    const [hasTags, setHasTags] = useState(false);

    // Function to delete the image from the array and any associated taggings
    const deleteImage = () => {
      setArrayOfImageURIs(
        arrayOfImageURIs.filter((i) => i.mediaID !== image.mediaID)
      );
      const newTaggedUsers = { ...taggedUsers };
      const keys = Object.keys(newTaggedUsers);
      if (keys.includes(image.mediaID)) {
        delete newTaggedUsers[image.mediaID];
      }
      setTaggedUsers(newTaggedUsers);
    };

    useEffect(() => {
      console.warn("TAGGEDUSERS:", taggedUsers);
      const hasTags =
        image.mediaID in taggedUsers && taggedUsers[image.mediaID].length > 0;
      setHasTags(hasTags);
    }, [image.mediaID]);

    //     useEffect(()=>{
    // console.warn('USEEFFECTFIRED!')
    //       setFileKey('/public/' + selectedImage)
    //     }, [selectedImage, fileKey])

    return (
      <View
        style={{
          width: 150,
          backgroundColor: "transparent",
          aspectRatio: 1,
          margin: 10,
          justifyContent: "center",
          overflow: "hidden",
          borderColor: Colors[colorScheme].edging,
          borderWidth: 1,
          borderRadius: 10,
        }}>
        {image.s3Key ? (
          <SignedURLImage
            filter={"/filters:quality(100)//fit-in/150x150"}
            // filter={"/filters:quality(50)/filters:grayscale()/fit-in/100x100"}
            fileKey={"/public/" + image.s3Key}
          />
        ) : (
          <ActivityIndicator />
        )}
        {image.s3Key ? (
          <View
            style={{
              position: "absolute",
              bottom: 10,
              right: 10,
              backgroundColor: "transparent",
            }}>
            <Pressable onPress={deleteImage}>
              <Feather
                name="trash-2"
                size={24}
                color={Colors.palette.softRed}
              />
            </Pressable>
          </View>
        ) : null}
        {/* If the image has tags, display the tag icon */}
        {hasTags && (
          <View style={styles.tagIcon}>
            <Feather name="tag" size={24} color={Colors.palette.logoGreen} />
          </View>
        )}
      </View>
    );
  };

  const uploadImage = async (image: string) => {
    const generatedImageFileName =
      "organisation-assets/carrmanor/momentsMedia/MoImage" +
      "-D" +
      Date.now() +
      "-U" +
      userDetailsFromUserContext.surname +
      "-R" +
      Math.floor(Math.random() * 1000001) +
      ".jpg";
    try {
      const response = await fetch(image);
      const blob = await response.blob();
      const s3ResponseKey = await Storage.put(generatedImageFileName, blob);
      return s3ResponseKey.key;
    } catch (e) {
      Alert.alert("error uploading file");
    }
  };

  const uploadImages = (images) => {
    // Iterate over the items in the images array
    images.forEach(async (image) => {
      //this probably doesn't need to be async anymore
      // Call the uploadImage function with the current image URL as an argument
      // const s3ResponseKey = await uploadImage(image.uri);

      //prepare the media metadata for the database
      const newMediaToUpload = {
        id: image.mediaID,
        // key: s3ResponseKey.key, //you can remove the .key if you are using the new method of uploading
        key: image.s3Key, //this is untested
        momentID: momentID,
        userID: userDetailsFromUserContext.id,
      };

      //validate the data in the object before uploading

      //write to database function goes here
      console.warn("newMediaToUpload:", newMediaToUpload);
    });
  };

  const submit = async () => {
    function validateMoment(moment) {
      // check dateUploaded field
      if (!/^\d{4}-\d{2}-\d{2}$/.test(moment.dateUploaded)) {
        alert(
          "Invalid date uploaded. Please enter a valid date in the format yyyy-mm-dd."
        );
        return false;
      }

      // check dateMomentHappened field
      if (!/^\d{4}-\d{2}-\d{2}$/.test(moment.dateMomentHappened)) {
        alert(
          "Invalid date moment happened. Please enter a valid date in the format yyyy-mm-dd."
        );
        return false;
      }

      // check contributor field
      if (!moment.contributorID) {
        alert("Invalid contributor. Contributor cannot be empty.");
        return false;
      }

      // check caption field
      if (!moment.caption || moment.caption.trim() === "") {
        alert("Invalid caption. Caption cannot be empty.");
        return false;
      }

      // check actuallyUploadedBy field
      if (!moment.actuallyUploadedByID) {
        alert("Invalid uploader. Uploader cannot be empty.");
        return false;
      }

      // if all validation checks pass, return true
      return true;
    }

    //create the object for the new moment
    if (staffLeader.length > 0) {
      const newMomentToUpload = {
        id: momentID,
        dateUploaded: today,
        dateMomentHappened: momentDate,
        caption: caption,
        contributorID: staffLeader[0].id,
        actuallyUploadedByID: userDetailsFromUserContext.id,
      };

      if (validateMoment(newMomentToUpload)) {
        //if the validation is passed, upload the new moment to the DB

        console.warn("moment valid:", newMomentToUpload);
        //this is where the upload code goes

        const writeMomentToDatabase = async () => {
          try {
            await API.graphql({
              query: createMoment,
              variables: { input: newMomentToUpload },
            });
            console.warn("async write function for opportunity table called");
            setSuccessfulUploads((prevSuccessfulUploads) => [
              ...prevSuccessfulUploads,
              newMomentToUpload,
            ]);
          } catch (err) {
            console.error("Error uploading moment to database: ", err);
            alert(
              "An error occurred while uploading the moment to the database. Please try again later."
            );
          }
        };

        //write the new moment back to the database
        writeMomentToDatabase();
      }

      //now move on to dealing with the images
      if (arrayOfImageURIs.length > 0) {
        const organisationID = "e5b5c6c5-9a99-45cf-9bb2-c94764a6ca6c";
        const uploadMedia = async (mediaObject) => {
          try {
            await API.graphql({
              query: createMedia,
              variables: { input: mediaObject },
            });
            console.warn("media uploaded: ", mediaObject);
            setSuccessfulUploads((prevSuccessfulUploads) => [
              ...prevSuccessfulUploads,
              mediaObject,
            ]);
          } catch (err) {
            console.error("Error uploading media: ", err);
            // you can use alert(err) to show the error message to the user
          }
        };

        for (let i = 0; i < arrayOfImageURIs.length; i++) {
          const mediaObject = {
            id: arrayOfImageURIs[i].mediaID,
            s3Key: arrayOfImageURIs[i].s3Key,
            momentID: momentID,
            organisationID: organisationID,
          };
          uploadMedia(mediaObject);
        }

        //now move on to dealing with the taggings
        console.warn("taggedUsers", taggedUsers);

        const uploadTagsToDB = async () => {
          try {
            for (const [mediaID, tags] of Object.entries(taggedUsers)) {
              for (const tag of tags) {
                const input = {
                  //id: tag.id,
                  mediaID,
                  userID: tag.id,
                };
                await API.graphql({
                  query: createTag,
                  variables: { input },
                });
                setSuccessfulUploads((prevSuccessfulUploads) => [
                  ...prevSuccessfulUploads,
                  input,
                ]);
              }
            }
            console.log("Tags uploaded successfully");
            console.log("SUCCESSFULLY UPLOADED:", successfulUploads);
          } catch (error) {
            console.error(`Error uploading tags: ${error}`);
          }
        };

        uploadTagsToDB();
        setSuccessfulUploads(["fred"]);
        navigation.navigate("AdminUploadConfirmation", {
          sU: successfulUploads,
        });
      } else {
        alert("You need to select some images first!");
      }
    } else {
      alert("You must have a member of staff!");
    }
  };

  useEffect(() => {
    fetchUsersList();
  }, []);

  useEffect(() => {
    console.warn("FILE KEY!!!", fileKey);
  }, [fileKey]);

  const openLightbox = (id) => {
    setIDofCurrentImage(id);
    console.warn("ID:", id);
    const index = arrayOfImageURIs.findIndex((image) => image.mediaID === id); // find the index of the image with the given ID
    console.warn("index:", index);
    setModalVisible(true);
    setSelectedImage(arrayOfImageURIs[index].s3Key);
    setCurrentIndex(index);
    setFileKey(arrayOfImageURIs[index].s3Key);
    console.warn("arrayOfImageURIs", arrayOfImageURIs);
    console.warn("selectedImage:", selectedImage);
    console.warn("iDofCurrentImage", iDofCurrentImage);
  };

  const closeLightbox = () => {
    console.warn("taggedUSERS:", taggedUsers);
    setModalVisible(false);
    setSelectedImage(null);
  };

  const fetchUsersList = async () => {
    const response = await API.graphql(
      graphqlOperation(listUsers, {
        filter: {
          type: {
            ne: "ALUMNI",
            // eq: "CHILD"
          },
        },
      })
    );

    setFilteredData(response.data.listUsers.items);
    setMasterData(response.data.listUsers.items);
    setUserArrayJustStaff(
      response.data.listUsers.items.filter(
        (item) => item.type === "STAFF" || item.type === "ADMIN"
      )
    );
  };
  // const selectedImageURLs = new Set();
  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1, //apparently, 0.2 is default?
      allowsMultipleSelection: true,
    });
    console.log(result);
    if (!result.cancelled) {
      // Generate an ID for each image and add it to the arrayOfImageURIs array along with the image URL
      const images = result.selected.map((image) => {
        const mediaID =
          "CARRMANOR-MEDIA-" +
          Date.now() +
          "-" +
          Math.floor(Math.random() * 10000000) +
          userDetailsFromUserContext.id;

        return {
          mediaID,
          uri: image.uri,
          s3Key: null,
          downloadedFile: null,
        };
      });

      // Check if the image already exists in the array before adding it
      const newImages = images.filter((img) => {
        return !arrayOfImageURIs.some((arrayImg) => arrayImg.uri === img.uri);
      });

      setArrayOfImageURIs([...arrayOfImageURIs, ...newImages]);
    }
  };

  useEffect(() => {
    console.warn("USEEFFECT FOR ARRAYOFIMAGEURIS:", arrayOfImageURIs);
    if (arrayOfImageURIs.length > 0) {
      setUploadingImages(true);
      const uploadPromises = arrayOfImageURIs.map(async (image) => {
        console.warn("mediaID:", image.mediaID);
        if (!image.s3Key) {
          console.warn("its null, get uploading!");
          image.s3Key = await uploadImage(image.uri);
          image.downloadedFile = await fetchImageFromS3(image.s3Key);
          console.warn(image);
        }
      });
      Promise.all(uploadPromises)
        .then(() => {
          setUploadingImages(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [arrayOfImageURIs]);

  //combine the one off dates into a single string when any of them change
  useEffect(() => {
    setMomentDate(
      momentDateYear.concat("-", momentDateMonth, "-", momentDateDay)
    );
  }, [momentDateDay, momentDateMonth, momentDateYear]);

  //add to list of staff leaders
  const addToArray = (item) => {
    setStaffLeader((oldArray) => [...oldArray, item]);
  };

  //remove from staff leaders array
  const removeFromArray = (id) => {
    setSearch("");
    setStaffLeader((user) =>
      user.filter((obj) => {
        return obj.id !== id;
      })
    );
  };

  const RenderThumbnails = useMemo(
    () =>
      arrayOfImageURIs.map((image) => (
        <View
          key={image.mediaID}
          style={{ width: 180, backgroundColor: "transparent" }}>
          <Pressable
            style={{ backgroundColor: "transparent" }}
            onPress={() => openLightbox(image.mediaID)} // open the lightbox when the image is pressed
          >
            <TaggedImage image={image} />
          </Pressable>
        </View>
      )),
    [uploadingImages, arrayOfImageURIs, taggedUsers]
  );

  const searchFilter = (text) => {
    if (text) {
      const newData = userArrayJustStaff.filter((item) => {
        const itemData = item.aaa ? item.aaa.toUpperCase() : "".toLowerCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });

      setFilteredData(newData);
      setSearch(text);
    } else {
      setFilteredData(userArrayJustStaff);
      setSearch(text);
    }
  };

  const searchFilterUsers = (text) => {
    if (text) {
      const terms = text.toLowerCase().split(" ");
      const newData = masterData.filter((item) => {
        return terms.every((term) =>
          `${item.preferredSurname} ${item.surname} ${item.preferredFirstName} ${item.firstName}`
            .toLowerCase()
            .includes(term)
        );
      });

      // const newData = masterData.filter((item) => {
      //   const itemData = item.aaa ? item.aaa.toUpperCase() : "".toLowerCase();
      //   const textData = text.toUpperCase();
      //   return itemData.indexOf(textData) > -1;
      // });

      setFilteredDataUsers(newData);
      setSearchUsers(text);
    } else {
      setFilteredDataUsers(masterData);
      setSearchUsers(text);
    }
  };

  const prevImage = () => {
    setSearchUsers("");
    const prevIndex =
      (currentIndex + arrayOfImageURIs.length - 1) % arrayOfImageURIs.length;

    const prevId = arrayOfImageURIs[prevIndex].id; // get the ID of the previous image
    setFileKey(arrayOfImageURIs[prevIndex].s3Key);
    setCurrentIndex(prevIndex);
    setIDofCurrentImage(arrayOfImageURIs[prevIndex].mediaID);
    setSelectedImage(arrayOfImageURIs[prevIndex].s3Key); //was previously .uri
  };

  const nextImage = () => {
    setSearchUsers("");
    const nextIndex = (currentIndex + 1) % arrayOfImageURIs.length;

    const nextId = arrayOfImageURIs[nextIndex].id; // get the ID of the next image
    setFileKey(arrayOfImageURIs[nextIndex].s3Key);
    setCurrentIndex(nextIndex);
    setIDofCurrentImage(arrayOfImageURIs[nextIndex].mediaID);
    setSelectedImage(arrayOfImageURIs[nextIndex].s3Key);
    console.warn("selectedimage:", selectedImage);
  };

  let timeoutId;

const handleKeyDown = (event) => {
  if (event.key === 'ArrowLeft') {
    if(currentIndex > 0){
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        prevImage();
      }, 0);
    }
  } else if (event.key === 'ArrowRight') {
    if(currentIndex < arrayOfImageURIs.length - 1){
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        nextImage();
      }, 0);
    }
  }
};

const KeyListener = () => {
  useEffect(() => {
    document.addEventListener('keydown', (e) => handleKeyDown(e));
    return () => {
      document.removeEventListener('keydown', (e) => handleKeyDown(e));
    };
  }, [setCurrentIndex, currentIndex]);
  return null;
};

  return (
    <ScrollView
      contentContainerStyle={{
        width: "100%",
        alignSelf: "center",
        alignItems: "center",
        backgroundColor: "transparent",
      }}>
      {/* <View style={{flexDirection: 'column', width:'100%'}}> */}

      {/* Caption */}

      <View style={styles.textInputContainer}>
        <View style={styles.textInputLabelAndIconContainer}>
          <Feather
            name="thumbs-up"
            size={18}
            color={caption ? Colors.palette.logoGreen : "transparent"}
          />
          <Text
            style={[
              { color: Colors[colorScheme].textLessEmphasised },
              styles.textInputLabel,
            ]}>
            Caption:
          </Text>
        </View>
        <TextInput
          style={[
            styles.textInputBox,
            {
              outlineStyle: "none",
              color: Colors[colorScheme].textLessEmphasised,
              backgroundColor: Colors[colorScheme].background,
              borderColor: Colors[colorScheme].edging,
            },
          ]}
          onChangeText={setCaption}
          value={caption}
          maxLength={70}
        />
      </View>

      {/* Date */}

      <View style={styles.textInputContainer}>
        <View style={styles.textInputLabelAndIconContainer}>
          <Feather
            name="thumbs-up"
            size={18}
            color={
              momentDate.length < 10 ? "transparent" : Colors.palette.logoGreen
            }
          />
          <Text
            style={[
              { color: Colors[colorScheme].textLessEmphasised },
              styles.textInputLabel,
            ]}>
            Date:
          </Text>
        </View>
        <View
          style={{
            width: 270,
            flexDirection: "row",
            backgroundColor: "transparent",
          }}>
          <Picker
            style={[
              styles.textInputBox,
              {
                color: Colors[colorScheme].textLessEmphasised,
                backgroundColor: Colors[colorScheme].background,
                borderColor: Colors[colorScheme].edging,
              },
            ]}
            // itemStyle={{fontFamily: 'avenir-next', color: 'red'}}
            selectedValue={momentDateDay}
            onValueChange={(itemValue, itemIndex) =>
              setMomentDateDay(itemValue)
            }>
            <Picker.Item label="Day" value="" />
            <Picker.Item label="1" value="01" />
            <Picker.Item label="2" value="02" />
            <Picker.Item label="3" value="03" />
            <Picker.Item label="4" value="04" />
            <Picker.Item label="5" value="05" />
            <Picker.Item label="6" value="06" />
            <Picker.Item label="7" value="07" />
            <Picker.Item label="8" value="08" />
            <Picker.Item label="9" value="09" />
            <Picker.Item label="10" value="10" />
            <Picker.Item label="11" value="11" />
            <Picker.Item label="12" value="12" />
            <Picker.Item label="13" value="13" />
            <Picker.Item label="14" value="14" />
            <Picker.Item label="15" value="15" />
            <Picker.Item label="16" value="16" />
            <Picker.Item label="17" value="17" />
            <Picker.Item label="18" value="18" />
            <Picker.Item label="19" value="19" />
            <Picker.Item label="20" value="20" />
            <Picker.Item label="21" value="21" />
            <Picker.Item label="22" value="22" />
            <Picker.Item label="23" value="23" />
            <Picker.Item label="24" value="24" />
            <Picker.Item label="25" value="25" />
            <Picker.Item label="26" value="26" />
            <Picker.Item label="27" value="27" />
            <Picker.Item label="28" value="28" />
            <Picker.Item label="29" value="29" />
            <Picker.Item label="30" value="30" />
            <Picker.Item label="31" value="31" />
          </Picker>
          <Picker
            style={[
              styles.textInputBox,
              {
                color: Colors[colorScheme].textLessEmphasised,
                backgroundColor: Colors[colorScheme].background,
                borderColor: Colors[colorScheme].edging,
              },
            ]}
            selectedValue={momentDateMonth}
            onValueChange={(itemValue, itemIndex) =>
              setMomentDateMonth(itemValue)
            }>
            <Picker.Item label="Month" value="" />
            <Picker.Item label="Jan" value="01" />
            <Picker.Item label="Feb" value="02" />
            <Picker.Item label="Mar" value="03" />

            <Picker.Item label="Apr" value="04" />
            <Picker.Item label="May" value="05" />
            <Picker.Item label="Jun" value="06" />

            <Picker.Item label="Jul" value="07" />
            <Picker.Item label="Aug" value="08" />
            <Picker.Item label="Sep" value="09" />

            <Picker.Item label="Oct" value="10" />
            <Picker.Item label="Nov" value="11" />
            <Picker.Item label="Dec" value="12" />
          </Picker>
          <Picker
            style={[
              styles.textInputBox,
              {
                color: Colors[colorScheme].textLessEmphasised,
                backgroundColor: Colors[colorScheme].background,
                borderColor: Colors[colorScheme].edging,
              },
            ]}
            selectedValue={momentDateYear}
            onValueChange={(itemValue, itemIndex) =>
              setMomentDateYear(itemValue)
            }>
            <Picker.Item label="Year" value="" />
            <Picker.Item label="2021" value="2021" />
            <Picker.Item label="2022" value="2022" />
            <Picker.Item label="2023" value="2023" />
            <Picker.Item label="2024" value="2024" />
          </Picker>
        </View>
      </View>

      {/* STAFF  */}
      <View style={[styles.textInputContainer, { alignItems: "baseline" }]}>
        <View style={styles.textInputLabelAndIconContainer}>
          <Feather
            name="thumbs-up"
            size={18}
            color={
              staffLeader.length < 1 ? "transparent" : Colors.palette.logoGreen
            }
          />
          <Text
            style={[
              { color: Colors[colorScheme].textLessEmphasised },
              styles.textInputLabel,
            ]}>
            Staff name:
          </Text>
        </View>

        <View
          style={[
            styles.textInputBox,
            {
              outlineStyle: "none",
              color: Colors[colorScheme].textLessEmphasised,
              backgroundColor: Colors[colorScheme].background,
              borderColor: Colors[colorScheme].edging,
            },
          ]}>
          {staffLeader.map((user) => {
            //Item in the list of users to add the badge to
            return (
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: Colors[colorScheme].background,
                }}
                key={user.id}>
                <Text
                  style={{ color: Colors[colorScheme].text, fontSize: 16 }}
                  numberOfLines={1}>
                  {user.preferredFirstName} {user.surname}
                </Text>
                <Pressable
                  style={{ backgroundColor: "transparent" }}
                  onPress={() => removeFromArray(user.id)}>
                  <Feather
                    name="trash-2"
                    size={20}
                    color={Colors.palette.softRed}
                  />
                </Pressable>
              </View>
            );
          })}

          {staffLeader.length < 1 ? (
            <>
              <SearchBarMobile
                searchPhrase={search}
                setSearchPhrase={(text) => searchFilter(text)}
                clicked={clicked}
                setClicked={setClicked}
              />

              {search ? (
                <FlatList
                  data={filteredData}
                  numColumns={1}
                  renderItem={({ item }) => (
                    // <ThumbnailTimeline id={item.id}/>
                    <Pressable
                      onPress={
                        () =>
                          addToArray({
                            id: item.id,
                            surname: item.surname,
                            preferredFirstName: item.preferredFirstName,
                          })
                        // {highlightSelection(item.id)}}
                      }>
                      <View style={{ backgroundColor: "transparent" }}>
                        <Text
                          style={{
                            color: Colors[colorScheme].text,
                            fontSize: 16,
                            paddingVertical: 5,
                            marginLeft: 10,
                          }}>
                          {item.preferredFirstName} {item.surname}
                        </Text>
                      </View>
                    </Pressable>
                  )}
                  keyExtractor={(item) => item.id}
                  key={4}
                />
              ) : null}
            </>
          ) : null}
        </View>
      </View>

      {/* </View> */}

      {/* Image */}
      <View
        style={[
          styles.textInputContainer,
          { flexDirection: "column", width: "100%" },
        ]}>
        <View
          style={{
            flexDirection: "row",
            backgroundColor: "transparent",
            width: 600,
            padding: 5,
            alignContent: "flex-start",
          }}>
          <View
            style={[
              styles.textInputLabelAndIconContainer,
              { alignItems: "center" },
            ]}>
            <Feather
              name="thumbs-up"
              size={18}
              color={
                arrayOfImageURIs.length > 0
                  ? Colors.palette.logoGreen
                  : "transparent"
              }
            />
            <Text
              style={[
                { color: Colors[colorScheme].textLessEmphasised },
                styles.textInputLabel,
              ]}>
              Image(s):
            </Text>
          </View>
          {/* <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}> */}
          <View
            style={[
              styles.textInputBox,
              {
                // width: "100%",
                outlineStyle: "none",
                color: Colors[colorScheme].textLessEmphasised,
                backgroundColor: Colors[colorScheme].background,
                borderColor: Colors[colorScheme].edging,
                // flexDirection: "row",
              },
            ]}>
            <Pressable
              onPress={pickImage}
              style={{
                // alignItems: "center",
                flexDirection: "row",
                // width: 75,
                // height: 85,
                // width:'90%',
                flex: 1,
                backgroundColor: Colors[colorScheme].almostBackground,
                padding: 10,
                margin: 10,
                borderRadius: 10,
                borderColor: Colors[colorScheme].edging,
                borderWidth: 1,
              }}>
              <Feather
                name="image"
                size={24}
                color={Colors[colorScheme].text}
              />
              <View
                style={{
                  marginHorizontal: 10,
                  backgroundColor: "transparent",
                  justifyContent: "center",
                }}>
                <Text
                  style={{
                    // textAlign: "center",
                    color: Colors[colorScheme].text,
                    fontSize: 16,
                  }}>
                  Browse photos
                </Text>
              </View>
            </Pressable>
          </View>
        </View>

        {/* container for array of images  */}
        <View
          style={{
            flexDirection: "row", // arrange the items in a row
            flexWrap: "wrap", // enable wrapping
            backgroundColor: "transparent",
          }}>
          {RenderThumbnails}

          <Modal
            animationType="slide"
            transparent={true}
            visible={modalVisible}
            onRequestClose={closeLightbox}>
              {/* <KeyListener /> */}
            <View
              style={[
                styles.modalContainer,
                { backgroundColor: Colors[colorScheme].background },
              ]}>
              <View
                style={{
                  flex: 1,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}>
                {selectedImage && (
                  <SignedURLImage
                    key={fileKey} //for reasons unknown, this wasn't updating when the fileKey state variable was updating. Annoying. A key forces it to upload.
                    filter={"/filters:quality(95)/fit-in/500x500"}
                    fileKey={"/public/" + fileKey}
                  />
                )}
              </View>
              <View
                style={{
                  flex: 1,
                  height: "100%",
                  backgroundColor: "transparent",
                  justifyContent: "space-between",
                }}>
                <Pressable
                  style={{
                    alignItems: "flex-end",
                    backgroundColor: "transparent",
                    top: 30,
                    right: 30,
                  }}
                  onPress={closeLightbox} // close the lightbox when the image is pressed
                >
                  <Feather
                    name="x"
                    size={24}
                    color={Colors[colorScheme].text}
                  />
                </Pressable>

                {/* Pupil(s) */}

                <View
                  style={{
                    width: "100%",
                    position: "absolute",
                    top: 100,
                    backgroundColor: "transparent",
                  }}>
                  <View
                    style={[
                      styles.textInputContainer,
                      { alignItems: "flex-start" },
                    ]}>
                    <View
                      style={[
                        styles.textInputLabelAndIconContainer,
                        {
                          marginTop: 40,
                          justifyContent: "center",
                          backgroundColor: "transparent",
                        },
                      ]}>
                      <Feather
                        name="thumbs-up"
                        size={18}
                        color={
                          taggedUsers[iDofCurrentImage]
                            ? taggedUsers[iDofCurrentImage].length > 0
                              ? Colors.palette.logoGreen
                              : "transparent"
                            : "transparent"
                        }
                      />
                      <Text
                        style={[
                          { color: Colors[colorScheme].textLessEmphasised },
                          styles.textInputLabel,
                        ]}>
                        Tag(s):
                      </Text>
                    </View>

                    <View
                      style={[
                        styles.textInputBox,
                        {
                          // outlineStyle: "none",
                          // color: Colors[colorScheme].textLessEmphasised,
                          // backgroundColor: Colors[colorScheme].background,
                          backgroundColor: "transparent",
                          borderColor: Colors[colorScheme].edging,
                          height: 400,
                        },
                      ]}>
                        <ScrollView style={{maxHeight: 300}}>
                      {taggedUsers[iDofCurrentImage] &&
                        taggedUsers[iDofCurrentImage].map((user) => {
                          //Item in the list of users to add the tag to
                          return (
                            <View
                              style={{
                                alignItems: "center",
                                justifyContent: "space-between",
                                flexDirection: "row",
                                width: "100%",
                                backgroundColor: Colors[colorScheme].background,
                              }}
                              key={user.id}>
                              <Text
                                style={{
                                  color: Colors[colorScheme].text,
                                  fontSize: 16,
                                }}
                                numberOfLines={1}>
                                {user.preferredFirstName} {user.surname}
                              </Text>
                              <Pressable
                                onPress={() =>
                                  removeUser(user, iDofCurrentImage)
                                }
                                style={{ backgroundColor: "transparent" }}>
                                <Feather
                                  name="trash-2"
                                  size={20}
                                  color={Colors.palette.softRed}
                                />
                              </Pressable>
                            </View>
                          );
                        })}</ScrollView>

                      {/* {usersToBadge.length < 1 ? ( */}
                      <>
                        <SearchBarMobile
                          searchPhrase={searchUsers}
                          setSearchPhrase={(text) => searchFilterUsers(text)}
                          clicked={clickedUsers}
                          setClicked={setClickedUsers}
                        />
                        {console.warn("IDofCurrentImage", iDofCurrentImage)}
                        {searchUsers ? (
                          <FlatList
                            data={filteredDataUsers}
                            numColumns={1}
                            initialNumToRender={3}
                            maxToRenderPerBatch={3}
                            renderItem={({ item }) => (
                              <Pressable
                                onPress={() => {
                                  const user = {
                                    id: item.id,
                                    surname: item.surname,
                                    preferredFirstName: item.preferredFirstName,
                                    uploaderID: userDetailsFromUserContext.id,
                                    mediaID: iDofCurrentImage,
                                  };
                                  tagUser(user, iDofCurrentImage);
                                }}>
                                <View
                                  style={{ backgroundColor: "transparent" }}>
                                  <Text
                                    style={{
                                      color: Colors[colorScheme].text,
                                      fontSize: 16,
                                      paddingVertical: 5,
                                      marginLeft: 10,
                                    }}>
                                    {item.preferredFirstName} {item.surname}
                                  </Text>
                                </View>
                              </Pressable>
                            )}
                            keyExtractor={(item) => item.id}
                            key={4}
                          />
                        ) : null}
                      </>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    backgroundColor: "transparent",
                    justifyContent: "space-between",
                  }}>
                  {currentIndex === 0 ? (
                    <View
                      style={{
                        width: 120,
                        backgroundColor: "transparent",
                        padding: 10,
                        margin: 20,
                        borderRadius: 10,
                      }}></View>
                  ) : (
                    <Pressable
                      onPress={prevImage}
                      style={{
                        alignItems: "center",
                        width: 120,
                        backgroundColor: Colors.palette.logoGreen,
                        padding: 10,
                        margin: 20,
                        borderRadius: 10,
                      }}>
                      <Feather
                        name="arrow-left-circle"
                        size={24}
                        color={Colors[colorScheme].background}
                      />
                      <Text style={{ color: Colors[colorScheme].background }}>
                        Previous
                      </Text>
                    </Pressable>
                  )}
                  {currentIndex === arrayOfImageURIs.length - 1 ? (
                    <View
                      style={{
                        width: 120,
                        backgroundColor: "transparent",
                        padding: 10,
                        margin: 20,
                        borderRadius: 10,
                      }}></View>
                  ) : (
                    <Pressable
                      onPress={nextImage}
                      style={{
                        alignItems: "center",
                        width: 120,
                        backgroundColor: Colors.palette.logoGreen,
                        padding: 10,
                        margin: 20,
                        borderRadius: 10,
                      }}>
                      <Feather
                        name="arrow-right-circle"
                        size={24}
                        color={Colors[colorScheme].background}
                      />
                      <Text style={{ color: Colors[colorScheme].background }}>
                        Next
                      </Text>
                    </Pressable>
                  )}
                </View>
              </View>
            </View>
          </Modal>
        </View>
      </View>

      {/* </View> */}

      {/* Upload button */}
      <View style={{ backgroundColor: "transparent", alignItems: "center" }}>
        {triggerWarning ? (
          <View
            style={{
              backgroundColor: Colors[colorScheme].almostBackground,
              padding: 10,
              borderRadius: 10,
              borderColor: Colors.palette.softRed,
              borderWidth: 1,
              margin: 10,
            }}>
            <Text style={{ color: Colors.palette.softRed }}>
              There are some issues with the form. Please fix these before
              trying again.
            </Text>
          </View>
        ) : null}
        {!uploadingImages ? (
          <Pressable
            onPress={submit}
            style={{
              alignItems: "center",
              width: 120,
              backgroundColor: Colors.palette.logoGreen,
              padding: 10,
              margin: 20,
              borderRadius: 10,
            }}>
            <Feather
              name="upload"
              size={48}
              color={Colors[colorScheme].background}
            />
            <Text
              style={{
                textAlign: "center",
                color: Colors[colorScheme].background,
              }}>
              Submit details
            </Text>
          </Pressable>
        ) : null}
      </View>
    </ScrollView>
  );
};
export default AdminAddShoutOut;

const styles = StyleSheet.create({
  textInputBox: {
    borderWidth: 1,
    margin: 10,
    padding: 10,
    borderRadius: 8,
    fontSize: 16,
    fontFamily: "avenir-next",
    width: 280,
  },
  textInputLabel: { fontSize: 16, marginHorizontal: 10, fontWeight: "800" },
  textInputContainer: {
    flexDirection: "row",
    padding: 5,
    width: 600,
    backgroundColor: "transparent",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  textInputLabelAndIconContainer: {
    backgroundColor: "tranparent",
    flexDirection: "row",
    alignItems: "flex-end",
    width: 150,
  },
  switchRowContainer: {
    backgroundColor: "transparent",
    flexDirection: "row",
    flex: 1,
    padding: 5,
    // alignItems: "s",
    justifyContent: "flex-start",
    alignSelf: "stretch",
    marginLeft: 20,
  },
  switchGroupContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    margin: 10,
    padding: 10,
    borderRadius: 8,
    fontSize: 16,
    width: 300,
  },
  modalContainer: {
    flex: 1,
    // height:'100%',
    // backgroundColor: 'rgba(0, 0, 0, 0.5)',
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  modalImage: {
    width: "100%",
    aspectRatio: 1,
  },
  switchLabel: { fontSize: 16, marginHorizontal: 40 },
  tagIcon: {
    position: "absolute",
    top: 10,
    right: 10,
    backgroundColor: "transparent",
  },
});
