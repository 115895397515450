import { StyleSheet, Pressable, FlatList, ActivityIndicator } from "react-native";
import { View, Text } from "../../constants/Themed";
import Colors from "../../constants/Colors";
import { Feather } from "@expo/vector-icons";
import useColorScheme from "../../hooks/useColorScheme";
import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import ThumbnailShoutOut from '../../components/desktop/ThumbnailShoutOut';
import HousePointsWeekly from "../../components/HousePointsWeekly";




export default function AdminHousePoints({ navigation }) {
  const colorScheme = useColorScheme();




  return (
    <View
      style={[
        styles.root,
        { backgroundColor: Colors[colorScheme].almostBackground },
      ]}
    >

 <HousePointsWeekly />

    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    width: "100%",
    alignSelf: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
