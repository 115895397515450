import { StyleSheet, useWindowDimensions, ActivityIndicator, Pressable, Image } from "react-native";
import { View, Text } from "../../constants/Themed";
import { RootTabScreenProps } from "../../navigation/types";
import Colors from '../../constants/Colors'
import { FlatList } from "react-native-gesture-handler";
import CardOpportunityCategory from "../../components/CardOpportunityCategory";
import { API, graphqlOperation, navItem } from "aws-amplify";
import { useEffect, useState } from "react";
// import Colors from '../../constants/Colors';
import useColorScheme from "../../hooks/useColorScheme";
import * as WebBrowser from "expo-web-browser";

export const listOpportunityCategories = /* GraphQL */ `
  query ListOpportunityCategories(
    $filter: ModelOpportunityCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOpportunityCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        isActive
        logoWhiteURI
        logoColourURI
        logoGreyURI
        colour
        organisationID
        description
      }
    }
  }
`;




export default function OpportunitiesRoot({
  navigation,
}: RootTabScreenProps<"ShoutOuts">) {
  const [isLoading, setIsLoading] = useState(true);
  const {width, height} = useWindowDimensions();
  const numberOfColumns = width < 1440 ? 2 : 3
  const [stateVariableForNumberOfColumns,setStateVariableForNumberOfColumns] = useState(numberOfColumns)
  useEffect(() => {
    setStateVariableForNumberOfColumns(numberOfColumns)
  }, [numberOfColumns]);
  
const colorScheme = useColorScheme();
const [categories, setCategories] = useState([]);
const fetchCategoriesList = async () => {
  const response = await API.graphql(
    graphqlOperation(listOpportunityCategories, 
      {
        filter: {
          
            
              isActive: {
                eq: true,
              },
            
          
        },
      })
  );
  setCategories(response.data.listOpportunityCategories.items);
  setIsLoading(false)
};
useEffect(() => {
  fetchCategoriesList();
}, []);



function handleGetFLPDF() {
  WebBrowser.openBrowserAsync(
    "https://carrmanor.org.uk/wp-content/uploads/2023/09/Autumn01_FL_2023.pdf"
  );
}


const EmptyListMessage = () =>{

  return (
  <Text>Nothing here...</Text>
  )
}



  return (
//     <View style={[styles.root, {backgroundColor: Colors[colorScheme].almostBackground }]}>

// {isLoading ? <View style={{flex: 1, backgroundColor: 'transparent', alignItems: 'center', justifyContent: 'center'}}><ActivityIndicator size="large" color = {Colors.palette.logoGreen} /></View> :
      

//       <FlatList
//         data={categories}
        
//         numColumns = {stateVariableForNumberOfColumns}
//         renderItem={({ item }) => <CardOpportunityCategory category={item} />}
//         keyExtractor={(item) => item.id}
//         ListEmptyComponent={EmptyListMessage}
//       />
//   }
//     </View>




<Pressable onPress={handleGetFLPDF} style={{alignItems:'center'}}>
<Image source={require('../../../assets/images/Autumn01_FL_2023_cover-scaled.jpg')} style={{width: 400, height:566, margin: 30}} />
         
        </Pressable>


  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: "center",
    // backgroundColor: "transparent",
    width: '100%',
    alignSelf: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
