import { Text, View } from "../../constants/Themed";
import { StyleSheet, Image, Pressable,useWindowDimensions  } from "react-native";
import Colors from "../../constants/Colors";
import useColorScheme from "../../hooks/useColorScheme";
import React, { useEffect, useRef, useState } from "react";
import { BoldText } from "../../constants/StyledText";
import DynamicBadge from "../DynamicBadge";
import {useNavigation} from '@react-navigation/native';
import { Opportunity } from "../../API";

//this import is for getting the image out of storage
import { Storage } from "aws-amplify";



const CardOpportunity = ({ opportunity
}) => {
  const colorScheme = useColorScheme();
  const navigation = useNavigation();
  const [downloadedImageURI, setDownloadedImageURI] = useState<string | null>(
    null
  ); //state variable for imageURI



const {logoURL, dateType, id,engagementLevel,multipleEndDate,
  multipleStartDate,
  colour,
  name,
  location,
  oneOffDate,
  regularFriday,
  regularMonday,
  regularStartTime,
  regularThursday,
  regularTuesday,
  regularWednesday,
availableTo } = opportunity;
const windowWidth = useWindowDimensions().width
const variableItemWidth = windowWidth > 1200 ? (windowWidth - (220+48)) /3 : (windowWidth > 500 ? (windowWidth - 32) /2 : windowWidth - 16)
  

// console.warn(availableTo)
const isCancelled = useRef(false);

//get the image URL from storage
const getImage = async () => {
  const file = await Storage.get(logoURL, { level: "public" }); //get file using query data
  if (!isCancelled.current) {
  setDownloadedImageURI(file); //store imageURI in state variable to be used elsewhere on the page
}
};
useEffect(() => {
  getImage();
  return () => {
    isCancelled.current = true;
  };

}, []);

//navigate to the details for this opportunity
  const NavigateToOpportunityDetail = () => {
    navigation.navigate('OpportunityDetail', {opportunityID: id}) 
  }

//Timing line
const DateTypeSelector = () => {
  switch (dateType) {
    case "REGULAR":
      return (
        <Text numberOfLines={1}>
          {regularStartTime} on{regularMonday ? " Monday" : null}
          {regularTuesday ? " Tuesday" : null}
          {regularWednesday ? " Wednesday" : null}
          {regularThursday ? " Thursday" : null}
          {regularFriday ? " Friday" : null}
        </Text>
      );

    case "MULTIPLEDATES":
      const mutipleStartDateObject = new Date(multipleStartDate);
      const multipleEndDateObject = new Date(multipleEndDate);
      const formattedMultipleStartDate =
        mutipleStartDateObject.toLocaleDateString("en-GB", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      const formattedMultipleEndDate =
        multipleEndDateObject.toLocaleDateString("en-GB", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      return (
        <Text numberOfLines={1}>
          {formattedMultipleStartDate} until {formattedMultipleEndDate}{" "}
        </Text>
      );

    case "ONEOFFDATE":
      const dateObject = new Date(oneOffDate);
      const formattedDate = dateObject.toLocaleDateString("en-GB", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      return <Text numberOfLines={1}>{formattedDate}</Text>;
    default:
      return <Text>Error, no timing information</Text>;
  }
};




  return (
    <Pressable onPress={NavigateToOpportunityDetail}>
    <View
      style={[
        styles.root,
        {
          width:variableItemWidth,
          backgroundColor: Colors[colorScheme].background,
          // borderColor: Colors[colorScheme].edging,
        },
      ]}
    >
      <View style={{flexDirection: 'row'}}> 
      
{/* Badge logo */}
<DynamicBadge
            size={120}
            edgeColour={colour}
            engagementLevel={engagementLevel}
            logo={downloadedImageURI}
          />



      <View style={styles.textContainer}>

      <BoldText numberOfLines={2}  style={[styles.title, {color: Colors[colorScheme].text}]}>{name}</BoldText>
      
      {/* Adds the timing of the event in three diffrent formats */}
      {DateTypeSelector()}

{/* Location - change this to who this is available to later */}
<Text numberOfLines={1}>{availableTo}</Text>
{/* <Text numberOfLines={1}>In {location}</Text> */}

      
      </View></View>
    </View>
     </Pressable>
  );
};

export default CardOpportunity;

const styles = StyleSheet.create({
  root: {
    borderRadius: 5,
          // borderWidth: 1,
          padding: 10,
          margin: 5,
          // width: 320,
  },
  textContainer:{
    marginLeft: 20,
    // width: '100%',
    flex: 1,
    justifyContent: 'center',

    
  },
  
  title:{
    fontSize: 18,
    // lineHeight:20,
    marginBottom: 10,
  }
});
