import { StyleSheet, FlatList, ActivityIndicator, Pressable, useWindowDimensions, Animated} from 'react-native';
import { View, Text } from "../../constants/Themed";
import {useState, useRef, useEffect,} from 'react';
import { RootTabScreenProps } from "../../navigation/types";
import CardOpportunity from '../../components/desktop/CardOpportunity';
import { Feather } from "@expo/vector-icons";
import Colors from '../../constants/Colors';
import useColorScheme from "../../hooks/useColorScheme";
import  SearchBarDesktop from "../../components/desktop/SearchBar";
import { API, graphqlOperation } from "aws-amplify";
// import {listOpportunities} from '../../graphql/queries';
// import { Opportunity } from "../../API";
import { useRoute } from '@react-navigation/native';
import {Picker} from '@react-native-picker/picker';

//note, you have removed the line about limit from the top of this query (where it gets defined as an int) and added a limit of 2000. If you don't do this, you don't get all of the results!
export const listOpportunities = /* GraphQL */ `
  query ListOpportunities(
    $filter: ModelOpportunityFilterInput
   
    $nextToken: String
  ) {
    listOpportunities(filter: $filter, limit: 2000, nextToken: $nextToken) {
      items {
        id
        isActive
        name
        description
        logoURL
        location
        colour
        engagementLevel
        availableTo
        regularMonday
        regularTuesday
        regularWednesday
        regularThursday
        regularFriday
        regularStartTime
        regularEndTime
        dateType
        oneOffDate
        regularStartDat
        regularEndDate
        multipleStartDate
        multipleEndDate
        totalHours
        userID
        User {
          id
          title
          surname
        }
        opportunitycategoryID
        OpportunityCategory {
          id
          name
          logoWhiteURI
          logoColourURI
          logoGreyURI
          colour
        }
        Mappings {
          items {
            level
            Criteria {
              name
              logoColourURI
            }
          }
        }
        OpportunityAvailabilities {
          items {
            groupingData3
          }
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;


export default function OpportunitiesRoot({
  navigation,
}: RootTabScreenProps<"Opportunities">) {


  const [isLoading, setIsLoading] = useState(true);
  const route = useRoute();
  const { catID, catName, catDescription } = route.params; 
  const {width} = useWindowDimensions();
  const [searchBarToggle, setSearchBarToggle] = useState(true)
  const colorScheme = useColorScheme();
  const [opportunitiesList, setOpportunitiesList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [availabilityArray, setAvailabilityArray] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [masterDataWithYearGroupFilter, setMasterDataWithYearGroupFilter] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState('');
  const [yearWeWantState, setYearWeWantState] = useState('');
  
  
  const [selectedLanguage, setSelectedLanguage] = useState();

 const [dayWeWantState, setDayWeWantState] = useState('');

  //the cloud query for a list (that has a filter applied here)
  const fetchOpportunitiesList = async () => {
    const response = await API.graphql(
      graphqlOperation(listOpportunities, {
        filter: {
          and: [
            {
              isActive: {
                eq: true,
              },
            },
            {
              opportunitycategoryID: {
                eq: catID,
              }
            }
          ]
        },
      })
    );
    setMasterData(response.data.listOpportunities.items);
    setFilteredData(response.data.listOpportunities.items);
    setIsLoading(false)
  };





  useEffect(() => {

      fetchOpportunitiesList();

  }, []);









// search button
const ToggleSearchBar = () => {
  setSearchBarToggle(!searchBarToggle)
} 
useEffect( ()=> {
  navigation.setOptions({
    headerRight:()=>(
      <View style={{right: (width - 1200)/2, backgroundColor: 'transparent' }}>
      <Pressable
      onPress={ToggleSearchBar}
      ><Feather
            name="search"
            size={24}
            color={searchBarToggle ?  Colors.palette.logoGreen : Colors[colorScheme].buttons}
            style={{ marginRight: 10 }}
          /></Pressable></View>
    )
  })
}, [ToggleSearchBar] );

  const searchBarAnim = useRef(new Animated.Value(-145)).current;
  useEffect(() => {
    if (searchBarToggle) {
      Animated.timing(searchBarAnim, {
        toValue: 0,
        duration: 150,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(searchBarAnim, {
        toValue: -70,
        duration: 150,
        useNativeDriver: true,
      }).start();
      setYearWeWantState('');
      setSearch('');
      setFilteredData(masterData);
    }
  }, [searchBarToggle]);



  // const [searchPhrase, setSearchPhrase] = useState("");
  const [clicked, setClicked] = useState(false);



const numberOfColumns = 3


// console.warn(filteredData)



const FilterByYear = (year) => {

if(yearWeWantState == year) {
  setYearWeWantState('');
}
else {

  setYearWeWantState(year)
  // console.warn('we want year', yearWeWantState)
}
};


// const FilterByDay = (regularDay) => {
//   if(dayWeWantState == regularDay) {
//     setDayWeWantState('');
//   }
//   else{
//     setDayWeWantState(regularDay)
//   }


// }


useEffect(() => {
// console.warn('fired useeffect', yearWeWantState)
  searchFilter(search);

}, [yearWeWantState]);


// //going in here is masterdata
// const yearFilter = (year) => {
//   if(year) {
//     const newFilteredByYearData = masterData.filter((item) => {
//       const itemData = item.availebleTo ? item.availebleTo.toUpperCase() : ''.toLowerCase()
//       const textData = year.toUpperCase();
     

//       return itemData.indexOf(textData) > -1; 
//     });
    
//     setMasterDataWithYearGroupFilter(newFilteredByYearData); 
//     // setSearch(text);
//   }
//   else {
//     setFilteredData(masterData);
//     // setSearch(text);
//   }
// }
// //spit out newfiltereddnyyearata




//going in -> masterdatawithyeargroupfilter and the text to search by
const searchFilter = (text) => {
  if(text) {
    const newData = masterData.filter((item) => {
      const itemData = item.name ? item.name.toUpperCase() : ''.toLowerCase()
      const textData = text.toUpperCase();
      const yearData = item.availableTo ? item.availableTo : 'no avilableTo data';
      const yearWeWant = yearWeWantState;
// console.warn(yearData)

      return itemData.indexOf(textData) > -1
      && yearData.includes(yearWeWant);
    });
    
    setFilteredData(newData); 
    setSearch(text);
  }

  else if (yearWeWantState) {


    // console.warn('else if fired', yearWeWantState)

    const newData = masterData.filter((item) => {
      const itemData = item.name ? item.name.toUpperCase() : ''.toLowerCase()
      const textData = text.toUpperCase();
      const yearData = item.availableTo ? item.availableTo : 'no avilableTo data';
      const yearWeWant = yearWeWantState;
// console.warn(yearData)

      return itemData.indexOf(textData) > -1
      && yearData.includes(yearWeWant);
    });
    
    setFilteredData(newData); 
    setSearch(text);


  }
  


  else {
    setFilteredData(masterData);
    setSearch(text);
  }
}


const EmptyListMessage = ({item}) =>{

  return (
    <View style={{backgroundColor: Colors[colorScheme].almostBackground, marginTop: 100, width: '80%', alignSelf: 'center', }}>
  
  <Text style={{marginTop: 20, textAlign: 'center'}}>{catDescription}.</Text>
   <Text style={{marginTop: 20, textAlign: 'center'}}>Keep checking back. We'll have some {catName} opportunities soon...</Text>
    </View>
  )
}




  return (
    <View style ={{flex: 1, width: '100%', backgroundColor: Colors[colorScheme].almostBackground}}>
    <View style={[styles.root, {backgroundColor: Colors[colorScheme].almostBackground}]}>






{/* search bar */}
<Animated.View style={{ transform: [{ translateY: searchBarAnim }], flexDirection: 'row', justifyContent:'center', alignItems:'center', backgroundColor:'transparent', width:1000 }}>

<Picker
style={{ backgroundColor: Colors[colorScheme].background, borderWidth: 1, margin: 10, padding:10, borderColor: Colors[colorScheme].edging, borderRadius: 8, fontSize: 16, fontFamily: 'avenir-next', color: Colors[colorScheme].textLessEmphasised }}
// itemStyle={{fontFamily: 'avenir-next', color: 'red'}}  
selectedValue={yearWeWantState}
  onValueChange={(itemValue, itemIndex) =>
    setYearWeWantState(itemValue)
  }>
  <Picker.Item label="Year Group" value="" />
  <Picker.Item label="Reception" value="Reception" />
  <Picker.Item label="Year 1" value="1" />
  <Picker.Item label="Year 2" value="2" />
  <Picker.Item label="Year 3" value="3" />
  <Picker.Item label="Year 4" value="4" />
  <Picker.Item label="Year 5" value="5" />
  <Picker.Item label="Year 6" value="6" />
  <Picker.Item label="Year 7" value="7" />
  <Picker.Item label="Year 8" value="8" />
  <Picker.Item label="Year 9" value="9" />
  <Picker.Item label="Year 10" value="10" />
  <Picker.Item label="Year 11" value="11" />
</Picker>

{/* <Picker
style={{ borderWidth: 1, margin: 10, padding:10, borderColor: 'lightgrey', borderRadius: 8, fontSize: 16, fontFamily: 'avenir-next', color: Colors[colorScheme].textLessEmphasised }}
// itemStyle={{fontFamily: 'avenir-next', color: 'red'}}  
selectedValue={selectedLanguage}
  onValueChange={(itemValue, itemIndex) =>
    setDayWeWantState(itemValue)
  }>
  <Picker.Item label="Regular Day" value="" />
  <Picker.Item label="Monday" value="Monday" />
  <Picker.Item label="Tuesday" value="Tuesday" />
  <Picker.Item label="Wednesday" value="Wednesday" />
  <Picker.Item label="Thursday" value="Thursday" />
  <Picker.Item label="Friday" value="Friday" />
</Picker> */}
<View style={{backgroundColor: 'transparent'}}>
<SearchBarDesktop
  searchPhrase={search}
  setSearchPhrase={(text) => searchFilter(text)}
  clicked={clicked}
  setClicked={setClicked}

  /></View>





{/* <Pressable onPress={()=>FilterByYear(9)}><Text>Year 19</Text></Pressable> */}
  {/* <Pressable onPress={()=>FilterByYear(10)}><Text>Year 10</Text></Pressable><Pressable onPress={()=>FilterByYear(11)}><Text>Year 11</Text></Pressable> */}

</Animated.View>

<Animated.View style={{ transform: [{ translateY: searchBarAnim }],  
// height: '110%'
flex:1
}}>

{/* list of results */}
{isLoading ? <View style={{flex: 1, backgroundColor: 'transparent', alignItems: 'center', justifyContent: 'center'}}><ActivityIndicator size="large" color = {Colors.palette.logoGreen} /></View> : 
<Animated.FlatList
data={filteredData}
numColumns = {numberOfColumns}
// renderItem={renderItem}
extraData={yearWeWantState}
renderItem={({item}) => (
<CardOpportunity opportunity={item} />
)}


keyExtractor={(item) => item.id}
ListEmptyComponent={EmptyListMessage}
/>}
</Animated.View>
    </View>

    </View>

  );


  }
















const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: "center",
    // backgroundColor: "transparent",
    width: 1000,
    alignSelf: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
