import {
  StyleSheet,
  Image,
  ScrollView,
  useWindowDimensions,
  ActivityIndicator,
  Button,
  Pressable,
} from "react-native";
import { View, Text } from "../../constants/Themed";
import ShoutOutIcon from "../../../assets/icons/ShoutOut";
import Colors from "../../constants/Colors";
import { BoldText } from "../../constants/StyledText";
import useColorScheme from "../../hooks/useColorScheme";
import { RouteProp, useRoute } from "@react-navigation/native";
import TaggedTimeline from '../../components/mobile/TaggedTimeline';
import { RootStackParamList } from "../../navigation/types";
import { useEffect, useState } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import SignedURLImage from "../../components/SignedURLImage";
import { Feather } from "@expo/vector-icons";
export const getMoment = /* GraphQL */ `
  query GetMoment($id: ID!) {
    getMoment(id: $id) {
      id
      dateUploaded
      dateMomentHappened
      caption
      contributorID
      actuallyUploadedByID
      Contributor {
        id
        surname
        firstName
        preferredFirstName
        preferredSurname
        aaa
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      ActuallyUploadedBy {
        id
        surname
        firstName
        preferredFirstName
        preferredSurname
        aaa
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Media {
        items {
          id
          s3Key
          Tags {
            items {
              id
              userID
              User {
                id
                preferredSurname
                preferredFirstName
                photoURL
                title
              }
            }
            
          }
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

let timeoutId;

const handleKeyDown = (event, setCurrentMediaIndex, currentMediaIndex, Media) => {
  if (event.key === 'ArrowLeft') {
    if(currentMediaIndex > 0){
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setCurrentMediaIndex(currentMediaIndex - 1);
      }, 100);
    }
  } else if (event.key === 'ArrowRight') {
    if(currentMediaIndex < Media.items.length - 1){
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setCurrentMediaIndex(currentMediaIndex + 1);
      }, 100);
    }
  }
};





const KeyListener = ({setCurrentMediaIndex, currentMediaIndex, Media}) => {
  useEffect(() => {
    document.addEventListener('keydown', (e) => handleKeyDown(e, setCurrentMediaIndex, currentMediaIndex, Media));
    return () => {
      document.removeEventListener('keydown', (e) => handleKeyDown(e, setCurrentMediaIndex, currentMediaIndex, Media));
    };
  }, [setCurrentMediaIndex, currentMediaIndex]);
  return null;
};

export default function ShoutOutsDetail() {
  const { height } = useWindowDimensions();
  const colorScheme = useColorScheme();
  const route = useRoute<RouteProp<RootStackParamList, 'ShoutOutsDetail'>>();
  const [momentFullData, setMomentFullData] = useState(null)
  const [media, setMedia] = useState(null)
  const [contributorPhoto, setContributorPhoto] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const { shoutOutID } = route.params; //this isn't type checked and is bad practice. According to the Istagram course by Vadim (video 4.3) we should only be sending the shoutout ID and then querying the database on the detail screen
  // const [s3Key, setS3Key] = useState(null);
  // const [date, setDate] = useState(null)
  // const [caption, setCaption] = useState(null)
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);

  const fetchMoment = async () => {
    const response = await API.graphql(
      graphqlOperation(getMoment, {
        id: shoutOutID,
      })
    );

    setMomentFullData(response.data.getMoment); //all data in a variable to be destructured further down
    setMedia(response.data.getMoment.Media.items); //Media data in a variable
    
    const contributorProfileImage = await Storage.get(
      response.data.getMoment.Contributor.photoURL,
      {
        level: "public",
      }
    );
    setContributorPhoto(contributorProfileImage);    
  };

  useEffect(() => {
    fetchMoment();
    setIsLoading(false);
  }, []);
if (!momentFullData) {
return <ActivityIndicator />;
}
 
const { Media, caption, dateMomentHappened,Contributor,  } = momentFullData;
const currentMedia = Media.items[currentMediaIndex];
const {title, preferredFirstName, preferredSurname, photoURL} = Contributor
const {Tags} = currentMedia

console.warn('TAGS:', Tags)

  return (
    <ScrollView style={styles.root}>
         <KeyListener setCurrentMediaIndex={setCurrentMediaIndex} currentMediaIndex={currentMediaIndex} Media={Media} />
      <View style={[styles.contentContainer, { width: height - 64 }]}>
        {/* The Photo */}
        <View style={{ height: height - 64, width: height - 64 }}>
          {/* AJL: 64 in the line above is the height of the main site navigation. This means the photo will be a square taking up the remaining height of the scren */}
          {/* <Image
            source={{
              uri: image
            }}
            style={styles.image}
          /> */}

{/* <SignedURLImage
            filter={"/filters:quality(100)/fit-in/500x500"}
            // filter={"/filters:quality(50)/filters:grayscale()/fit-in/100x100"}
            fileKey={"/public/" + imageS3Key}
          /> */}





          {/* {media ? <SignedURLImage
          filter={"/filters:quality(100)/fit-in/500x500"}
          fileKey={'/public/'+ s3Key} /> : <ActivityIndicator />} */}



{Media?<SignedURLImage
key={currentMedia.s3Key}
filter={"/filters:quality(95)//fit-in/1000x1000"}
fileKey={"/public/" + currentMedia.s3Key}
/>:<ActivityIndicator />}

{currentMediaIndex === 0?null:
<Pressable style={{position:'absolute', left:-65, bottom:((height-64)/2)-35}} onPress={() => setCurrentMediaIndex(currentMediaIndex - 1)}><Feather name="chevron-left" size={70}
color={Colors[colorScheme].textLessEmphasised}
 /></Pressable>
}

{currentMediaIndex === Media.items.length - 1?null:
<Pressable style={{position:'absolute', right:-65, bottom:((height-64)/2)-35}} onPress={() => setCurrentMediaIndex(currentMediaIndex + 1)}><Feather name="chevron-right" size={70}
color={Colors[colorScheme].textLessEmphasised}
 /></Pressable>
}

          <View style={styles.iconPosition}>
            <ShoutOutIcon color={Colors.palette.logoGreen} />
          </View>
        </View>
        {Media.items.length > 1 && (
<View>
  
</View>
)}

        {/* The meta data */}
        <View>
          {/* Who posted, what's the title and when? */}
          <View
            style={[
              styles.contributionContainer,
              { backgroundColor: Colors[colorScheme].almostBackground },
            ]}
          >
            <Image
              source={{
                uri: contributorPhoto,
              }}
              style={styles.contributorProfileImage}
            />

            <View
              style={{ backgroundColor: "transparent", marginHorizontal: 10 }}
            >
              <BoldText
                style={{ color: Colors.palette.secondaryBlue, fontSize: 18 }}
              >
                {caption?caption:'Untitled moment...'}
              </BoldText>
              <Text>{title && preferredSurname?title + " " + preferredSurname:preferredFirstName + " " + preferredSurname}</Text>
              <Text
                style={{
                  color: Colors[colorScheme].textLessEmphasised,
                  fontSize: 10,
                }}
              >
                {dateMomentHappened?dateMomentHappened:"We don't have a date for this"}
              </Text>
            </View>
          </View>



{/* USERS THAT ARE TAGGED IN THE SHOUTOUT */}
{Tags.items.length > 0?
<View
          style={[
            styles.taggedRowContainer,
            { backgroundColor: Colors[colorScheme].almostBackground },
          ]}
        > <Feather name="tag" size={24} color={Colors.palette.logoGreen} />
          {Tags.items?.map((tagged) => (
            <TaggedTimeline key={tagged.id} tagged={tagged} />
          ))}
          {/* The above uses mapping rather than a flatlist to render the tagged users.   */}

         
        </View>:null}



        
        </View>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: "transparent",
  },
  contentContainer: {
    // width: 600,
    alignSelf: "center",
  },
  iconPosition: {
    width: 70,
    height: 70,
    position: "absolute",
    alignSelf: "flex-end",
    backgroundColor: "transparent",
  },
  image: {
    aspectRatio: 1,
    height: "100%",
  },
  contributorProfileImage: {
    width: 60,
    height: 60,
    borderRadius: 35,
    marginHorizontal: 10,
  },
  contributionContainer: {
    flexDirection: "row",
    flex: 1,
    paddingVertical: 10,
    alignItems: "center",
  },
  taggedRowContainer: {
    // paddingHorizontal: 10,
    alignItems: "center",
    flexDirection: "row-reverse",
  },
  taggedProfileImage: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginBottom: 5,
  },
  taggedItemContainer: {
    alignItems: "center",
    margin: 5,
    width: 90,
    backgroundColor: "transparent",
  },
  taggedName:{
    fontSize: 10,
    flexWrap: 'wrap',
    textAlign: 'center',
  }
});
