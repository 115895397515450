import { View, Text } from "../../constants/Themed";
import { StyleSheet, Image, Pressable } from "react-native";
import React, { useEffect, useState } from "react";
import { IUser } from "../../models/types";
import { useNavigation } from "@react-navigation/native";
import { BoldText } from "../../constants/StyledText";
import Colors from "../../constants/Colors";
import useColorScheme from "../../hooks/useColorScheme";
import { API, graphqlOperation, Storage } from "aws-amplify";


const TaggedTimeline = ({ tagged }) => {

  const {User} = tagged
  const {preferredFirstName, preferredSurname, title, photoURL, id} = User
  const [downloadedPhoto, setDownloadedPhoto] = useState()
  const navigation = useNavigation();
  const colorScheme = useColorScheme();
  const NavigateToTimelineDetail = () => {
    // const userIDForQuery = tagged.id;
    navigation.navigate("TimelinesDetail", { userIDForQuery: id });
  };
  

  const fetchTaggedPhoto = async () => {
    
    const taggedPhoto = await Storage.get(
      photoURL,
      {
        level: "public",
      }
    );
    setDownloadedPhoto(taggedPhoto);    
  };

  useEffect(() => {
    if(photoURL){
    fetchTaggedPhoto();
  }
  }, []);



  return (
    <Pressable onPress={NavigateToTimelineDetail}>
      <View style={styles.taggedItemContainer}>
        <Image
          source={{
            uri: downloadedPhoto,
          }}
          style={styles.taggedProfileImage}
        />
        <BoldText
          style={[
            styles.taggedName,
            { color: Colors[colorScheme].textLessEmphasised },
          ]}
        >
          {title?title + " " + preferredSurname:preferredFirstName + " " + preferredSurname}
        </BoldText>
      </View>
    </Pressable>
  );
};

export default TaggedTimeline;

const styles = StyleSheet.create({
  taggedProfileImage: {
    width: 35,
    height: 35,
    borderRadius: 20,
    marginBottom: 5,
  },
  taggedItemContainer: {
    alignItems: "center",
    margin: 5,
    width: 55,
    backgroundColor: "transparent",
  },
  taggedName: {
    fontSize: 8,
    flexWrap: "wrap",
    textAlign: "center",
  },
});
