import { StyleSheet, Pressable, Animated, Alert, FlatList, Platform, ActivityIndicator } from "react-native";
import React, { useEffect, useRef, useState } from "react";
import { View, Text } from "../../constants/Themed";
import { RootTabScreenProps } from "../../navigation/types";
import SearchBarMobile from "../../components/mobile/SearchBar";
import { Feather } from "@expo/vector-icons";
import Colors from "../../constants/Colors";
import useColorScheme from "../../hooks/useColorScheme";
import CardOpportunity from "../../components/mobile/CardOpportunity";
import { RouteProp, useRoute } from "@react-navigation/native";
//these imports are for the cloud query
import { API, graphqlOperation, navItem } from "aws-amplify";
// import {listOpportunities} from '../../graphql/queries';
import { Opportunity } from "../../API";
import { useWindowDimensions } from 'react-native';

import {Picker} from '@react-native-picker/picker';



//note, you have removed the line about limit from the top of this query (where it gets defined as an int) and added a limit of 2000. If you don't do this, you don't get all of the results!
export const listOpportunities = /* GraphQL */ `
  query ListOpportunities(
    $filter: ModelOpportunityFilterInput
   
    $nextToken: String
  ) {
    listOpportunities(filter: $filter, limit: 2000, nextToken: $nextToken) {
      items {
        id
        isActive
        name
        description
        logoURL
        location
        colour
        engagementLevel
        availableTo
        regularMonday
        regularTuesday
        regularWednesday
        regularThursday
        regularFriday
        regularStartTime
        regularEndTime
        dateType
        oneOffDate
        regularStartDat
        regularEndDate
        multipleStartDate
        multipleEndDate
        totalHours
        userID
        User {
          id
          title
          surname
        }
        opportunitycategoryID
        OpportunityCategory {
          id
          name
          logoWhiteURI
          logoColourURI
          logoGreyURI
          colour
        }
        Mappings {
          items {
            level
            Criteria {
              name
              logoColourURI
            }
          }
        }
        OpportunityAvailabilities {
          items {
            groupingData3
          }
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export default function OpportunitiesListMobile({
  navigation,
}: RootTabScreenProps<"Opportunities">) {
  const [isLoading, setIsLoading] = useState(true);
  const route = useRoute();
  const { catID, catName, catDescription } = route.params; 

const {width, height} = useWindowDimensions();


const [masterData, setMasterData] = useState([]);
const [filteredData, setFilteredData] = useState([]);
const [search, setSearch] = useState('');
  const [yearWeWantState, setYearWeWantState] = useState('');


  const [searchBarToggle, setSearchBarToggle] = useState(false);
  const colorScheme = useColorScheme();

  //the cloud query for a list (that has a filter applied here)
  const [opportunitiesList, setOpportunitiesList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [availabilityArray, setAvailabilityArray] = useState([]);

  const numberOfColumns = width > 500 ? 2 : 1
  const [stateVariableForNumberOfColumns,setStateVariableForNumberOfColumns] = useState(numberOfColumns)
  useEffect(() => {
    setStateVariableForNumberOfColumns(numberOfColumns)
  }, [numberOfColumns]);

  const fetchOpportunitiesList = async () => {
    const response = await API.graphql(
      graphqlOperation(listOpportunities, {
        filter: {
          and: [
            {
              isActive: {
                eq: true,
              },
            },
            {
              opportunitycategoryID: {
                eq: catID,
              }
            }
          ]
        },
      })
    );
  setMasterData(response.data.listOpportunities.items);
    setFilteredData(response.data.listOpportunities.items);
    setIsLoading(false)
  };

  useEffect(() => {

      fetchOpportunitiesList();

  }, []);





  const ToggleSearchBar = () => {
    setSearchBarToggle(!searchBarToggle);
  };



  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Pressable 
        style={{marginRight:Platform.OS === 'web'? 20: 0}}
        onPress={ToggleSearchBar}>
          <Feather
            name="search"
            size={24}
            color={
              searchBarToggle
                ? Colors.palette.logoGreen
                : Colors[colorScheme].buttons
            }
            style={{ marginLeft: 1 }}
          />
        </Pressable>
      ),
      title: catName,
    });
  }, [ToggleSearchBar]);

  const searchBarAnim = useRef(new Animated.Value(-145)).current;
  useEffect(() => {
    if (searchBarToggle) {
      Animated.timing(searchBarAnim, {
        toValue: 0,
        duration: 150,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(searchBarAnim, {
        toValue: Platform.OS === 'ios' ? -320 : -120,
        duration: 150,
        useNativeDriver: true,
      }).start();
      setYearWeWantState('');
      setSearch('');
      setFilteredData(masterData);
    }
  }, [searchBarToggle]);

  

  // const [searchPhrase, setSearchPhrase] = useState("");
  const [clicked, setClicked] = useState(false);


  const FilterByYear = (year) => {

    if(yearWeWantState == year) {
      setYearWeWantState('');
    }
    else {
    
      setYearWeWantState(year)
      // console.warn('we want year', yearWeWantState)
    }
    };

  // const renderItem = ({ item }) => {
  //   if (searchPhrase === "") {
  //     return (
  //       <CardOpportunity
  //         dateType={item.dateType}
  //         edgeColour={item.colour}
  //         engagementLevel={item.engagementLevel}
  //         id={item.id}
  //         location={item.location}
  //         logoURL={item.logoURL}
  //         name={item.name}
  //         multipleEndDate={item.multipleEndDate}
  //         multipleStartDate={item.multipleStartDate}
  //         oneOffDate={item.oneOffDate}
  //         regularFriday={item.regularFriday}
  //         regularMonday={item.regularMonday}
  //         regularStartTime={item.regularStartTime}
  //         regularThursday={item.regularThursday}
  //         regularTuesday={item.regularTuesday}
  //         regularWednesday={item.regularWednesday}
  //         availabilityArray={item.OpportunityAvailabilities.items}
  //       />
  //     );
  //   }
  //   if (
  //     item.name
  //       .toUpperCase()
  //       .includes(searchPhrase.toUpperCase().trim().replace(/\s/g, ""))
  //   ) {
  //     return (
  //       <CardOpportunity
  //         dateType={item.dateType}
  //         edgeColour={item.colour}
  //         engagementLevel={item.engagementLevel}
  //         id={item.id}
  //         location={item.location}
  //         logoURL={item.logoURL}
  //         name={item.name}
  //         multipleEndDate={item.multipleEndDate}
  //         multipleStartDate={item.multipleStartDate}
  //         oneOffDate={item.oneOffDate}
  //         regularFriday={item.regularFriday}
  //         regularMonday={item.regularMonday}
  //         regularStartTime={item.regularStartTime}
  //         regularThursday={item.regularThursday}
  //         regularTuesday={item.regularTuesday}
  //         regularWednesday={item.regularWednesday}
  //         availabilityArray={item.OpportunityAvailabilities}
  //       />
  //     );
  //   }
  // };
  useEffect(() => {
    // console.warn('fired useeffect', yearWeWantState)
      searchFilter(search);
    
    }, [yearWeWantState]);


  const searchFilter = (text) => {
    if(text) {
      const newData = masterData.filter((item) => {
        const itemData = item.name ? item.name.toUpperCase() : ''.toLowerCase()
        const textData = text.toUpperCase();
        const yearData = item.availableTo ? item.availableTo : 'no avilableTo data';
        const yearWeWant = yearWeWantState;
  // console.warn(yearData)
  
        return itemData.indexOf(textData) > -1
        && yearData.includes(yearWeWant);
      });
      
      setFilteredData(newData); 
      setSearch(text);
    }
  
    else if (yearWeWantState) {
  
  
      // console.warn('else if fired', yearWeWantState)
  
      const newData = masterData.filter((item) => {
        const itemData = item.name ? item.name.toUpperCase() : ''.toLowerCase()
        const textData = text.toUpperCase();
        const yearData = item.availableTo ? item.availableTo : 'no avilableTo data';
        const yearWeWant = yearWeWantState;
  // console.warn(yearData)
  
        return itemData.indexOf(textData) > -1
        && yearData.includes(yearWeWant);
      });
      
      setFilteredData(newData); 
      setSearch(text);
  
  
    }
    
  
  
    else {
      setFilteredData(masterData);
      setSearch(text);
    }
  }


  const EmptyListMessage = ({item}) =>{

    return (
      <View style={{backgroundColor: 'transparent', marginTop: 100, width: '80%', alignSelf: 'center', }}>
    
    <Text style={{marginTop: 20, textAlign: 'center'}}>{catDescription}.</Text>
     <Text style={{marginTop: 20, textAlign: 'center'}}>Keep checking back. We'll have some {catName} opportunities soon...</Text>
      </View>
    )
  }

// console.warn(opportunitiesList)


  return (
    <View style={{flex: 1,backgroundColor: Colors[colorScheme].almostBackground }}>
      {/* search bar */}
      <Animated.View style={{ transform: [{ translateY: searchBarAnim }], backgroundColor: Colors[colorScheme].background, }}>
      
      <Picker
style={{ backgroundColor: Colors[colorScheme].almostBackground, borderWidth: 1, margin: 10, padding:10, borderColor: Colors[colorScheme].edging, borderRadius: 8, fontSize: 16, fontFamily: 'avenir-next', color: Colors[colorScheme].textLessEmphasised }}
itemStyle={{fontFamily: 'avenir-next',padding:10, color: Colors[colorScheme].textLessEmphasised}}  
selectedValue={yearWeWantState}
  onValueChange={(itemValue, itemIndex) =>
    setYearWeWantState(itemValue)
  }>
  <Picker.Item label="Year Group" value="" />
  <Picker.Item label="Reception" value="Reception" />
  <Picker.Item label="Year 1" value="1" />
  <Picker.Item label="Year 2" value="2" />
  <Picker.Item label="Year 3" value="3" />
  <Picker.Item label="Year 4" value="4" />
  <Picker.Item label="Year 5" value="5" />
  <Picker.Item label="Year 6" value="6" />
  <Picker.Item label="Year 7" value="7" />
  <Picker.Item label="Year 8" value="8" />
  <Picker.Item label="Year 9" value="9" />
  <Picker.Item label="Year 10" value="10" />
  <Picker.Item label="Year 11" value="11" />
</Picker>
        <SearchBarMobile
        searchPhrase={search}
        setSearchPhrase={(text) => searchFilter(text)}
        clicked={clicked}
        setClicked={setClicked}
        />
      </Animated.View>

 
{/* View to hold the flatlist */}
<Animated.View
        style={{ transform: [{ translateY: searchBarAnim }], height: '100%', alignItems: 'center', width: '100%', marginTop:5}}
      >
      {/* list of results */}
      {isLoading ? <View style={{flex: 1, backgroundColor: 'transparent', alignItems: 'center', justifyContent: 'center'}}><ActivityIndicator size="large" color = {Colors.palette.logoGreen} /></View> : 
      <FlatList
      data={filteredData}
      numColumns = {stateVariableForNumberOfColumns}
        extraData={yearWeWantState}
        renderItem={({item}) => (
          <CardOpportunity opportunity={item} />
          )}
        // keyExtractor={(item, index) => item.id}
        keyExtractor={(item) => item.id}
        ListEmptyComponent={EmptyListMessage}
        key={stateVariableForNumberOfColumns}
        // key={index}
      />}</Animated.View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    // height: '100%',
    // backgroundColor: "transparent",
  },
});
