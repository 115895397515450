import React from "react";
import Svg, { Path, Ellipse } from "react-native-svg";

const TomlinsonIcon = (color: any) => {


  return (
    
    <Svg id="Layer_1" data-name="Layer 1" viewBox="0 0 300 300">
      <Path d="M249.5,51.9c-26.1-26.6-60.9-41.6-98.1-42C113.8,9.4,78.2,23.7,51.2,50.3C24.3,76.9,9.4,112.3,9.4,149.9
		c0,11.3,1.4,22.5,4,33.4c3.5,14.1,14.5,24.5,28.8,27.3c14.4,2.7,28.7-3,37.2-15l5.9-8.4l13.4-18.9l18.3-26
		c8.3-11.9,9.4-27.2,2.7-40.1c-6.7-12.9-19.8-20.9-34.3-20.9c-21.4,0-38.8,17.4-38.8,38.8c0,7.9,2.4,15.6,7.1,22.2L72,168.3
		L61.6,183c-3.5,5-9.2,7.3-15.3,6.1c-5.9-1.1-10.3-5.2-11.7-11c-2.3-9.2-3.4-18.7-3.4-28.2c0-31.7,12.5-61.5,35.3-84
		c22.4-22.1,51.8-34.2,82.9-34.2c0.5,0,1.1,0,1.6,0c55.1,0.7,101.1,39.4,113.4,90.8L246,112.3l-18.5,33.2c-2.1,3.7-6.4,5.4-10,4
		c-1.2-0.5-2.3-1.2-3.1-2.1c0,0-9.7-12.1-14.4-18.5c-7.3-9.9-19-15.8-31.3-15.8h-0.2c-12.1,0-23.1,5.5-30.3,15.1
		c-0.4,0.5-0.8,1.1-1.2,1.7L121.3,153l18.1,12.2l15.6-23.1c0.2-0.3,0.4-0.6,0.6-0.8c4.2-5.6,9.8-6.4,12.8-6.4h0.1
		c2.2,0,4.3,0.4,6.3,1.2l-35.7,50.6c-3.2,4.5-8.2,7.1-13.7,7.1c-5.5,0-10.5-2.6-13.7-7.1l-13-18.4l-8.4,26l3.5,5
		c3.6,5.1,8.4,9.2,13.8,12c5.4,2.8,11.5,4.3,17.8,4.3c10.5,0,20.4-4.2,27.6-11.6c4.1-4.1,8.3-8.2,12.8-11.8l1.4-1.1l12.5,9.5
		c3.1,2.4,4.2,6.5,2.6,10l-20.8,46.2l10.1,9l7,6.2l3,2.7l-3.9-0.8l-21.8-4.8c-3.4-0.7-6.9-1.2-10.4-1.6c-9.4-1.1-30.5-3.9-41.6-8.6
		c-15.2-6.4-29.5-16.7-41.2-29.8l-10.2-11.4l-16.3,14.5l10.2,11.4c13.9,15.5,30.8,27.7,49,35.4c17.1,7.2,35.3,10.8,54,10.8h1
		c76-0.5,138.3-62.8,139-138.7C289.8,113.8,275.6,78.5,249.5,51.9z M71.6,129.8c-2.1-2.9-3.1-6.2-3.1-9.6c0-8.2,6.5-17,16.9-17
		c6.4,0,12,3.4,15,9.1c3,5.7,2.5,12.2-1.2,17.5l-13.8,19.6L71.6,129.8z M182.5,263.3l19.7-43.6c5.8-12.9,2-27.8-9.2-36.4l-9.2-7
		l11.3-18c0.9,1,1.7,2.1,2.6,3.1l0.3,0.3c3.2,3.6,7.2,6.4,11.7,8.1c3.4,1.3,6.9,1.9,10.4,1.9c10.6,0,21-5.8,26.5-15.7l18.1-32.5
		c2,8.8,3,17.9,2.9,27.3C267.1,203.9,231.2,248.9,182.5,263.3z" fill={color.color}/>
    <Ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 74.1021 291.5851)" fill={color.color}
    
    cx="208.4" cy="102.2" rx="20.7" ry="20.7"
    
     />
    
    </Svg>
    
        
  );
};

export default TomlinsonIcon;
