import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as React from "react";
import { ColorSchemeName, Pressable, useWindowDimensions } from "react-native";
import Colors from "../constants/Colors";
import {Text} from '../constants/Themed';
import useColorScheme from "../hooks/useColorScheme";
import NotFoundScreen from "../screens/NotFoundScreen";
import {
  BottomTabParamList,
  OpportunitiesStackParamList,
  RootStackParamList,
  RootTabScreenProps,
  ShoutOutsStackParamList,
  TimelinesStackParamList,
} from "./types";
import LinkingConfiguration from "./LinkingConfiguration";
import ShoutOutIcon from "../../assets/icons/ShoutOut";
import OpportunityIcon from "../../assets/icons/Opportunity";
import TimelineIcon from "../../assets/icons/Timeline";
import SettingsIcon from "../../assets/icons/Settings";
import ShoutOutsRootMobile from "../screens/mobile/ShoutOutsRoot";
import OpportunitiesRootMobile from "../screens/mobile/OpportunitiesRoot";
import ScreenTitleOpportunities from "../components/desktop/ScreenTitleOpportunities";
import ShoutOutsRoot from "../screens/mobile/ShoutOutsRoot";
import ScreenTitleShoutOuts from "../components/desktop/ScreenTitleShoutOuts";
import ScreenTitleOpportunitiesMobile from "../components/mobile/ScreenTitleOpportunities";
import OpportunitiesListMobile from "../screens/mobile/OpportunitiesList";
import OpportunityDetail from "../screens/mobile/OpportunitiesDetail";
import HeaderLogoMobile from "../components/mobile/HeaderLogo";
import TimelinesRootMobile from "../screens/mobile/TimelinesRoot";
import ScreenTitleTimelinesMobile from "../components/mobile/ScreenTitleTimelines";
import TimelinesDetailMobile from "../screens/mobile/TimelinesDetail";
import AttributeDetailMobile from "../screens/mobile/CriteriaDetail";
import SettingsRootMobile from "../screens/mobile/SettingsRoot";
import CriteriaDetailMobile from "../screens/mobile/CriteriaDetail";
import ScreenTitleSettingsMobile from "../components/mobile/ScreenTitleSettings";
import TimelinesRootMobileV2 from "../screens/mobile/TimelinesRootV2";
import HomeIcon from "../../assets/icons/Home";



const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: Colors.light.almostBackground,
  },
};








export default function MobileNavigation({
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
}) {
  return (
    <NavigationContainer
    // independent={true}
      linking={LinkingConfiguration}
      theme={colorScheme === "dark" ? DarkTheme : MyTheme}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const RootStack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  const colorScheme = useColorScheme();
  return (
    <RootStack.Navigator>
      <RootStack.Screen
        name="Root"
        component={BottomTabNavigator}
        options={{
          headerShown: false,
          // headerTitle: () => <HeaderLogo />,
          // headerRight: () => <HeaderLogoMobile />,
          // headerShadowVisible: false,
          // headerTitle:'',
          
        }}
      />
      {/* <RootStack.Screen
        name="NotFound"
        component={RootNavigator}
        options={{ title: "Oops!", headerShown: false, }}
      /> */}




      <RootStack.Group screenOptions={{ presentation: "modal" }}> 
        <RootStack.Screen name="Modal" options={{title: "",
          headerShadowVisible: false,
        }}
        component={CriteriaDetailMobile} />
        {/* <RootStack.Screen name="TimelinesV2" component={TimelinesRootMobileV2} /> */}
       </RootStack.Group>
    </RootStack.Navigator>
  );
}






// SHOUT OUT STACK NAVIGATOR

const ShoutOutsStackMobile = createNativeStackNavigator<ShoutOutsStackParamList>();
function ShoutOutsStackNavigator() {
  const colorScheme = useColorScheme();
  return (
    <ShoutOutsStackMobile.Navigator screenOptions={{headerShown: false, headerStyle:{
      backgroundColor: Colors[colorScheme].background,
    }}}>
      <ShoutOutsStackMobile.Screen
        name="ShoutOutsRoot"
        component={ShoutOutsRoot}
        options={{

          headerShown: true,
          headerTitle: () => <HeaderLogoMobile />,
          headerShadowVisible: false,
          // headerStyle:{
          //   backgroundColor: Colors[colorScheme].background,
          // }
            

        }}
      />


<ShoutOutsStackMobile.Screen
        name="TimelinesDetail"
        component={TimelinesDetailMobile}
        options={{
          headerShown: true, //AJL: this is how you show a back button for some of the screens!
          
          headerTintColor: Colors[colorScheme].buttons,
          headerShadowVisible: false,
          headerBackTitle: '',
          title:'',
          
          
        }}
      />


<ShoutOutsStackMobile.Screen
        name="OpportunityDetail"
        component={OpportunityDetail}
        options={{
          headerShown: true, //AJL: this is how you show a back button for some of the screens!
          
          headerTintColor: Colors[colorScheme].buttons,
          headerShadowVisible: false,
          headerBackTitle: '',
          title:'',
          
          
        }}
      />


    </ShoutOutsStackMobile.Navigator>
  );
}




//OPPORTUNITIES STACK NAVIGATOR 

const OpportunitiesStackMobile =
  createNativeStackNavigator<OpportunitiesStackParamList>();
function OppotuntitiesStackNavigator() {
  const colorScheme = useColorScheme();
  return (
    
    <OpportunitiesStackMobile.Navigator
    screenOptions={{       
      
    headerShown: false,
    headerStyle:{
      backgroundColor: Colors[colorScheme].background,
    },
    headerTitleStyle:{fontFamily: 'avenir-next'},
        headerShadowVisible: false,
      }}
    >
      <OpportunitiesStackMobile.Screen
        name="OpportunitiesRoot"
        component={OpportunitiesRootMobile}
        options={{
          headerShown: true, //AJL: this is how you show a back button for some of the screens!
          headerShadowVisible: false,
          headerTitle: () => <ScreenTitleOpportunitiesMobile />,
          
        
          
        }}
      />
      <OpportunitiesStackMobile.Screen
        name="OpportunitiesList"
        component={OpportunitiesListMobile}
        options={{
          headerShown: true, //AJL: this is how you show a back button for some of the screens!
          headerBackTitle: '',
          title: 'Filtered list',
          headerTintColor: Colors[colorScheme].buttons,    
          
        }}
      />
      
      <OpportunitiesStackMobile.Screen
        name="OpportunityDetail"
        component={OpportunityDetail}
        options={{
          headerShown: true, //AJL: this is how you show a back button for some of the screens!
          
          headerTintColor: Colors[colorScheme].buttons,
          headerShadowVisible: false,
          headerBackTitle: '',
          title:'',
          
          
        }}
      />
    </OpportunitiesStackMobile.Navigator>
  
  );
}




//TIMESLINES STACK NAVIGATOR 


const TimeslineStackMobile =
  createNativeStackNavigator<TimelinesStackParamList>();
function TimelinesStackNavigator() {
  const colorScheme = useColorScheme();
  return (
    
    <TimeslineStackMobile.Navigator
    screenOptions={{     
      headerStyle:{
        backgroundColor: Colors[colorScheme].background,
      },  
    headerShown: false,
    headerTitleStyle:{fontFamily: 'avenir-next'},
        headerShadowVisible: false,
      }}
    >
      <TimeslineStackMobile.Screen
        name="TimelinesRoot"
        component={TimelinesRootMobile}
        options={{
          headerShown: true, //AJL: this is how you show a back button for some of the screens!
          // headerShadowVisible: true,
          headerTitle: () => <ScreenTitleTimelinesMobile />,
          
        
          
        }}
      />
      
      
      <TimeslineStackMobile.Screen
        name="TimelinesDetail"
        component={TimelinesDetailMobile}
        options={{
          headerShown: true, //AJL: this is how you show a back button for some of the screens!
          
          headerTintColor: Colors[colorScheme].buttons,
          // headerShadowVisible: false,
          headerBackTitle: '',
          title:'',
          
          
        }}
      />
      <TimeslineStackMobile.Screen
        name="OpportunityDetail"
        component={OpportunityDetail}
        options={{
          headerShown: true,
          headerShadowVisible: false,
          title:'',
          headerTintColor: Colors[colorScheme].buttons,
          // headerStyle: {
          //   backgroundColor: Colors[colorScheme].almostBackground,
          
          // },
          
        }}
      />
    </TimeslineStackMobile.Navigator>
  
  );
}




/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<BottomTabParamList>();

function BottomTabNavigator() {
  const colorScheme = useColorScheme();
  const windowSize = useWindowDimensions();

  return (
    <BottomTab.Navigator
      initialRouteName="ShoutOuts"
      screenOptions={{
        // tabBarLabel:false
        tabBarIconStyle: { width: 50, height: 50 },
        tabBarLabelStyle: {
          color: Colors[colorScheme].text,
          letterSpacing: 0.5,
          fontFamily: "avenir-next",
          marginBottom: 8,
        },
        // tabBarShowLabel: windowSize.width < 400 ? false : true, //AJL: this is the setting to hide the label text for the bottom tab only when using a mobile phone
       tabBarShowLabel:false,
        tabBarInactiveTintColor: Colors.palette.menuInactive, 
        tabBarActiveTintColor: Colors.palette.menuActive,
        tabBarStyle: {
          backgroundColor: Colors[colorScheme].background,
          shadowColor: 'transparent', //doesn't work
          height: 90, //AJL: increases the size of the bottom tab bar
          elevation: 0, //AJL: emoves the shadow for Android
          shadowOffset: {
            width: 0,
            height: 0, // AJL: removes the shadow for iOS ( NOT WORKING, SEE borderTopWidth below which is working, leaving this in just in case it doesn't work on Android)
          },
          borderTopWidth: 0, //AJL: this works
        },
      }}
    >
      <BottomTab.Screen
        name="ShoutOuts"
        component={ShoutOutsStackNavigator}
        // options={({ navigation }: RootTabScreenProps<"ShoutOuts">) => ({
          options={{
          title: "SHOUT OUTS",
          headerShown: false,
        // headerTitle: () => <HeaderLogoMobile />,
        headerShadowVisible: false,
          tabBarIcon: ({ color }) => <HomeIcon color={color} />,
        }}
      />
      <BottomTab.Screen
        name="Opportunities"
        component={OppotuntitiesStackNavigator}
        options={{
          title: "OPPORTUNITIES",
          headerShown: false,
        // headerTitle: () => <ScreenTitleOpportunitiesMobile />,
        headerShadowVisible: false,
          tabBarIcon: ({ color }) => <OpportunityIcon color={color} />,
          
        }}
      />
      <BottomTab.Screen
        name="Timelines"
        component={TimelinesStackNavigator}
        options={{
          title: "TIMELINES",
          headerShown: false,
          tabBarIcon: ({ color }) => <TimelineIcon color={color} />,
        }}
      />
      <BottomTab.Screen
        name="Settings"
        component={SettingsRootMobile}
        options={{
          headerTitle: () => <ScreenTitleSettingsMobile />,
          title: "SETTINGS",
          headerShadowVisible: false,
          headerStyle:{
            backgroundColor: Colors[colorScheme].background,
          },
          tabBarIcon: ({ color }) => <SettingsIcon color={color} />,
        }}
      />
    </BottomTab.Navigator>
  );
}
