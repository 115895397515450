import { StyleSheet,Pressable,} from "react-native";
import { View, Text } from "../../constants/Themed";
import { BoldText } from "../../constants/StyledText";

import Colors from '../../constants/Colors'

import { Feather } from '@expo/vector-icons';
import useColorScheme from "../../hooks/useColorScheme";




export default function AdminBadgeConfirmation({
  navigation,
}) {
  


  const colorScheme = useColorScheme();












  return (
    
      <View style={[styles.root, {backgroundColor: Colors[colorScheme].almostBackground}]}>
  
        

  <View
        style={{ backgroundColor: "transparent", alignItems: "center" }}/>
   <View style={{backgroundColor:Colors[colorScheme].almostBackground, padding:10, borderRadius:10, borderColor:Colors.palette.logoGreen, borderWidth:1, margin:10}}><Text style={{color:Colors.palette.logoGreen}}>Upload complete!</Text></View>





  <Pressable onPress={() =>
      navigation.navigate('AdminRoot',
          )
    }style={{alignItems:'center',width:120,backgroundColor: Colors.palette.logoGreen, padding:10, margin: 50, borderRadius:10}}>
  <Feather name="lock" size={48} color={Colors[colorScheme].background} />
          <BoldText style={{textAlign: 'center', color: Colors[colorScheme].background}}>Return to Administration</BoldText>

  </Pressable>

    
      </View>
    );

  


     



}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    width: '100%',
    alignSelf: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },

});
