import React from 'react'
import Svg, {Path} from 'react-native-svg';

const ShoutOutIcon = (color: any) => {

    return (
       
        <Svg id="Layer_1" data-name="Layer 1" viewBox="0 0 300 300"><Path d="M215.16,146.1l-42.95-11.51,16.65-62.14a8,8,0,0,0-12.74-8.3L81.89,139.94a8,8,0,0,0,3,14l43,11.51-16.65,62.14a8,8,0,0,0,12.74,8.3l94.23-75.79A8,8,0,0,0,215.16,146.1Z" fill={color.color}/></Svg>
        //AJL: color.color in the above means that the color scheme is passed down to the SVG
    )
}

export default ShoutOutIcon 