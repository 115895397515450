import React from "react";
import Svg, { Path, G, Circle } from "react-native-svg";

const LCAEdgeLevel4 = (edgeColour: any) => {
  return (
    <Svg id="Layer_1" viewBox="0 0 590 590">

      {/* outer edging */}
      <Path
        d="M590,295c0,127.21-80.53,235.62-193.39,277.02-3.12,1.15-6.27,2.25-9.44,3.29-29,9.53-59.98,14.69-92.17,14.69s-63.56-5.22-92.71-14.87c-3.17-1.05-6.32-2.15-9.44-3.3C80.28,530.28,0,422.01,0,295,0,132.08,132.08,0,295,0s295,132.08,295,295Z"
        fill={edgeColour.edgeColour}
      />
      {/* white inner circle */}
      <Circle cx="295" cy="295" r="282.81" fill={edgeColour.edgeColour} />

      {/* badge background colour */}
      <Path
        d="M295,34.31c35.2,0,69.34,6.89,101.47,20.48,31.04,13.13,58.92,31.93,82.87,55.87s42.74,51.82,55.87,82.87c13.59,32.13,20.48,66.27,20.48,101.47,0,54.89-16.86,107.39-48.75,151.83-15.36,21.4-33.81,40.32-54.83,56.22-21.24,16.06-44.68,28.72-69.66,37.61-27.99,9.97-57.41,15.03-87.44,15.03-27.36,0-54.28-4.21-80.04-12.51-25.91-8.35-50.28-20.7-72.44-36.7-21.89-15.81-41.13-34.83-57.2-56.54-33.37-45.09-51.01-98.66-51.01-154.93,0-35.2,6.89-69.34,20.48-101.47,13.13-31.04,31.93-58.92,55.87-82.87s51.82-42.74,82.87-55.87c32.13-13.59,66.27-20.48,101.47-20.48m0-10.15C145.42,24.16,24.16,145.42,24.16,295c0,120.57,78.79,222.74,187.69,257.84,26.19,8.45,54.14,13,83.15,13,31.87,0,62.46-5.5,90.85-15.62,104.89-37.34,179.99-137.51,179.99-255.22,0-149.58-121.26-270.84-270.84-270.84h0Z"
        fill={"#fff"}
      />


      {/* LCA notch */}
      <G>
        <Path
          d="M377.22,558.69l-8.93-14.92-14.05-23.49c-5.35-9.28-15.26-15-25.97-15h-66.47c-10.72,0-20.63,5.72-25.98,15l-14.32,23.43-9.08,14.86-10.13,16.56c29.15,9.65,60.32,14.87,92.71,14.87s63.17-5.16,92.17-14.69l-9.95-16.62Z"
          fill={"#013334"}
        />
        <Path
          d="M328.21,547.84l-3.76-12.38s-.02-.05-.03-.07c-2.56-10.12-11.56-17.09-21.98-17.09-.13,0-.27,0-.4,.01-1.49,.02-17.47-.04-19.28,0-6.09,.1-11.64,2.67-15.67,6.75-4.04,4.07-6.56,9.63-6.61,15.73-.02,2.69,.42,5.32,1.32,7.82,1.63,4.58,2.62,9.13,2.92,13.52l.49,7.12v2.31h4.76l-.15-4.78-.34-4.98c-.33-4.82-1.4-9.8-3.19-14.8-.69-1.93-1.04-3.96-1.04-6.04v-.13c.07-8.01,5.55-14.87,12.94-17.04-4.83,4.14-7.92,10.25-7.97,17-.03,2.69,.41,5.32,1.31,7.82,1.64,4.58,2.62,9.13,2.92,13.52l.03,.37,.29,4.28,.17,2.47v2.31h4.72l-.1-4.78-.35-4.98c-.33-4.82-1.4-9.8-3.18-14.8-.69-1.92-1.04-3.95-1.04-6.01v-.16c.07-7.9,5.39-14.68,12.62-16.94-4.75,4.13-7.8,10.2-7.85,16.9-.02,2.69,.42,5.32,1.31,7.82,1.64,4.58,2.62,9.13,2.92,13.52l.12,1.66,.21,2.99,.17,2.47v2.31h28.23c6.84,0,12.4-5.57,12.4-12.4v-4.99l1.23-.87c1.73-1.24,2.48-3.43,1.86-5.46Zm-27.14,3.86v7.46c0,4.2-3.42,7.62-7.63,7.62h-4.34l-.34-4.98c-.33-4.82-1.41-9.8-3.19-14.8-.69-1.92-1.04-3.95-1.04-6.01v-.16c.05-6.22,3.38-11.76,8.32-14.93,3.72,2.39,6.53,6.14,7.68,10.7l.03,.11c.03,.1,3.8,12.52,3.8,12.52,.02,.06,0,.14-.06,.17l-3.23,2.3Zm9.73,0v7.46c0,4.19-3.4,7.6-7.59,7.62,1.65-2.11,2.63-4.75,2.63-7.62v-4.99l1.23-.87c1.73-1.24,2.48-3.43,1.86-5.46l-3.76-12.38s-.02-.05-.02-.07c-1.18-4.64-3.72-8.63-7.13-11.58,5.94,1.76,10.66,6.51,12.24,12.79l.03,.11c.03,.1,3.81,12.52,3.81,12.52,.02,.06,0,.14-.06,.17l-3.24,2.3Zm12.78-2.3l-3.23,2.3v7.46c0,4.12-3.3,7.5-7.4,7.62,1.65-2.11,2.63-4.75,2.63-7.62v-4.99l1.23-.87c1.73-1.24,2.47-3.43,1.86-5.46l-3.77-12.38s-.01-.05-.02-.07c-1.17-4.6-3.66-8.54-7.02-11.49,5.79,1.83,10.39,6.53,11.94,12.7l.04,.11c.03,.1,3.8,12.52,3.8,12.52,.02,.06,0,.14-.06,.17Z"
          fill={"#fff"}
        />
      </G>
    </Svg>
  );
};

export default LCAEdgeLevel4;
