import React from "react";
import { StyleSheet } from "react-native";
import { View, Text} from "../../constants/Themed";
import Colors from '../../constants/Colors';
import useColorScheme from "../../hooks/useColorScheme";
import HomeIcon from "../../../assets/icons/Home";

const DrawerMenuItemHome = (props: any) => {
  const colorScheme = useColorScheme();
  return (
    <View style={styles.root}>
    <View style={styles.iconContainer}>
      <HomeIcon color={props.color.color} />
    </View>
    <View style={{backgroundColor: 'transparent'}}>
    <Text style={[styles.labelText, {color:Colors[colorScheme].text}]}>Home</Text>
    </View>
    </View>
  );
};


export default DrawerMenuItemHome;

const styles = StyleSheet.create({
  root:{
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: "transparent",
  },
  iconContainer: {
    width: 30,
    height: 30,
    backgroundColor: "transparent",
  },
  labelText:{
    // color: Colors.palette.grey,
    fontSize: 16,
    paddingLeft: 5,
  },
});
