import { StyleSheet, Pressable, FlatList, TextInput, ActivityIndicator } from "react-native";
import { View, Text } from "../../constants/Themed";
import Colors from "../../constants/Colors";
import useColorScheme from "../../hooks/useColorScheme";
import { API, graphqlOperation } from "aws-amplify";
import { useCallback, useState, useEffect } from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Picker } from "@react-native-picker/picker";
export const listOpportunities = /* GraphQL */ `
  query ListOpportunities(
    $filter: ModelOpportunityFilterInput
    $nextToken: String
  ) {
    listOpportunities(filter: $filter, limit: 999999, nextToken: $nextToken) {
      items {
        id
        isActive
        name
        description
        opportunitycategoryID
        OpportunityCategory {
          id
          name
          colour
        }
        availableTo
        year
        term
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

const SearchBox = ({
  masterData,
  dataToShow,
  setDataToShow,
  filteredAndSortedData,
  setFilteredAndSortedData,
  setSearchedData,
  searchedData,
  filterInUse,
  setFilterInUse,
  searchInUse,
  setSearchInUse,
  filteredData,
  setFilteredData,
  clearSearch,
  setClearSearch

}) => {
  const [searchTermSB, setSearchTermSB] = useState("");
  const colorScheme = useColorScheme();
  const handleSearch = () => {

    // if(!searchInUse) {
    //   setSearchTermSB('')
    // }

    if (!searchTermSB || searchTermSB == "") {
      //There's nothing typed in the search box
      console.warn('nothing')
      setSearchInUse(false);
      setSearchTermSB("")
      if (!filterInUse) {
        setDataToShow(masterData)
      }
    } else {
      //there is something in the search box
      setSearchInUse(true);
      if (filterInUse) {
        //the filter is in use
        const text = searchTermSB;
        const terms = text.toLowerCase().split(" ");
        const freshlySeearchedData = filteredData.filter((opp) => {
          return terms.every((term) =>
            `${opp.name}`.toLowerCase().includes(term)
          );
        });

        setFilteredAndSortedData(freshlySeearchedData)
        setDataToShow(freshlySeearchedData);
      } else {
        //there is no filter set
        const text = searchTermSB;
        const terms = text.toLowerCase().split(" ");
        const freshlySeearchedData = masterData.filter((opp) => {
          return terms.every((term) =>
            `${opp.name}`.toLowerCase().includes(term)
          );
        });
        setSearchedData(freshlySeearchedData)
        setDataToShow(freshlySeearchedData);
      }
    }
  };

  useEffect(() => {
    handleSearch();
  }, [searchTermSB]);



  useEffect(() => {
    setSearchTermSB('')
    setClearSearch(false)
  }, [clearSearch]);



  return (
    // <View>

    <TextInput
      style={[
        styles.textInputBox,
        {
          outlineStyle: "none",
          color: Colors[colorScheme].textLessEmphasised,
          backgroundColor: Colors[colorScheme].background,
          borderColor: Colors[colorScheme].edging,
        },
      ]}
      placeholder="Name"
      onChangeText={setSearchTermSB}
      value={searchTermSB}
    />
    // </View>
  );
};









export default function AdminOpportunityManagement({ navigation }) {
  const [dataToShow, setDataToShow] = useState([]);
  const [searchedData, setSearchedData] = useState([])
  const [filteredAndSortedData, setFilteredAndSortedData] = useState([])
  const colorScheme = useColorScheme();
  const [masterData, setMasterData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState("All");
  const [isActiveFilter, setIsActiveFilter] = useState("Active");
  const [termFilter, setTermFilter] = useState('All')
  const [yearFilter, setYearFilter] = useState('All')
  const [searchInUse, setSearchInUse] = useState(false);
  const [filterInUse, setFilterInUse] = useState(false);
  const [clearSearch, setClearSearch] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isActiveOptions, setIsActiveOptions] = useState([
    "Active",
    "true",
    "false",
  ]);

  const fetchMasterData = async () => {
    let nextToken = null;
    let allDataFromResponse = []

    do {
    const response = await API.graphql(graphqlOperation(listOpportunities, {nextToken: nextToken}));


    allDataFromResponse = [...allDataFromResponse, ...response.data.listOpportunities.items];
    nextToken = response.data.listOpportunities.nextToken;
    console.log('token:', nextToken)
  } while (nextToken !== null);




    console.log('response:', allDataFromResponse)
    // console.log('response length:', response.length)
    const sortedData = allDataFromResponse.sort(function (
      a,
      b
    ) {
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    });
    setMasterData(sortedData);
    setIsLoading(false)
    // setFilteredData(sortedData);
  };

  useEffect(() => {
    fetchMasterData();
  }, []);

  useEffect(() => {
    if (masterData) {
      setDataToShow(masterData);
    }
  }, [masterData]);


  const applyFilter = () => {

    if (searchInUse) {
      // SEARCH ALREADY IN USE


      let newFilteredData = searchedData;

      if (categoryFilter !== "All") {


        newFilteredData = newFilteredData.filter(
          (item) => item.OpportunityCategory.name === categoryFilter
        );
      } else {
        setFilterInUse(false)
      }

      if (isActiveFilter !== "Active") {

        newFilteredData = newFilteredData.filter(
          (item) => item.isActive.toString() === isActiveFilter
        );
      }
      else {
        setFilterInUse(false)
      }


      if (termFilter !== "All") {

        newFilteredData = newFilteredData.filter(
          (item) => item.term === termFilter
        );
      }
      else {
        setFilterInUse(false)
      }

      if (yearFilter !== "All") {

        newFilteredData = newFilteredData.filter(
          (item) => item.year === yearFilter
        );
      }
      else {
        setFilterInUse(false)
      }

      setFilteredAndSortedData(newFilteredData)
      setDataToShow(newFilteredData);


    } else {
      //SEARCH NOT IN USE 
      let newFilteredData = masterData;

      if (masterData.length) {
        if (categoryFilter !== "All") {
          console.log("handling cat filter");
          setFilterInUse(true);
          newFilteredData = newFilteredData.filter(
            (item) => item.OpportunityCategory.name === categoryFilter
          );
        } else {
          setFilterInUse(false);
        }
        if (isActiveFilter !== "Active") {
          console.log("handling active filter");
          setFilterInUse(true);
          newFilteredData = newFilteredData.filter(
            (item) => item.isActive.toString() === isActiveFilter
          );
        } else {
          setFilterInUse(false);
        }


        if (termFilter !== "All") {

          newFilteredData = newFilteredData.filter(
            (item) => item.term === termFilter
          );
        }
        else {
          setFilterInUse(false)
        }

        if (yearFilter !== "All") {

          newFilteredData = newFilteredData.filter(
            (item) => item.year === yearFilter
          );
        }
        else {
          setFilterInUse(false)
        }


      }
      setFilteredData(newFilteredData)
      setDataToShow(newFilteredData);
    }


  };
  useEffect(() => {
    if (masterData) {
      setFilteredData(masterData);
    }
  }, [masterData]);

  useEffect(() => {
    applyFilter();
  }, [isActiveFilter]);

  useEffect(() => {
    applyFilter();
  }, [categoryFilter]);

  useEffect(() => {
    applyFilter();
  }, [termFilter]);

  useEffect(() => {
    applyFilter();
  }, [yearFilter]);


  const clearAllSearchAndFilters = () => {

    setDataToShow(masterData)
    setFilteredAndSortedData([])
    setFilteredData([])
    setCategoryFilter("All")
    setIsActiveFilter("Active");
    setTermFilter('All')
    setYearFilter('All')
    setSearchInUse(false);
    setFilterInUse(false);
    setSearchedData([])
    setClearSearch(true)







  }





  const RenderItem = ({ item }) => {

//navigate to the details for this opportunity
const NavigateToOpportunityDetail = () => {
  navigation.navigate('AdminOpportunityDetailEdit', {opportunityID: item.id}) 
}

    return (
      <Pressable onPress={NavigateToOpportunityDetail}>
      <View
        style={[
          styles.tableRow,
          { borderBottomColor: Colors[colorScheme].edging },
        ]}>
        <View style={[styles.tableCell, { width: "10%" }]}>
          {item.isActive ? (
            <MaterialCommunityIcons
              name="sticker-check-outline"
              size={24}
              color={Colors.palette.logoGreen}
            />
          ) : (
            <MaterialCommunityIcons
              name="sticker-remove-outline"
              size={24}
              color={Colors.palette.softRed}
            />
          )}
        </View>
        <View style={[styles.tableCell, { width: "30%" }]}>
          <Text numberOfLines={1} style={styles.tableText}>
            {item.name}
          </Text>
        </View>
        <View style={[styles.tableCell, { width: "20%" }]}>
          <Text numberOfLines={1} style={styles.tableText}>
            {item.year}
          </Text>
        </View>
        <View style={[styles.tableCell, { width: "20%" }]}>
          <Text numberOfLines={1} style={styles.tableText}>
            {item.term}
          </Text>
        </View>
        <View style={[styles.tableCell, { width: "20%" }]}>
          {item.OpportunityCategory.colour ? (
            <Text
              numberOfLines={1}
              style={[
                styles.tableText,
                {
                  color: item.OpportunityCategory.colour
                    ? item.OpportunityCategory.colour
                    : "red",
                },
              ]}>
              {item.OpportunityCategory.name}
            </Text>
          ) : (
            <Text numberOfLines={1} style={styles.tableText}>
              {item.OpportunityCategory.name}
            </Text>
          )}
        </View>
      </View>
      </Pressable>
    );
  };

  return (
    <View
      style={[
        styles.root,
        { backgroundColor: Colors[colorScheme].almostBackground },
      ]}>
      <View style={[styles.filtersContainer, { backgroundColor: Colors[colorScheme].almostBackground }]}>
        
        
        {isLoading?<ActivityIndicator color={Colors.palette.logoGreen} />:<Text>Displaying {dataToShow.length} opportunities</Text>}
        
        
        <Pressable
          onPress={clearAllSearchAndFilters}
        ><Text>Clear search and filters</Text></Pressable>

      </View>

      <View style={[styles.filtersContainer, { backgroundColor: Colors[colorScheme].almostBackground }]}>
        <View style={[styles.filterContainer, { backgroundColor: Colors[colorScheme].almostBackground, width: "10%" }]}>
          {/* <Text style={styles.rowHeaderText}>Is Active:</Text> */}
          <Picker
            selectedValue={isActiveFilter}
            style={[
              styles.textInputBox,
              {
                color: Colors[colorScheme].textLessEmphasised,
                backgroundColor: Colors[colorScheme].background,
                borderColor: Colors[colorScheme].edging,
              },
            ]}
            // onValueChange={handleIsActiveFilter}>
            onValueChange={(itemValue) => setIsActiveFilter(itemValue)}>
            {isActiveOptions.map((option, index) => {
              return <Picker.Item key={index} label={option} value={option} />;
            })}
          </Picker>
        </View>
        <View style={[styles.filterContainer, { backgroundColor: Colors[colorScheme].almostBackground, width: "30%" }]}>
          <SearchBox
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
            masterData={masterData}
            dataToShow={dataToShow}
            setDataToShow={setDataToShow}
            searchInUse={searchInUse}
            setSearchInUse={setSearchInUse}
            filterInUse={filterInUse}
            setFilterInUse={setFilterInUse}
            filteredData={filteredData}
            setFilteredData={setFilteredData}
            filteredAndSortedData={filteredAndSortedData}
            setFilteredAndSortedData={setFilteredAndSortedData}
            searchedData={searchedData}
            setSearchedData={setSearchedData}
          />

        </View>
        <View style={[styles.filterContainer, { backgroundColor: Colors[colorScheme].almostBackground, width: "20%" }]}>
          <Picker
            style={[
              styles.textInputBox,
              {
                color: Colors[colorScheme].textLessEmphasised,
                backgroundColor: Colors[colorScheme].background,
                borderColor: Colors[colorScheme].edging,
              },
            ]}
            selectedValue={yearFilter}
            onValueChange={(itemValue, itemIndex) =>
              setYearFilter(itemValue)
            }>
            <Picker.Item label="Year" value="All" />
            <Picker.Item label="2021-2022" value="2021-2022" />
            <Picker.Item label="2022-2023" value="2022-2023" />
            <Picker.Item label="2023-2024" value="2023-2024" />
          </Picker>
        </View>
        <View style={[styles.filterContainer, { backgroundColor: Colors[colorScheme].almostBackground, width: "20%" }]}>
          <Picker
            style={[
              styles.textInputBox,
              {
                color: Colors[colorScheme].textLessEmphasised,
                backgroundColor: Colors[colorScheme].background,
                borderColor: Colors[colorScheme].edging,
              },
            ]}
            selectedValue={termFilter}
            onValueChange={(itemValue, itemIndex) =>
              setTermFilter(itemValue)
            }>
            <Picker.Item label="Term" value="All" />
            <Picker.Item label="Autumn 1" value="Autumn 1" />
            <Picker.Item label="Autumn 2" value="Autumn 2" />
            <Picker.Item label="Spring 1" value="Spring 1" />
            <Picker.Item label="Spring 2" value="Spring 2" />
            <Picker.Item label="Summer 1" value="Summer 1" />
            <Picker.Item label="Summer 2" value="Summer 2" />
          </Picker>
        </View>
        <View style={[styles.filterContainer, { backgroundColor: Colors[colorScheme].almostBackground, width: "20%" }]}>

          <Picker
            style={[
              styles.textInputBox,
              {
                color: Colors[colorScheme].textLessEmphasised,
                backgroundColor: Colors[colorScheme].background,
                borderColor: Colors[colorScheme].edging,
              },
            ]}
            selectedValue={categoryFilter}
            onValueChange={(itemValue, itemIndex) =>
              setCategoryFilter(itemValue)
            }>
            <Picker.Item label="Category" value="All" />
            <Picker.Item label="Ambassador" value="Ambassador" />
            <Picker.Item label="Work Experience" value="Work Experience" />
            <Picker.Item label="Flexible Learning" value="Flexible Learning" />
            <Picker.Item label="Volunteering" value="Volunteering" />
            <Picker.Item label="Visits" value="Visits" />
            <Picker.Item
              label="Independent Learning"
              value="Independent Learning"
            />
          </Picker>
        </View>
      </View>


      <FlatList
        style={{ width: "100%" }}
        data={dataToShow}
        renderItem={({ item }) => <RenderItem item={item} />}
        keyExtractor={(item) => item.id}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: "center",
    // justifyContent: "center",
    backgroundColor: "transparent",
    width: "100%",
    alignSelf: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "gray",
    alignItems: "center",
    alignSelf: "center",
    width: "90%",
  },
  tableCell: {
    // width: 100,
    padding: 10,
    alignItems: "center",
  },
  tableText: {},
  filtersContainer: {
    width: "90%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 10,
  },
  filterContainer: {
    flexDirection: "column",
    alignItems: "center",
    padding: 10,
  },
  filterInput: {},
  textInputBox: {
    borderWidth: 1,
    margin: 10,
    padding: 10,
    borderRadius: 8,
    fontSize: 14,
    fontFamily: "avenir-next",
    // width: 300,
  },
  rowHeaderText: {
    fontSize: 16,
  },
});
