import { Text, View } from "../../constants/Themed";
import { StyleSheet, Pressable } from "react-native";
import Colors from "../../constants/Colors";
import useColorScheme from "../../hooks/useColorScheme";
import React, { useEffect, useRef, useState } from "react";
import { BoldText } from "../../constants/StyledText";
import { useNavigation } from "@react-navigation/native";
import DynamicBadge from "../DynamicBadge";
import { useWindowDimensions } from 'react-native';
import { Opportunity } from "../../API";
export const listOpportunityAvailabilities = /* GraphQL */ `
  query ListOpportunityAvailabilities(
    $filter: ModelOpportunityAvailabilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOpportunityAvailabilities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupingData1
        groupingData2
        groupingData3
        opportunityID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
//this import is for getting the image out of storage
import { API, graphqlOperation, Storage } from "aws-amplify";

const CardOpportunity = ({
  opportunity
}) => {
  const colorScheme = useColorScheme();
  const navigation = useNavigation();
  const [downloadedImageURI, setDownloadedImageURI] = useState<string | null>(
    null
  ); //state variable for imageURI
const [availability, setAvailability] = useState([]);
  // const stringArray = availabilityArray.toString()

  const {logoURL, dateType, id,engagementLevel,multipleEndDate,
    multipleStartDate,
    colour,
    name,
    location,
    oneOffDate,
    regularFriday,
    regularMonday,
    regularStartTime,
    regularThursday,
    regularTuesday,
    regularWednesday,
  availableTo } = opportunity;


// console.warn('this is the availability', availabilityArray)
const windowWidth = useWindowDimensions().width

const isCancelled = useRef(false);
  const variableItemWidth = windowWidth > 500 ? (windowWidth - 32) /2 : windowWidth - 16  //double the margin


  //get the image URL from storage
  const getImage = async () => {
    const file = await Storage.get(logoURL, { level: "public" }); //get file using query data
    // if (!isCancelled.current) {
    setDownloadedImageURI(file); //store imageURI in state variable to be used elsewhere on the page
    }
  // };


// const getAvailability = async () => {
//   const response = await API.graphql(
//     graphqlOperation(listOpportunityAvailabilities, {
//       filter: {
        
//         opportunityID: {
//               eq: id,
            
//           }
//       },
//     })
//   );
//   if (!isCancelled.current) {
//   setAvailability(response.data.listOpportunityAvailabilities.items);
// }
// };


  useEffect(() => {
    getImage();
    // getAvailability();
    // return () => {
    //   isCancelled.current = true;
    // };

  }, []);

  //navigate to the details for this opportunity
  const NavigateToOpportunityDetail = () => {
    navigation.navigate("OpportunityDetail", { opportunityID: id }); // go to the opportunity page and run the query with this ID
  };



// console.warn('array =', availability)


  //Timing line
  const DateTypeSelector = () => {
    switch (dateType) {
      case "REGULAR":
        return (
          <Text numberOfLines={1}>
            {regularStartTime} on{regularMonday ? " Monday" : null}
            {regularTuesday ? " Tuesday" : null}
            {regularWednesday ? " Wednesday" : null}
            {regularThursday ? " Thursday" : null}
            {regularFriday ? " Friday" : null}
          </Text>
        );

      case "MULTIPLEDATES":
        const mutipleStartDateObject = new Date(multipleStartDate);
        const multipleEndDateObject = new Date(multipleEndDate);
        const formattedMultipleStartDate =
          mutipleStartDateObject.toLocaleDateString("en-GB", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          });
        const formattedMultipleEndDate =
          multipleEndDateObject.toLocaleDateString("en-GB", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          });
        return (
          <Text numberOfLines={1}>
            {formattedMultipleStartDate} until {formattedMultipleEndDate}{" "}
          </Text>
        );

      case "ONEOFFDATE":
        const dateObject = new Date(oneOffDate);
        const formattedDate = dateObject.toLocaleDateString("en-GB", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        });

        return <Text numberOfLines={1}>{formattedDate}</Text>;
      default:
        return <Text>Error, no timing information</Text>;
    }
  };

  return (
    <Pressable onPress={NavigateToOpportunityDetail}>
      <View
        style={[
          styles.root,
          {
            backgroundColor: Colors[colorScheme].background,
            width: variableItemWidth
          },
        ]}
      >
        <View style={{ flexDirection: "row" }}>
          {/* Badge logo */}
          <DynamicBadge
            size={100}
            edgeColour={colour}
            engagementLevel={engagementLevel}
            logo={downloadedImageURI}
          />
          <View style={styles.textContainer}>



            {/* Opportunity Name */}
            <BoldText
              numberOfLines={1}
              style={[styles.title, { color: Colors[colorScheme].text }]}
            >
              {name}
            </BoldText>

            {/* Adds the timing of the event in three diffrent formats */}
            <View style={{marginVertical:5}}>{DateTypeSelector()}</View>

            {/* Location - change this to who this is available to later */}
            {/* <Text numberOfLines={1}>{location}</Text> */}
            <Text numberOfLines={1}>{availableTo}</Text>
{/* <Text numberOfLines={1}>In {location}</Text> */}

        {/* <View style={{flexDirection: 'row', }}>
            {availability.map((item, i) => (
            <Text key={item.id} style={{paddingRight: 4}} numberOfLines={1}>{item.groupingData3}{i < availability.length - 1 ? ',' : null}</Text> //there is a key prop in here to ensure that each mapped item in the array can be identified. This stops the "Warning: Each child in a list should have a unique "key" prop" error. 
          ))}
</View> */}

            {/* <Text numberOfLines={1}>{availabilityArray}</Text> */}
          </View>
        </View>
      </View>
    </Pressable>
  );
};

export default CardOpportunity;

const styles = StyleSheet.create({
  root: {
    borderRadius: 10,
    // borderWidth: 1,
    // flex: 1,
    padding: 10,
    marginHorizontal: 8,
    marginVertical: 8,
    // width: '100%',
    // minWidth: 380,
    // maxWidth: 390,
    // alignSelf: 'center'
  },
  textContainer: {
    marginLeft: 10,
    // width: '100%',
    flex: 1,
    justifyContent: "center",
  },
  icon: {
    width: 80,
    height: 80,
  },
  title: {
    fontSize: 18,
  },
});
