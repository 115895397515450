import React from "react";
import Svg, { Path, Ellipse } from "react-native-svg";

const RobinsonIcon = (color: any) => {


  return (
    
    <Svg id="Layer_1" data-name="Layer 1" viewBox="0 0 300 300">
      <Path d="M66.6,114.5c0.6,1,0.4,1,1.5,0.4c2.8-1.6,5.6-3.1,8.5-4.5c6.8-3.3,13.9-6,21.1-8.2c0,2.8,0,5.6,0,8.4
	c0,1.7,0.3,3.8-0.1,5.5c-0.3,1.3-1,2.7-1.5,4c-1,2.6-1.9,5.2-2.9,7.8c-4.9,13.3-9.8,26.6-14.7,39.9c-0.3,0.9-0.7,1.8-1,2.7
	c-0.2,0.4-0.3,1.2-0.6,1.6c-0.5,0.6-1.9-0.1-2.7-0.3c-2.8-0.7-5.6-1.5-8.3-2.2c-6.5-1.8-12.9-3.7-19.4-5.7c-1.6-0.5-3.3-1-4.9-1.5
	c-0.6-0.2-1.4-0.6-2-0.6c-0.7,0-0.5-0.1-0.8,0.7c-0.2,0.6-0.3,1.1-0.5,1.7c-0.6,2.3-1.1,4.7-1.4,7.1c-0.6,4.7-0.4,9.5,0.4,14.2
	c0.1,0.5,0.1,1.3,0.4,1.8c0.5,1,3.2,1.3,4.4,1.6c3.4,1,6.9,2,10.3,2.9c6,1.7,12,3.3,18,4.8c6,1.5,11.8,2.3,17.6-0.3
	c5.8-2.6,10.1-7.5,12-13.5c0.6-1.8,1.1-3.6,1.6-5.3c2-6.6,4-13.2,6-19.7c0.2-0.5,0.3-1,0.5-1.5c2.2,15.1,4.4,30.2,6.7,45.3
	c0.1,0.7,0,2.1,0.4,2.8c0,0.1,0,0.2,0,0.3c0.3,0.4,3.1,0.3,3.6,0.3c1.4,0.1,2.8,0.2,4.3,0.3c5.1,0.4,10.2,0.6,15.4,0.8
	c1.9,0.1,2.4,0.7,2.7-1c1.5-7.9,3-15.8,4.4-23.7c1.4-7.5,2.8-15,4.2-22.6c2.2,15.1,4.5,30.2,6.7,45.3c0.1,0.5,0,1.7,0.3,2
	c0.3,0.4,3.7,0,4.3,0c2.7-0.1,5.3-0.1,8-0.3c2.4-0.1,4.9-0.2,7.3-0.4c1.2-0.1,2.4-0.2,3.6-0.2c0.4,0,2.2,0.1,2.4-0.2
	c0.3-0.3,0.3-1.4,0.4-1.9c1.6-8.4,3.3-16.8,4.9-25.1c1.4-7.4,2.9-14.7,4.3-22.1c1.2,5,2.3,10,3.5,14.9c0.7,3.1,1.5,6.3,2.2,9.4
	c0.5,2.1,0.9,4.3,1.8,6.3c2.2,5.4,6.6,9.7,12.1,11.9c5.4,2.1,10.7,1.4,16.2,0.1c11.8-2.9,23.4-6.3,34.9-9.9c1-0.3,0.8-0.4,1.1-1.4
	c0.1-0.5,0.2-0.9,0.3-1.4c0.1-0.7,0.2-1.4,0.3-2.1c0.3-1.9,0.4-3.9,0.5-5.8c0.2-5.5-0.8-11.2-2.5-16.4c-7.4,2.7-14.9,5.2-22.5,7.6
	c-3.5,1.1-7.1,2.1-10.7,3.1c-1.5,0.4-3.1,0.8-4.6,1.2c-0.4,0.1-2.3,0.8-2.6,0.6c-0.2-0.2-0.3-1.1-0.4-1.3c-0.3-1-0.5-1.9-0.8-2.9
	c-0.9-3.1-1.8-6.2-2.6-9.3c-4-14.1-8.1-28.2-12.1-42.3c-0.5-1.6-0.4-2.9-0.4-4.6c0-3.7,0-7.3,0-11c6.2,2,12.3,4.4,18.2,7.2
	c2.8,1.4,5.6,2.8,8.4,4.4c1.2,0.7,1.6,1.6,2.3,0.4c3.1-5.2,6.2-10.4,9.3-15.7c1-1.7,2.1-3.5,3.1-5.2c-21.6-12.8-46.4-20.1-71.3-23
	c-7.6-0.9-15.2-1.4-22.8-1.4c9.5-0.9,17.8-7.7,20.6-16.8c2.8-9.2-0.4-19.5-8-25.5c-7.6-6.1-18.4-6.9-26.8-2
	c-8.3,4.8-13,14.4-11.7,23.9c1.5,10.8,10.5,19.4,21.4,20.4c-25.9,0.3-52.1,5-76,15.2c-6.3,2.7-12.4,5.7-18.2,9.2
	c2.7,4.5,5.3,9,8,13.5C63.8,109.7,65.2,112.1,66.6,114.5z M163.5,110.6c0-5.2,0-10.5,0.1-15.7c3.3,0.2,6.6,0.5,9.9,0.9
	c1.5,0.2,3,0.4,4.6,0.6c0.5,0.1,2.5,0.1,2.7,0.5c0.3,0.5,0,1.8,0,2.3c0,1.4,0,2.9,0,4.3c0,3.1,0,6.1,0,9.2c0,0.8,0.1,1.6,0,2.4
	c-0.1,0.8-0.5,1.5-0.7,2.3c-2.5,9.5-4.3,19.3-6.4,28.8c-0.9,4-1.8,8-2.7,12c-1.5-8.8-3.1-17.5-4.6-26.3c-0.8-4.8-1.7-9.6-2.5-14.5
	C163.4,115.3,163.4,113.1,163.5,110.6z M122.9,111.5c0-5,0-10,0-14.9c5.1-0.8,10.3-1.3,15.5-1.6c0,4.3,0,8.6-0.1,13
	c0,3,0.4,6.4-0.1,9.4c-1.7,9.8-4.2,19.5-6.3,29.2c-0.8,3.5-1.5,7.1-2.3,10.6c-1.4-8.4-2.8-16.7-4.2-25.1c-0.8-4.5-1.5-9-2.3-13.6
	C122.8,116.1,122.9,113.9,122.9,111.5z M79.7,44.9c-2-9.8,2.5-20,11-25.1c8.6-5.2,19.8-4.3,27.5,2c7.7,6.4,10.7,17.2,7.2,26.6
	C122.1,57.6,113,64,103.1,64C91.9,64,82,55.9,79.7,44.9z M299.9,173.2C299.9,172.9,300,174.4,299.9,173.2L299.9,173.2z M299.5,186.3
	c-3.5,31.9-25.5,57.8-52.3,73.8c-27.7,16.4-60.4,23.8-92.4,24.4c-32.2,0.6-65.4-5.6-94.1-20.8C33.2,249.1,9.8,225,3.3,193.9
	c-3.5-16.5-1.7-34,4.8-49.5c6.4-15.4,17.2-28.7,30-39.2c0.6,0.8,1.3,1.6,1.9,2.3c3.2,3.9,6.4,7.8,9.6,11.7c1.2,1.5,2.5,3,3.7,4.5
	c0.1,0.1,0.7,0.6,0.7,0.8c0,0.4-1.9,1.6-2.2,1.9c-1,0.8-1.9,1.7-2.8,2.6c-13.6,13-23.1,30.8-22.3,50c0.8,18.1,10.1,34.4,23.2,46.6
	c13.6,12.6,30.9,21,48.6,26.3c18.8,5.6,38.7,7.9,58.3,7.3c19.3-0.6,38.6-4,56.6-10.8c17.1-6.5,33.4-16.2,45.3-30.3
	c12.5-14.7,18.9-34,14.8-53.1c-1.9-9.2-6.1-17.8-11.7-25.4c-2.7-3.7-5.8-7.2-9.2-10.4c-1-0.9-2-1.9-3-2.8c-0.3-0.3-2.2-1.5-2.2-1.9
	c0-0.2,0.5-0.6,0.6-0.8c5.1-6.2,10.2-12.4,15.3-18.6c4.5,3.7,8.8,7.8,12.7,12.2c6.8,7.7,12.6,16.3,16.7,25.7c4.1,9.3,7,19.9,7,30.1
	C300.1,177.5,299.9,181.9,299.5,186.3z M175.1,44.8c-2-9.8,2.5-20,11-25.1c8.6-5.2,19.8-4.3,27.5,2c7.7,6.4,10.7,17.2,7.2,26.6
	c-3.4,9.3-12.5,15.6-22.4,15.6C187.2,63.9,177.3,55.8,175.1,44.8z" fill={color.color}/>
    
    </Svg>
    
        
  );
};

export default RobinsonIcon;
