import {
  StyleSheet,
  Image,
  ActivityIndicator,
  useWindowDimensions,
  ScrollView,
} from "react-native";
import { BoldText } from "../constants/StyledText";
import { Text, View } from "../constants/Themed";
import Colors from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme";
import React, { useState, useEffect } from "react";
import Animated, { useAnimatedStyle, withTiming, Easing, useSharedValue } from 'react-native-reanimated';
import { API, graphqlOperation } from "aws-amplify";
import { Feather } from "@expo/vector-icons";
import TomlinsonIcon from "../../assets/icons/Tomlinson";
import TruemanIcon from "../../assets/icons/Trueman";
import BremnerIcon from "../../assets/icons/Bremner";
import RobinsonIcon from "../../assets/icons/Robinson";
import { Picker } from "@react-native-picker/picker";

export const awardedBadgesByHouse = /* GraphQL */ `
  query AwardedBadgesByHouse(
    $groupingData1Value: String!
    $dateAwarded: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAwardedBadgeFilterInput
    $nextToken: String
  ) {
    awardedBadgesByHouse(
      groupingData1Value: $groupingData1Value
      dateAwarded: $dateAwarded
      sortDirection: $sortDirection
      filter: $filter
      limit: 100000
      nextToken: $nextToken
    ) {
      items {
        id
        dateAwarded
        scoringData3Value
        groupingData1Value
        groupingData2Value
        groupingData3Value
      }
      nextToken
      startedAt
    }
  }
`;

const HousePointsWeekly = ({ }) => {
  var ScalingFactorEver = 1; //default value
  var HousePointsPercentagesEver = {
    Tomlinson: 1,
    Bremner: 1,
    Trueman: 1,
    Robinson: 1,
  }; //default values

  // var firstPlaceThisWeekCoach = 'calculating...' //default

  const WidthOfBarChart = 250;
  const { width } = useWindowDimensions();
  const colorScheme = useColorScheme();


  const animatedWidth = (widthToAnimatedTo) => {
    const animatedStyle = useAnimatedStyle(() => ({
      width: withTiming(widthToAnimatedTo,
        {
          duration: 5500,
          // easing: Easing.bezier(0.25, 0.1, 0.25, 1),
        })
    }));
    return animatedStyle
  }


  const [academicYear, setAcademicYear] = useState('2023-2024')
  const [academicYearStartDate, setAcademicYearStartDate] = useState('2023-09-01')
  const [academicYearEndDate, setAcademicYearEndDate] = useState('2024-08-31')
  
  useEffect(() => {


console.log('setting the academic year')
    if(academicYear == "2023-2024"){
      console.log('to 23-24')
      setAcademicYearStartDate('2023-09-01')
      setAcademicYearEndDate('2024-08-31')
    } else if(academicYear =='2022-2023'){
      console.log('to 22-23')
      setAcademicYearStartDate('2022-09-01')
      setAcademicYearEndDate('2023-08-31')
    } else {
      setAcademicYearStartDate('error')
      setAcademicYearEndDate('error')
    }
  }, [academicYear]);



  const calculateTruemanWidthEver = () => {
    // width of chart multiplied by (total points from all tomlinson record) divided by total multipled by scaling factor
    const calculateTruemanWidthEverResult =
      ((WidthOfBarChart * truemanHousePointsTotal) / TotalHousePointsEver) *
      ScalingFactorEver;
    if (isNaN(calculateTruemanWidthEverResult)) {
      return 0;
    } else {
      return calculateTruemanWidthEverResult;
    }
  };

  const calculateTomlinsonWidthEver = () => {
    // width of chart multiplied by (total points from all tomlinson record) divided by total multipled by scaling factor
    const calculateTomlinsonWidthEverResult =
      ((WidthOfBarChart * tomlinsonHousePointsTotal) / TotalHousePointsEver) *
      ScalingFactorEver;
    if (isNaN(calculateTomlinsonWidthEverResult)) {
      return 0;
    } else {
      return calculateTomlinsonWidthEverResult;
    }
  };

  const calculateRobinsonWidthEver = () => {
    // width of chart multiplied by (total points from all tomlinson record) divided by total multipled by scaling factor
    const calculateRobinsonWidthEverResult =
      ((WidthOfBarChart * robinsonHousePointsTotal) / TotalHousePointsEver) *
      ScalingFactorEver;
    if (isNaN(calculateRobinsonWidthEverResult)) {
      return 0;
    } else {
      return calculateRobinsonWidthEverResult;
    }
  };

  const calculateBremnerWidthEver = () => {
    // width of chart multiplied by (total points from all tomlinson record) divided by total multipled by scaling factor
    const calculateBremnerWidthEverResult =
      ((WidthOfBarChart * bremnerHousePointsTotal) / TotalHousePointsEver) *
      ScalingFactorEver;

    if (isNaN(calculateBremnerWidthEverResult)) {
      return 0;
    } else {
      return calculateBremnerWidthEverResult;
    }
  };

  const calculateTop3CoachingGroupsWeek = (
    rank: number,
    coachOrPoints: string
  ) => {
    const winnerDetailsWeek = totalHousePointsForEachCoachingGroupThisWeekWithOutBlankCoachingGroups.sort(
      (a, b) =>
        parseFloat(b.scoringData3Value) - parseFloat(a.scoringData3Value)
    )[rank];
      // console.warn('totalHousePointsForEachCoachingGroupThisWeekWithOutBlankCoachingGroups', totalHousePointsForEachCoachingGroupThisWeekWithOutBlankCoachingGroups)
      // console.warn('winnerDetailsWeek:', winnerDetailsWeek)
    if (winnerDetailsWeek) {
      var {
        groupingData2Value: winningCoach,
        scoringData3Value: winningPoints,
      } = winnerDetailsWeek;

      if (coachOrPoints === "coach") {
        return winningCoach;
      } else if (coachOrPoints === "points") {
        var {
          groupingData2Value: winningCoach,
          scoringData3Value: winningPoints,
        } = winnerDetailsWeek;
        return winningPoints;
      }
    }
    return "-";
  };

  const calculateTruemanWidthWeek = () => {
    // width of chart multiplied by (total points from all tomlinson record) divided by total multipled by scaling factor
    const calculateTruemanWidthWeekResult =
      ((WidthOfBarChart * truemanHousePointsThisWeek) /
        TotalHousePointsThisWeek) *
      ScalingFactorWeek;
    if (isNaN(calculateTruemanWidthWeekResult)) {
      return 0;
    } else {
      return calculateTruemanWidthWeekResult;
    }
  };

  const calculateTomlinsonWidthWeek = () => {
    // width of chart multiplied by (total points from all tomlinson record) divided by total multipled by scaling factor
    const calculateTomlinsonWidthWeekResult =
      ((WidthOfBarChart * tomlinsonHousePointsThisWeek) /
        TotalHousePointsThisWeek) *
      ScalingFactorWeek;
    if (isNaN(calculateTomlinsonWidthWeekResult)) {
      return 0;
    } else {
      return calculateTomlinsonWidthWeekResult;
    }
  };

  const calculateRobinsonWidthWeek = () => {
    // width of chart multiplied by (total points from all tomlinson record) divided by total multipled by scaling factor
    const calculateRobinsonWidthWeekResult =
      ((WidthOfBarChart * robinsonHousePointsThisWeek) /
        TotalHousePointsThisWeek) *
      ScalingFactorWeek;
    if (isNaN(calculateRobinsonWidthWeekResult)) {
      return 0;
    } else {
      return calculateRobinsonWidthWeekResult;
    }
  };

  const calculateBremnerWidthWeek = () => {
    // width of chart multiplied by (total points from all tomlinson record) divided by total multipled by scaling factor
    const calculateBremnerWidthWeekResult =
      ((WidthOfBarChart * bremnerHousePointsThisWeek) /
        TotalHousePointsThisWeek) *
      ScalingFactorWeek;

    if (isNaN(calculateBremnerWidthWeekResult)) {
      return 0;
    } else {
      return calculateBremnerWidthWeekResult;
    }
  };

  const calculateTop3CoachingGroupsEver = (
    rank: number,
    coachOrPoints: string
  ) => {
    const winnerDetailsEver = totalHousePointsForEachCoachingGroupEverWithoutBlankCoaching.sort(
      (a, b) =>
        parseFloat(b.scoringData3Value) - parseFloat(a.scoringData3Value)
    )[rank];
    if (winnerDetailsEver) {
      var {
        groupingData2Value: winningCoach,
        scoringData3Value: winningPoints,
      } = winnerDetailsEver;

      if (coachOrPoints === "coach") {
        return winningCoach;
      } else if (coachOrPoints === "points") {
        var {
          groupingData2Value: winningCoach,
          scoringData3Value: winningPoints,
        } = winnerDetailsEver;
        return winningPoints;
      }
    }
    return "-";
  };

  //create date variables
  let today = new Date();
  const formattedToday = today.toISOString().slice(0, 10);
  today.setDate(today.getDate() - 1);
  const formattedYesterday = today.toISOString().slice(0, 10);
  today.setDate(today.getDate() - 1);
  const formatted2DaysAgo = today.toISOString().slice(0, 10);
  today.setDate(today.getDate() - 1);
  const formatted3DaysAgo = today.toISOString().slice(0, 10);
  today.setDate(today.getDate() - 1);
  const formatted4DaysAgo = today.toISOString().slice(0, 10);
  today.setDate(today.getDate() - 1);
  const formatted5DaysAgo = today.toISOString().slice(0, 10);
  today.setDate(today.getDate() - 1);
  const formatted6DaysAgo = today.toISOString().slice(0, 10);

  //create state variables
  const [bremnerBadges, setBremnerBadges] = useState([]);
  const [tomlinsonBadges, setTomlinsonBadges] = useState([]);
  const [truemanBadges, setTruemanBadges] = useState([]);
  const [robinsonBadges, setRobinsonBadges] = useState([]);

  const [doneFetching, setDoneFetching] = useState(false);

  const fetchBremnerHousePoints = async () => {
    let nextToken = null;
    let allBremnerBadges = [];
    console.log('academic start is:', academicYearStartDate)
    do {
      const response = await API.graphql(graphqlOperation(awardedBadgesByHouse, {
        groupingData1Value: "Bremner",
        dateAwarded: {
          between: [academicYearStartDate, academicYearEndDate],
        },
        sortDirection: "ASC",
        filter: null,
        nextToken: nextToken
      }));

      allBremnerBadges = [...allBremnerBadges, ...response.data.awardedBadgesByHouse.items];
      nextToken = response.data.awardedBadgesByHouse.nextToken;
      // console.warn('nextToken:', nextToken)
    } while (nextToken !== null);

    setBremnerBadges(allBremnerBadges);
  };

  const fetchTomlinsonHousePoints = async () => {
    let nextToken = null;
    let allTomlinsonBadges = [];
    // const startDate = academicYearStartDate;
    // const endDate = academicYearEndDate;
    // console.log('academic year start:', academicYearStartDate)
    // console.log('academic year end:', academicYearEndDate)
    console.log('academic start is:', academicYearStartDate)
    do {
      const response = await API.graphql(graphqlOperation(awardedBadgesByHouse, {
        groupingData1Value: "Tomlinson",
        dateAwarded: {
          between: [academicYearStartDate, academicYearEndDate],
        },
        sortDirection: "ASC",
        filter: null,
        nextToken: nextToken
      }));

      allTomlinsonBadges = [...allTomlinsonBadges, ...response.data.awardedBadgesByHouse.items];
      nextToken = response.data.awardedBadgesByHouse.nextToken;
      // console.warn('nextToken:', nextToken)
    } while (nextToken !== null);

    setTomlinsonBadges(allTomlinsonBadges);
  };


  const fetchTruemanHousePoints = async () => {
    let nextToken = null;
    let allTruemanBadges = [];
    console.log('academic start is:', academicYearStartDate)
    do {
      const response = await API.graphql(graphqlOperation(awardedBadgesByHouse, {
        groupingData1Value: "Trueman",
        dateAwarded: {
          between: [academicYearStartDate, academicYearEndDate],
        },
        sortDirection: "ASC",
        filter: null,
        nextToken: nextToken
      }));

      allTruemanBadges = [...allTruemanBadges, ...response.data.awardedBadgesByHouse.items];
      nextToken = response.data.awardedBadgesByHouse.nextToken;
      // console.warn('nextToken:', nextToken)
    } while (nextToken !== null);

    setTruemanBadges(allTruemanBadges);
  };

  const fetchRobinsonHousePoints = async () => {
    let nextToken = null;
    let allRobinsonBadges = [];


    console.log('academic start is:', academicYearStartDate)

    do {
      const response = await API.graphql(graphqlOperation(awardedBadgesByHouse, {
        groupingData1Value: "Robinson",
        dateAwarded: {
          between: [academicYearStartDate, academicYearEndDate],
        },
        sortDirection: "ASC",
        filter: null,
        nextToken: nextToken
      }));

      allRobinsonBadges = [...allRobinsonBadges, ...response.data.awardedBadgesByHouse.items];
      nextToken = response.data.awardedBadgesByHouse.nextToken;
      // console.warn('nextToken:', nextToken)
    } while (nextToken !== null);

    setRobinsonBadges(allRobinsonBadges);
    setDoneFetching(true); //IMPORTANT! This renders the page!
  };

  useEffect(() => {
    //fetch the awarded badges for each house from the server
    fetchBremnerHousePoints();
    fetchTomlinsonHousePoints();
    fetchTruemanHousePoints();
    fetchRobinsonHousePoints();
    console.log('the year is', academicYear)
  }, [academicYearStartDate]);

  // calculate the totals (since records began) for each house using the State
  const bremnerHousePointsTotal = bremnerBadges.reduce(
    (a, curr) => a + curr.scoringData3Value,
    0
  );
  const tomlinsonHousePointsTotal = tomlinsonBadges.reduce(
    (a, curr) => a + curr.scoringData3Value,
    0
  );
  const truemanHousePointsTotal = truemanBadges.reduce(
    (a, curr) => a + curr.scoringData3Value,
    0
  );
  const robinsonHousePointsTotal = robinsonBadges.reduce(
    (a, curr) => a + curr.scoringData3Value,
    0
  );

  // for the purposes of creating the bar chart, add up all of the house points Ever and turn this into percentages
  const TotalHousePointsEver =
    bremnerHousePointsTotal +
    tomlinsonHousePointsTotal +
    truemanHousePointsTotal +
    robinsonHousePointsTotal;

  var HousePointsPercentagesEver = {
    Tomlinson: tomlinsonHousePointsTotal / TotalHousePointsEver,
    Bremner: bremnerHousePointsTotal / TotalHousePointsEver,
    Trueman: truemanHousePointsTotal / TotalHousePointsEver,
    Robinson: robinsonHousePointsTotal / TotalHousePointsEver,
  };

  //use the largest percetngage value to caluclate a scaling factor
  const arrEver = Object.values(HousePointsPercentagesEver);
  const maxEver = Math.max(...arrEver);
  var ScalingFactorEver = WidthOfBarChart / (maxEver * WidthOfBarChart);

  //filter out the badges for the last week for each house
  const bremnerBadgesThisWeek = bremnerBadges.filter(
    (p) =>
      String(p.dateAwarded).startsWith(formattedToday) ||
      String(p.dateAwarded).startsWith(formattedYesterday) ||
      String(p.dateAwarded).startsWith(formatted2DaysAgo) ||
      String(p.dateAwarded).startsWith(formatted3DaysAgo) ||
      String(p.dateAwarded).startsWith(formatted4DaysAgo) ||
      String(p.dateAwarded).startsWith(formatted5DaysAgo) ||
      String(p.dateAwarded).startsWith(formatted6DaysAgo)
  );

  const tomlinsonBadgesThisWeek = tomlinsonBadges.filter(
    (p) =>
      String(p.dateAwarded).startsWith(formattedToday) ||
      String(p.dateAwarded).startsWith(formattedYesterday) ||
      String(p.dateAwarded).startsWith(formatted2DaysAgo) ||
      String(p.dateAwarded).startsWith(formatted3DaysAgo) ||
      String(p.dateAwarded).startsWith(formatted4DaysAgo) ||
      String(p.dateAwarded).startsWith(formatted5DaysAgo) ||
      String(p.dateAwarded).startsWith(formatted6DaysAgo)
  );

  const truemanBadgesThisWeek = truemanBadges.filter(
    (p) =>
      String(p.dateAwarded).startsWith(formattedToday) ||
      String(p.dateAwarded).startsWith(formattedYesterday) ||
      String(p.dateAwarded).startsWith(formatted2DaysAgo) ||
      String(p.dateAwarded).startsWith(formatted3DaysAgo) ||
      String(p.dateAwarded).startsWith(formatted4DaysAgo) ||
      String(p.dateAwarded).startsWith(formatted5DaysAgo) ||
      String(p.dateAwarded).startsWith(formatted6DaysAgo)
  );


  const robinsonBadgesThisWeek = robinsonBadges.filter(
    (p) =>
      String(p.dateAwarded).startsWith(formattedToday) ||
      String(p.dateAwarded).startsWith(formattedYesterday) ||
      String(p.dateAwarded).startsWith(formatted2DaysAgo) ||
      String(p.dateAwarded).startsWith(formatted3DaysAgo) ||
      String(p.dateAwarded).startsWith(formatted4DaysAgo) ||
      String(p.dateAwarded).startsWith(formatted5DaysAgo) ||
      String(p.dateAwarded).startsWith(formatted6DaysAgo)
  );

  //add up the house points from the filtered badges (just last week) for each house
  const bremnerHousePointsThisWeek = bremnerBadgesThisWeek.reduce(
    (a, curr) => a + curr.scoringData3Value,
    0
  );
  const tomlinsonHousePointsThisWeek = tomlinsonBadgesThisWeek.reduce(
    (a, curr) => a + curr.scoringData3Value,
    0
  );
  const truemanHousePointsThisWeek = truemanBadgesThisWeek.reduce(
    (a, curr) => a + curr.scoringData3Value,
    0
  );
  const robinsonHousePointsThisWeek = robinsonBadgesThisWeek.reduce(
    (a, curr) => a + curr.scoringData3Value,
    0
  );

  //combine all badges records from all houses for this week
  const allBadgesThisWeek = bremnerBadgesThisWeek.concat(
    tomlinsonBadgesThisWeek,
    truemanBadgesThisWeek,
    robinsonBadgesThisWeek
  );

  // console.warn('allBadgesThisWeek', allBadgesThisWeek)

  //combine all badges records from all houses for this week
  const allBadgesEver = bremnerBadges.concat(
    tomlinsonBadges,
    truemanBadges,
    robinsonBadges
  );

  //work out a total for each coaching group from the last week
  const reduceData = (allBadgesThisWeek) =>
    allBadgesThisWeek.reduce((acc, cur) => {
      const { groupingData2Value, scoringData3Value } = cur; // Get name and value from current item
      const item = acc.find(
        (it) => it.groupingData2Value === groupingData2Value
      ); // Find in our accumulator the desired object
      item
        ? (item.scoringData3Value += scoringData3Value)
        : acc.push({ groupingData2Value, scoringData3Value }); // Update object or create a new object if it doesn't exist
      return acc; // Return accumulator
    }, []);

  //create an array with all Coaching groups for this week
  const totalHousePointsForEachCoachingGroupThisWeek =
    reduceData(allBadgesThisWeek);


    // console.warn(totalHousePointsForEachCoachingGroupThisWeek)


  //filter out the blank coaching group introduced by the house competitions
  const totalHousePointsForEachCoachingGroupThisWeekWithOutBlankCoachingGroups = totalHousePointsForEachCoachingGroupThisWeek.filter(row => row.groupingData2Value !== null)
    // console.warn('totalHousePointsForEachCoachingGroupThisWeek', totalHousePointsForEachCoachingGroupThisWeek)



  //create an array with all Coaching groups since records began
  const totalHousePointsForEachCoachingGroupEver = reduceData(allBadgesEver);

  //filter out the blank coaching group introduced by the house competitions
  const totalHousePointsForEachCoachingGroupEverWithoutBlankCoaching = totalHousePointsForEachCoachingGroupEver.filter(row => row.groupingData2Value !== null)




  //extract all of the coaching groups this week
  const tomlinsonCoachingGroups = tomlinsonBadgesThisWeek.map(
    (a) => a.groupingData2Value
  );
  const bremnerCoachingGroups = bremnerBadgesThisWeek.map(
    (a) => a.groupingData2Value
  );
  const truemanCoachingGroups = truemanBadgesThisWeek.map(
    (a) => a.groupingData2Value
  );
  const robinsonCoachingGroups = robinsonBadgesThisWeek.map(
    (a) => a.groupingData2Value
  );

  //combine all of the coaching groups this week
  const allCoachingGroups = tomlinsonCoachingGroups.concat(
    bremnerCoachingGroups,
    truemanCoachingGroups,
    robinsonCoachingGroups
  );

  //store the weekly totals in an array
  const HousePointsWeeklyTotalByHouse = {
    Tomlinson: tomlinsonHousePointsThisWeek,
    Bremner: bremnerHousePointsThisWeek,
    Trueman: truemanHousePointsThisWeek,
    Robinson: robinsonHousePointsThisWeek,
  };

  //for the purposes of creating the bar chart, add up all of the house points this week and turn this into percentages
  const TotalHousePointsThisWeek =
    HousePointsWeeklyTotalByHouse.Bremner +
    HousePointsWeeklyTotalByHouse.Tomlinson +
    HousePointsWeeklyTotalByHouse.Trueman +
    HousePointsWeeklyTotalByHouse.Robinson;

  //trun the weekly house points into percentages
  const HousePointsPercentages = {
    Tomlinson:
      HousePointsWeeklyTotalByHouse.Tomlinson / TotalHousePointsThisWeek,
    Bremner: HousePointsWeeklyTotalByHouse.Bremner / TotalHousePointsThisWeek,
    Trueman: HousePointsWeeklyTotalByHouse.Trueman / TotalHousePointsThisWeek,
    Robinson: HousePointsWeeklyTotalByHouse.Robinson / TotalHousePointsThisWeek,
  };

  //use the largest value to caluclate a scaling factor for the weekly chart
  const arr = Object.values(HousePointsPercentages);
  const max = Math.max(...arr);
  const ScalingFactorWeek = WidthOfBarChart / (max * WidthOfBarChart);

  const drawTomlinsonIcon = () => (
    <TomlinsonIcon color={Colors.palette.tomlinson}
    />);
  const drawTruemanIcon = () => (
    <TruemanIcon color={Colors.palette.trueman}
    />);
  const drawBremnerIcon = () => (
    <BremnerIcon color={Colors.palette.bremner}
    />);
  const drawRobinsonIcon = () => (
    <RobinsonIcon color={Colors.palette.robinson}
    />);

  const EverResultsArray = [
    {
      House: 'Trueman',
      Width: calculateTruemanWidthEver(),
      Points: truemanHousePointsTotal,
      Logo: drawTruemanIcon(),
      Colour: Colors.palette.trueman,
    },
    {
      House: 'Tomlinson',
      Width: calculateTomlinsonWidthEver(),
      Points: tomlinsonHousePointsTotal,
      Logo: drawTomlinsonIcon(),
      Colour: Colors.palette.tomlinson,
    },
    {
      House: 'Bremner',
      Width: calculateBremnerWidthEver(),
      Points: bremnerHousePointsTotal,
      Logo: drawBremnerIcon(),
      Colour: Colors.palette.bremner,
    },
    {
      House: 'Robinson',
      Width: calculateRobinsonWidthEver(),
      Points: robinsonHousePointsTotal,
      Logo: drawRobinsonIcon(),
      Colour: Colors.palette.robinson,
    }
  ];

  const WeekResultsArray = [
    {
      House: 'Trueman',
      Width: calculateTruemanWidthWeek(),
      Points: truemanHousePointsThisWeek,
      Logo: drawTruemanIcon(),
      Colour: Colors.palette.trueman,
    },
    {
      House: 'Tomlinson',
      Width: calculateTomlinsonWidthWeek(),
      Points: tomlinsonHousePointsThisWeek,
      Logo: drawTomlinsonIcon(),
      Colour: Colors.palette.tomlinson,
    },
    {
      House: 'Bremner',
      Width: calculateBremnerWidthWeek(),
      Points: bremnerHousePointsThisWeek,
      Logo: drawBremnerIcon(),
      Colour: Colors.palette.bremner,
    },
    {
      House: 'Robinson',
      Width: calculateRobinsonWidthWeek(),
      Points: robinsonHousePointsThisWeek,
      Logo: drawRobinsonIcon(),
      Colour: Colors.palette.robinson,
    }
  ];


  const sortedEverResultsArray = EverResultsArray.sort((a, b) => b.Points - a.Points);
  const sortedWeeklResultsArray = WeekResultsArray.sort((a, b) => b.Points - a.Points);




  const HouseBar = ({ houseName, houseWidth, houseLogo, housePoints, houseColour }) => {
    return (



      <View style={styles.houseBar}>

        <View style={styles.houseLogo}>
          {houseLogo}</View>
        <View
          style={[
            styles.barBackground,
            {
              width: WidthOfBarChart,
            },
          ]}
        >
          <Animated.View
            style={[
              // styles.barColorBlock,
              {
                backgroundColor: houseColour,
                width: 0,
                height: 25,
                borderRadius: 5,
                paddingHorizontal: 5,
                alignItems: "flex-end",
                justifyContent: "center",
              },
              animatedWidth(houseWidth)
            ]}
          >
            <Text
              style={[
                styles.pointsNumber,
                { color: Colors[colorScheme].background },
              ]}
            >
              {housePoints}
            </Text>
          </Animated.View>
        </View>
      </View>

    )
  };


  if (doneFetching) {
    return (




      <View style={{ flex: 1, width: "100%", backgroundColor: "transparent" }}>





        <ScrollView
          contentContainerStyle={{ flexGrow: 1, alignItems: "center" }}
        >

<Picker
          style={[
            styles.textInputBox,
            {
              width: 400,
              color: Colors[colorScheme].textLessEmphasised,
              backgroundColor: Colors[colorScheme].background,
              borderColor: Colors[colorScheme].edging,
            },
          ]}
          selectedValue={academicYear}
          onValueChange={(itemValue, itemIndex) =>
            setAcademicYear(itemValue)
          }
          
          >

<Picker.Item label="2023-2024" value="2023-2024" />
<Picker.Item label="2022-2023" value="2022-2023" />

</Picker>


          <View
            style={
              width > 1000 ? styles.horizontalLayout : styles.verticalLayout
            }
          >
            {/* THIS YEAR */}
            <View
              style={[
                styles.container,
                {
                  backgroundColor: Colors[colorScheme].background,
                  width: WidthOfBarChart + 90,
                },
              ]}
            >
              <View style={{ backgroundColor: 'transparent', flexDirection: 'row', alignItems: 'center', borderBottomWidth: 1, borderColor: Colors[colorScheme].edging }}>
                <BoldText
                  style={[
                    styles.titleText,
                    { color: Colors[colorScheme].textLessEmphasised },
                  ]}
                >
                  HOUSE POINTS:
                </BoldText>
                <Text style={{ fontWeight: '800', fontSize: 20, marginLeft: 10, color: Colors[colorScheme].textLessEmphasised }}>{academicYear}</Text>

              </View>

              {sortedEverResultsArray?.map((house) => (
                <HouseBar houseName={house.House}
                  houseLogo={house.Logo}
                  houseWidth={house.Width}
                  houseColour={house.Colour}
                  housePoints={house.Points}
                  key={house.House} />
              ))}



              <View style={{ padding: 10, backgroundColor: "transparent" }}>
                <Text>
                  The Coaching groups with the most House Points this academic year are:{" "}
                </Text>

                <View style={styles.top3RowContainer}>
                  <Feather name="award" size={30} color="gold" />
                  <BoldText style={{ color: Colors[colorScheme].text }}>
                    {calculateTop3CoachingGroupsEver(0, "coach")}
                  </BoldText>
                  <Text style={{ color: Colors[colorScheme].text }}>
                    {calculateTop3CoachingGroupsEver(0, "points")}
                  </Text>
                </View>
                <View style={styles.top3RowContainer}>
                  <Feather name="award" size={30} color="silver" />
                  <BoldText style={{ color: Colors[colorScheme].text }}>
                    {calculateTop3CoachingGroupsEver(1, "coach")}
                  </BoldText>
                  <Text style={{ color: Colors[colorScheme].text }}>
                    {calculateTop3CoachingGroupsEver(1, "points")}
                  </Text>
                </View>
                <View style={styles.top3RowContainer}>
                  <Feather name="award" size={30} color="#cd7f32" />
                  <BoldText style={{ color: Colors[colorScheme].text }}>
                    {calculateTop3CoachingGroupsEver(2, "coach")}
                  </BoldText>
                  <Text style={{ color: Colors[colorScheme].text }}>
                    {calculateTop3CoachingGroupsEver(2, "points")}
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={[
                styles.container,
                {
                  backgroundColor: Colors[colorScheme].background,
                  width: WidthOfBarChart + 90,
                },
              ]}
            >

              <View style={{ backgroundColor: 'transparent', flexDirection: 'row', alignItems: 'center', borderBottomWidth: 1, borderColor: Colors[colorScheme].edging }}>
                <BoldText
                  style={[
                    styles.titleText,
                    { color: Colors[colorScheme].textLessEmphasised },
                  ]}
                >
                  HOUSE POINTS:
                </BoldText>
                <Text style={{ fontWeight: '800', fontSize: 20, marginLeft: 10, color: Colors[colorScheme].textLessEmphasised }}>This Week</Text>

              </View>

              {sortedWeeklResultsArray?.map((house) => (
                <HouseBar houseName={house.House}
                  houseLogo={house.Logo}
                  houseWidth={house.Width}
                  houseColour={house.Colour}
                  housePoints={house.Points} 
                  key={house.House}/>
              ))}



              <View style={{ padding: 10, backgroundColor: "transparent" }}>
                <Text>
                  The Coaching groups with the most House Points this week are:{" "}
                </Text>

                <View style={styles.top3RowContainer}>
                  <Feather name="award" size={30} color="gold" />
                  <BoldText style={{ color: Colors[colorScheme].text }}>
                    {calculateTop3CoachingGroupsWeek(0, "coach")}
                  </BoldText>
                  <Text style={{ color: Colors[colorScheme].text }}>
                    {calculateTop3CoachingGroupsWeek(0, "points")}
                  </Text>
                </View>
                <View style={styles.top3RowContainer}>
                  <Feather name="award" size={30} color="silver" />
                  <BoldText style={{ color: Colors[colorScheme].text }}>
                    {calculateTop3CoachingGroupsWeek(1, "coach")}
                  </BoldText>
                  <Text style={{ color: Colors[colorScheme].text }}>
                    {calculateTop3CoachingGroupsWeek(1, "points")}
                  </Text>
                </View>
                <View style={styles.top3RowContainer}>
                  <Feather name="award" size={30} color="#cd7f32" />
                  <BoldText style={{ color: Colors[colorScheme].text }}>
                    {calculateTop3CoachingGroupsWeek(2, "coach")}
                  </BoldText>
                  <Text style={{ color: Colors[colorScheme].text }}>
                    {calculateTop3CoachingGroupsWeek(2, "points")}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    );
  } else return <ActivityIndicator />;
};

export default HousePointsWeekly;

const styles = StyleSheet.create({
  barBackground: {
    backgroundColor: "transparent",
    height: 25,
    borderRadius: 5,
    flexDirection: "row",
  },
  barColorBlock: {
    height: 25,
    borderRadius: 5,
    paddingHorizontal: 5,
    alignItems: "flex-end",
    justifyContent: "center",
  },
  houseLogo: { width: 40, height: 40, margin: 10 },
  houseBar: { flexDirection: "row", alignItems: "center", marginRight: 10 },
  pointsNumber: { marginHorizontal: 5, color: "black" },
  titleText: {
    fontSize: 24,
    alignSelf: "center",
    marginVertical: 10,
  },
  container: {
    padding: 10,

    margin: 10,
    borderRadius: 10,
    // justifyContent: "space-evenly",
  },
  horizontalLayout: {
    backgroundColor: "tranparent",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-evenly",
  },
  verticalLayout: {
    backgroundColor: "tranparent",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
  },
  top3RowContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: 'flex-start',
    padding: 10,
    alignItems: "center",
    backgroundColor: "transparent",
  },
  textInputBox: {
    borderWidth: 1,
    margin: 10,
    padding: 10,
    borderRadius: 8,
    fontSize: 16,
    fontFamily: "avenir-next",
    width: 250,
  },
});
