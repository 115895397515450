import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  FlatList,
  TextInput,
  TouchableOpacity,
  Modal,
} from "react-native";
import RecognitionLogoIndentIcon from "../../../assets/icons/RecognitionLogoIndent";
import { Text, View } from "../../constants/Themed";
import useColorScheme from "../../hooks/useColorScheme";
import Colors from "../../constants/Colors";
import { useNavigation } from "@react-navigation/native";
import { API, graphqlOperation, Storage } from "aws-amplify";
//note, you have removed the line about limit from the top of this query (where it gets defined as an int) and added a limit of 2000. If you don't do this, you don't get all of the results!
export const listUsers = /* GraphQL */ `
  query ListUsers($filter: ModelUserFilterInput, $nextToken: String) {
    listUsers(filter: $filter, limit: 9999, nextToken: $nextToken) {
      items {
        id
        surname
        firstName
        preferredFirstName
        preferredSurname
        aaa
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        pushToken
        Enrolments {
          items {
            internalUniqueID
            contextData1
            contextData2
            contextData3
            groupingData1
            groupingData2
            groupingData3
            photoPermission
            scoringData1
            scoringData2
            scoringData3
            startDate
            endDate
            isActive
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;

const SearchBox = ({ masterData, users, setUsers }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = () => {
    const text = searchTerm;
    const terms = text.toLowerCase().split(" ");
    const filteredUsers = masterData.filter((user) => {
      return terms.every((term) =>
        `${user.preferredSurname} ${user.surname} ${user.preferredFirstName} ${user.firstName}`
          .toLowerCase()
          .includes(term)
      );
    });
    setUsers(filteredUsers);
  };

  useEffect(() => {
    handleSearch();
  }, [searchTerm]);

  return (
    <View>
      <Text>{users.length}</Text>
      <TextInput
        placeholder="Search for a user"
        onChangeText={setSearchTerm}
        value={searchTerm}
      />
    </View>
  );
};

const UserForm = ({ onSubmit, initialValues }) => {
  const [preferredFirstName, setpreferredFirstName] = useState(
    initialValues.preferredFirstName
  );
  const [surname, setSurname] = useState(initialValues.surname);
  const [email, setEmail] = useState(initialValues.email);
  const [gender, setGender] = useState(initialValues.gender);

  const Enrolments = initialValues.Enrolments.items[0];

  const [internalUniqueId, setInternalUniqueId] = useState(
    Enrolments ? Enrolments.internalUniqueID : null
  );
  console.warn("IUID:", internalUniqueId);

  return (
    <View style={styles.formContainer}>
      <TextInput
        style={styles.input}
        value={preferredFirstName}
        onChangeText={setpreferredFirstName}
        placeholder="First name"
      />
      <TextInput
        style={styles.input}
        value={surname}
        onChangeText={setSurname}
        placeholder="Surname"
      />
      <TextInput
        style={styles.input}
        value={email}
        onChangeText={setEmail}
        placeholder="Email"
      />
      <TextInput
        style={styles.input}
        value={gender}
        onChangeText={setGender}
        placeholder="Gender"
      />

      {internalUniqueId ? (
        <TextInput
          style={styles.input}
          value={internalUniqueId}
          onChangeText={setInternalUniqueId}
          placeholder="Internal Unique ID"
        />
      ) : (
        <Text>no enrolments</Text>
      )}
      <TouchableOpacity
        style={styles.button}
        onPress={() =>
          onSubmit({ preferredFirstName, surname, email, gender })
        }>
        <Text style={styles.buttonText}>Save</Text>
      </TouchableOpacity>
    </View>
  );
};

const UserList = () => {
  // const { loading, error, data } = useQuery(GET_USERS);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  // const [updateUser] = useMutation(UPDATE_USER);
  const [modalVisible, setModalVisible] = useState(false);

  const [filteredData, setFilteredData] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [userArrayJustStaff, setUserArrayJustStaff] = useState([]);

  const fetchUsersList = async () => {
    const response = await API.graphql(
      graphqlOperation(listUsers, {
        filter: {
          type: {
            ne: "ALUMNI",
            // eq: "CHILD"
          },
        },
      })
    );

    setMasterData(response.data.listUsers.items);
    setUserArrayJustStaff(
      response.data.listUsers.items.filter(
        (item) => item.type === "STAFF" || item.type === "ADMIN"
      )
    );
  };

  useEffect(() => {
    fetchUsersList();
  }, []);

  useEffect(() => {
    if (masterData) {
      setUsers(masterData);
    }
  }, [masterData]);

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setModalVisible(true);
  };

  const handleUpdateUser = async (values) => {
    const { preferredFirstName, surname, email, gender } = values;

    console.warn(values);
    // await updateUser({
    //   variables: {
    //     input: {
    //       id: selectedUser.id,
    //       preferredFirstName,
    //       surname,
    //       email,
    //       gender
    //     }
    //   }
    // });
    setModalVisible(false);
  };

  return (
    <View style={styles.container}>
      <SearchBox masterData={masterData} users={users} setUsers={setUsers} />
      <FlatList
        data={users}
        keyExtractor={(item) => item.id}
        renderItem={({ item }) => (
          <TouchableOpacity
            style={styles.row}
            onPress={() => handleEditUser(item)}>
            <View style={styles.column}>
              <Text style={styles.text}>{item.preferredFirstName}</Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.text}>{item.surname}</Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.text}>{item.email}</Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.text}>{item.gender}</Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.text}>
                {item.isActive ? "Active" : "Inactive"}
              </Text>
            </View>
          </TouchableOpacity>
        )}
      />
      <Modal visible={modalVisible} animationType="slide">
        <View style={styles.modalContainer}>
          <TouchableOpacity
            style={styles.closeButton}
            onPress={() => setModalVisible(false)}>
            <Text style={styles.closeButtonText}>Close</Text>
          </TouchableOpacity>
          <UserForm onSubmit={handleUpdateUser} initialValues={selectedUser} />
        </View>
      </Modal>
    </View>
  );
};
export default UserList;

const styles = StyleSheet.create({
  container: {
    width: "80%",
    flex: 1,
  },
  row: {
    flexDirection: "row",
    padding: 20,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  text: {
    flex: 1,
    marginRight: 10,
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
  },

  formContainer: {
    alignSelf: "stretch",
    padding: 20,
  },
  input: {
    height: 40,
    marginBottom: 20,
    paddingHorizontal: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  button: {
    backgroundColor: "#666",
    padding: 10,
    alignSelf: "center",
    marginTop: 20,
  },
  buttonText: {
    color: "#fff",
    fontWeight: "bold",
  },
  column: {
    width: 100, // you can adjust the width of the column to fit the design
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  closeButton: {
    position: "absolute",
    top: 20,
    right: 20,
  },
  closeButtonText: {
    fontSize: 18,
  },
});
