import { StyleSheet, FlatList,TextInput, Pressable, Animated } from "react-native";
import { View } from "../../constants/Themed";
import { useState, useRef, useEffect } from "react";
import { Feather } from "@expo/vector-icons";
import Colors from "../../constants/Colors";
import useColorScheme from "../../hooks/useColorScheme";
import SearchBarMobile from "../../components/mobile/SearchBar";
import CardTimeline from "../../components/CardTimeline";
import { API, graphqlOperation } from "aws-amplify";
import { useWindowDimensions } from 'react-native';
import SearchBarMobileV2 from "../../components/mobile/SearchBarV2";
// import { TextInput } from "react-native-gesture-handler";






//I'M PRETTY SURE WE DON'T NEED THIS FILE ANY MORE. IT WAS A TEST FOR THE NEW SEARCH SYSTEM.





//note, you have removed the line about limit from the top of this query (where it gets defined as an int) and added a limit of 2000. If you don't do this, you don't get all of the results!
export const listUsers = /* GraphQL */ `
  query ListUsers($filter: ModelUserFilterInput, $nextToken: String) {
    listUsers(filter: $filter, limit: 2000, nextToken: $nextToken) {
      items {
        id
        surname
        aaa
        preferredFirstName
        type
        photoURL
        isActive
        Enrolments {
          items {
            groupingData1
            groupingData2
            groupingData3
            organisationID
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;


export default function TimelinesRootMobileV2({ navigation }) {
  const [searchBarToggle, setSearchBarToggle] = useState(true);
  const colorScheme = useColorScheme();
  const windowWidth = useWindowDimensions().width;
  const [searchPhrase, setSearchPhrase] = useState("");
  const isCancelled = useRef(false);
  const [usersList, setUsersList] = useState([]);
  
  const fetchUsersList = async () => {
    const response = await API.graphql(
      graphqlOperation(listUsers, {
        filter: {


//THIS IS NEW 

          // and: [
          //   {
          //     aaa: {
            //     contains: searchPhrase,
            //   },
            // },
            // {
              type: { ne: "ALUMNI", eq: "CHILD" },
        //     },
        //   ],
        },
      })
    );
    
      // setUsersList(response.data.listUsers.items);
      setFilteredData(response.data.listUsers.items);
      setMasterData(response.data.listUsers.items);
    
  };
  const firstRender = useRef(true); //run the query only when the user starts typing, not when the screen first loads
  
  
  useEffect(() => {
    
    // if (firstRender.current) {
      // firstRender.current = false;
    // } else {
        fetchUsersList(); 
    // }
    // return () => {
    // };
  }, []);
  // },[]);

  //function to toggle the visibility of the search bar
  const ToggleSearchBar = () => {
    setSearchBarToggle(!searchBarToggle);
  };

  //when the togglesearchbar state variable changes state, change the look of the search button
  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Pressable onPress={ToggleSearchBar}>
          <Feather
            name="search"
            size={24}
            color={
              searchBarToggle
                ? Colors.palette.logoGreen
                : Colors[colorScheme].buttons
            }
            style={{ marginRight: 10 }}
          />
        </Pressable>
      ),
    });
  }, [ToggleSearchBar]);

  const searchBarAnim = useRef(new Animated.Value(-145)).current;
  useEffect(() => {
    if (searchBarToggle) {
      Animated.timing(searchBarAnim, {
        toValue: 0,
        duration: 150,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(searchBarAnim, {
        toValue: -70,
        duration: 150,
        useNativeDriver: true,
      }).start();
    }
  }, [searchBarToggle]);

  const [clicked, setClicked] = useState(false);



// THIS IS NEW - TRYING TO MATCH THE QUERY STYLE TO THE OPPORTUNITIES
  // const renderItem = ({ item }) => {
  //   if (searchPhrase === "") {
  //     return (
  //       <CardTimeline connection={item} />
  //     );
  //   }
  //   if (
  //     item.aaa
  //       .toUpperCase()
  //       .includes(searchPhrase.toUpperCase().trim().replace(/\s/g, ""))
  //   ) {
  //     return (
  //       <CardTimeline connection={item} />
  //     );
  //   }
  // };




const searchFilter = (text) => {
  if(text) {
    const newData = masterData.filter((item) => {
      const itemData = item.aaa ? item.aaa.toUpperCase() : ''.toLowerCase()
      const textData = text.toUpperCase();
      return itemData.indexOf(textData) > -1;
    });
    setFilteredData(newData); 
    setSearch(text);
  }
  else {
    setFilteredData(masterData);
    setSearch(text);
  }
}





const [filteredData, setFilteredData] = useState([]);
const [masterData, setMasterData] = useState([]);
const [search, setSearch] = useState('');

  return (
    <View style={styles.root}>

      {/* search bar */}
      <Animated.View style={{ transform: [{ translateY: searchBarAnim }] }}>
        {/* <SearchBarMobileV2
          searchPhrase={searchPhrase}
          setSearchPhrase={setSearchPhrase}
          clicked={clicked}
          setClicked={setClicked}
        /> */}

      <TextInput style={{backgroundColor:'red'}}
      value={search}
      placeholder='SEARCH HERE'
      onChangeText={(text) => searchFilter(text)}
      
      />



      </Animated.View>

      {/* View to hold the flatlist */}
      <Animated.View
        style={{ transform: [{ translateY: searchBarAnim }], height: "100%",alignItems: 'center', width: '100%', marginTop:5 }}
      >
        {/* flatlist of pupil photos */}
        <FlatList
          data={filteredData}
          numColumns={windowWidth > 500 ? 2 : 1}
          renderItem={({ item }) => (
            <CardTimeline connection={item} />
          )}
          // renderItem={renderItem}
          keyExtractor={(item) => item.id}
        />
      </Animated.View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: "transparent",
  },

  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
