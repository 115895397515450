/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOpportunityAvailability = /* GraphQL */ `
  query GetOpportunityAvailability($id: ID!) {
    getOpportunityAvailability(id: $id) {
      id
      groupingData1
      groupingData2
      groupingData3
      opportunityID
      Opportunity {
        id
        isActive
        name
        aaa
        description
        logoURL
        location
        colour
        engagementLevel
        regularMonday
        regularTuesday
        regularWednesday
        regularThursday
        regularFriday
        regularStartTime
        regularEndTime
        dateType
        oneOffDate
        regularStartDat
        regularEndDate
        multipleStartDate
        multipleEndDate
        totalHours
        userID
        opportunitycategoryID
        availableTo
        year
        term
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listOpportunityAvailabilities = /* GraphQL */ `
  query ListOpportunityAvailabilities(
    $filter: ModelOpportunityAvailabilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOpportunityAvailabilities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupingData1
        groupingData2
        groupingData3
        opportunityID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOpportunityAvailabilities = /* GraphQL */ `
  query SyncOpportunityAvailabilities(
    $filter: ModelOpportunityAvailabilityFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOpportunityAvailabilities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        groupingData1
        groupingData2
        groupingData3
        opportunityID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAwardedBadge = /* GraphQL */ `
  query GetAwardedBadge($id: ID!) {
    getAwardedBadge(id: $id) {
      id
      dateAwarded
      scoringData1Value
      scoringData2Value
      scoringData3Value
      groupingData1Value
      groupingData2Value
      groupingData3Value
      opportunityID
      Opportunity {
        id
        isActive
        name
        aaa
        description
        logoURL
        location
        colour
        engagementLevel
        regularMonday
        regularTuesday
        regularWednesday
        regularThursday
        regularFriday
        regularStartTime
        regularEndTime
        dateType
        oneOffDate
        regularStartDat
        regularEndDate
        multipleStartDate
        multipleEndDate
        totalHours
        userID
        opportunitycategoryID
        availableTo
        year
        term
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      userID
      User {
        id
        surname
        firstName
        preferredFirstName
        preferredSurname
        aaa
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAwardedBadges = /* GraphQL */ `
  query ListAwardedBadges(
    $filter: ModelAwardedBadgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAwardedBadges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dateAwarded
        scoringData1Value
        scoringData2Value
        scoringData3Value
        groupingData1Value
        groupingData2Value
        groupingData3Value
        opportunityID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAwardedBadges = /* GraphQL */ `
  query SyncAwardedBadges(
    $filter: ModelAwardedBadgeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAwardedBadges(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        dateAwarded
        scoringData1Value
        scoringData2Value
        scoringData3Value
        groupingData1Value
        groupingData2Value
        groupingData3Value
        opportunityID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const awardedBadgesByHouse = /* GraphQL */ `
  query AwardedBadgesByHouse(
    $groupingData1Value: String!
    $dateAwarded: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAwardedBadgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    awardedBadgesByHouse(
      groupingData1Value: $groupingData1Value
      dateAwarded: $dateAwarded
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dateAwarded
        scoringData1Value
        scoringData2Value
        scoringData3Value
        groupingData1Value
        groupingData2Value
        groupingData3Value
        opportunityID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getOpportunityCategory = /* GraphQL */ `
  query GetOpportunityCategory($id: ID!) {
    getOpportunityCategory(id: $id) {
      id
      name
      aaa
      isActive
      logoWhiteURI
      logoColourURI
      logoGreyURI
      colour
      Opportunities {
        nextToken
        startedAt
      }
      organisationID
      Organisation {
        id
        name
        aaa
        logoURL
        type
        contextDefinition1
        contextDefinition2
        contextDefinition3
        groupingDefinition1
        groupingDefinition2
        groupingDefinition3
        scoringDefinition1
        scoringDefinition2
        scoringDefinition3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listOpportunityCategories = /* GraphQL */ `
  query ListOpportunityCategories(
    $filter: ModelOpportunityCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOpportunityCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        aaa
        isActive
        logoWhiteURI
        logoColourURI
        logoGreyURI
        colour
        organisationID
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOpportunityCategories = /* GraphQL */ `
  query SyncOpportunityCategories(
    $filter: ModelOpportunityCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOpportunityCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        aaa
        isActive
        logoWhiteURI
        logoColourURI
        logoGreyURI
        colour
        organisationID
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCriteria = /* GraphQL */ `
  query GetCriteria($id: ID!) {
    getCriteria(id: $id) {
      id
      name
      aaa
      description
      logoColourURI
      colour
      strapline
      categoryName
      categoryStrapline
      categoryLogoURL
      videoURL
      synonyms
      level1Description
      level2Description
      level3Description
      level4Description
      level5Description
      level6Description
      Mappings {
        nextToken
        startedAt
      }
      logoWhiteURI
      logoGreyURI
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCriteria = /* GraphQL */ `
  query ListCriteria(
    $filter: ModelCriteriaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCriteria(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        aaa
        description
        logoColourURI
        colour
        strapline
        categoryName
        categoryStrapline
        categoryLogoURL
        videoURL
        synonyms
        level1Description
        level2Description
        level3Description
        level4Description
        level5Description
        level6Description
        logoWhiteURI
        logoGreyURI
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCriteria = /* GraphQL */ `
  query SyncCriteria(
    $filter: ModelCriteriaFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCriteria(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        aaa
        description
        logoColourURI
        colour
        strapline
        categoryName
        categoryStrapline
        categoryLogoURL
        videoURL
        synonyms
        level1Description
        level2Description
        level3Description
        level4Description
        level5Description
        level6Description
        logoWhiteURI
        logoGreyURI
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMapping = /* GraphQL */ `
  query GetMapping($id: ID!) {
    getMapping(id: $id) {
      id
      level
      criteriaID
      opportunityID
      Criteria {
        id
        name
        aaa
        description
        logoColourURI
        colour
        strapline
        categoryName
        categoryStrapline
        categoryLogoURL
        videoURL
        synonyms
        level1Description
        level2Description
        level3Description
        level4Description
        level5Description
        level6Description
        logoWhiteURI
        logoGreyURI
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Opportunity {
        id
        isActive
        name
        aaa
        description
        logoURL
        location
        colour
        engagementLevel
        regularMonday
        regularTuesday
        regularWednesday
        regularThursday
        regularFriday
        regularStartTime
        regularEndTime
        dateType
        oneOffDate
        regularStartDat
        regularEndDate
        multipleStartDate
        multipleEndDate
        totalHours
        userID
        opportunitycategoryID
        availableTo
        year
        term
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      isActive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listMappings = /* GraphQL */ `
  query ListMappings(
    $filter: ModelMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMappings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        level
        criteriaID
        opportunityID
        isActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMappings = /* GraphQL */ `
  query SyncMappings(
    $filter: ModelMappingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMappings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        level
        criteriaID
        opportunityID
        isActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getOpportunity = /* GraphQL */ `
  query GetOpportunity($id: ID!) {
    getOpportunity(id: $id) {
      id
      isActive
      name
      aaa
      description
      logoURL
      location
      colour
      engagementLevel
      regularMonday
      regularTuesday
      regularWednesday
      regularThursday
      regularFriday
      regularStartTime
      regularEndTime
      dateType
      oneOffDate
      regularStartDat
      regularEndDate
      multipleStartDate
      multipleEndDate
      totalHours
      userID
      User {
        id
        surname
        firstName
        preferredFirstName
        preferredSurname
        aaa
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Mappings {
        nextToken
        startedAt
      }
      opportunitycategoryID
      OpportunityCategory {
        id
        name
        aaa
        isActive
        logoWhiteURI
        logoColourURI
        logoGreyURI
        colour
        organisationID
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      AwardedBadges {
        nextToken
        startedAt
      }
      OpportunityAvailabilities {
        nextToken
        startedAt
      }
      availableTo
      year
      term
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listOpportunities = /* GraphQL */ `
  query ListOpportunities(
    $filter: ModelOpportunityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOpportunities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isActive
        name
        aaa
        description
        logoURL
        location
        colour
        engagementLevel
        regularMonday
        regularTuesday
        regularWednesday
        regularThursday
        regularFriday
        regularStartTime
        regularEndTime
        dateType
        oneOffDate
        regularStartDat
        regularEndDate
        multipleStartDate
        multipleEndDate
        totalHours
        userID
        opportunitycategoryID
        availableTo
        year
        term
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOpportunities = /* GraphQL */ `
  query SyncOpportunities(
    $filter: ModelOpportunityFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOpportunities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        isActive
        name
        aaa
        description
        logoURL
        location
        colour
        engagementLevel
        regularMonday
        regularTuesday
        regularWednesday
        regularThursday
        regularFriday
        regularStartTime
        regularEndTime
        dateType
        oneOffDate
        regularStartDat
        regularEndDate
        multipleStartDate
        multipleEndDate
        totalHours
        userID
        opportunitycategoryID
        availableTo
        year
        term
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEnrolment = /* GraphQL */ `
  query GetEnrolment($id: ID!) {
    getEnrolment(id: $id) {
      id
      internalUniqueID
      contextData1
      contextData2
      contextData3
      groupingData1
      groupingData2
      groupingData3
      photoPermission
      scoringData1
      scoringData2
      scoringData3
      startDate
      endDate
      isActive
      userID
      organisationID
      User {
        id
        surname
        firstName
        preferredFirstName
        preferredSurname
        aaa
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Organisation {
        id
        name
        aaa
        logoURL
        type
        contextDefinition1
        contextDefinition2
        contextDefinition3
        groupingDefinition1
        groupingDefinition2
        groupingDefinition3
        scoringDefinition1
        scoringDefinition2
        scoringDefinition3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listEnrolments = /* GraphQL */ `
  query ListEnrolments(
    $filter: ModelEnrolmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnrolments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        internalUniqueID
        contextData1
        contextData2
        contextData3
        groupingData1
        groupingData2
        groupingData3
        photoPermission
        scoringData1
        scoringData2
        scoringData3
        startDate
        endDate
        isActive
        userID
        organisationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEnrolments = /* GraphQL */ `
  query SyncEnrolments(
    $filter: ModelEnrolmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEnrolments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        internalUniqueID
        contextData1
        contextData2
        contextData3
        groupingData1
        groupingData2
        groupingData3
        photoPermission
        scoringData1
        scoringData2
        scoringData3
        startDate
        endDate
        isActive
        userID
        organisationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getOrganisation = /* GraphQL */ `
  query GetOrganisation($id: ID!) {
    getOrganisation(id: $id) {
      id
      name
      aaa
      logoURL
      type
      contextDefinition1
      contextDefinition2
      contextDefinition3
      groupingDefinition1
      groupingDefinition2
      groupingDefinition3
      scoringDefinition1
      scoringDefinition2
      scoringDefinition3
      Enrolments {
        nextToken
        startedAt
      }
      OpportunityCategories {
        nextToken
        startedAt
      }
      Media {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listOrganisations = /* GraphQL */ `
  query ListOrganisations(
    $filter: ModelOrganisationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganisations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        aaa
        logoURL
        type
        contextDefinition1
        contextDefinition2
        contextDefinition3
        groupingDefinition1
        groupingDefinition2
        groupingDefinition3
        scoringDefinition1
        scoringDefinition2
        scoringDefinition3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOrganisations = /* GraphQL */ `
  query SyncOrganisations(
    $filter: ModelOrganisationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrganisations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        aaa
        logoURL
        type
        contextDefinition1
        contextDefinition2
        contextDefinition3
        groupingDefinition1
        groupingDefinition2
        groupingDefinition3
        scoringDefinition1
        scoringDefinition2
        scoringDefinition3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      surname
      firstName
      preferredFirstName
      preferredSurname
      aaa
      title
      type
      photoURL
      dateOfBirth
      email
      gender
      isActive
      pushToken
      Enrolments {
        nextToken
        startedAt
      }
      Opportunities {
        nextToken
        startedAt
      }
      AwardedBadges {
        nextToken
        startedAt
      }
      ConnectionsUsersThatSeeThisUser {
        nextToken
        startedAt
      }
      ConnectionsUsersThisUserSees {
        nextToken
        startedAt
      }
      MomentsContributed {
        nextToken
        startedAt
      }
      MomentsActuallUploaded {
        nextToken
        startedAt
      }
      Tags {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        surname
        firstName
        preferredFirstName
        preferredSurname
        aaa
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        surname
        firstName
        preferredFirstName
        preferredSurname
        aaa
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getConnection = /* GraphQL */ `
  query GetConnection($id: ID!) {
    getConnection(id: $id) {
      id
      connectorID
      connecteeID
      Connector {
        id
        surname
        firstName
        preferredFirstName
        preferredSurname
        aaa
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Connectee {
        id
        surname
        firstName
        preferredFirstName
        preferredSurname
        aaa
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listConnections = /* GraphQL */ `
  query ListConnections(
    $filter: ModelConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConnections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        connectorID
        connecteeID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncConnections = /* GraphQL */ `
  query SyncConnections(
    $filter: ModelConnectionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncConnections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        connectorID
        connecteeID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMoment = /* GraphQL */ `
  query GetMoment($id: ID!) {
    getMoment(id: $id) {
      id
      dateUploaded
      dateMomentHappened
      caption
      contributorID
      actuallyUploadedByID
      Contributor {
        id
        surname
        firstName
        preferredFirstName
        preferredSurname
        aaa
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      ActuallyUploadedBy {
        id
        surname
        firstName
        preferredFirstName
        preferredSurname
        aaa
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Media {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listMoments = /* GraphQL */ `
  query ListMoments(
    $filter: ModelMomentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMoments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dateUploaded
        dateMomentHappened
        caption
        contributorID
        actuallyUploadedByID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMoments = /* GraphQL */ `
  query SyncMoments(
    $filter: ModelMomentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMoments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        dateUploaded
        dateMomentHappened
        caption
        contributorID
        actuallyUploadedByID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMedia = /* GraphQL */ `
  query GetMedia($id: ID!) {
    getMedia(id: $id) {
      id
      s3Key
      momentID
      organisationID
      Moment {
        id
        dateUploaded
        dateMomentHappened
        caption
        contributorID
        actuallyUploadedByID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Organisation {
        id
        name
        aaa
        logoURL
        type
        contextDefinition1
        contextDefinition2
        contextDefinition3
        groupingDefinition1
        groupingDefinition2
        groupingDefinition3
        scoringDefinition1
        scoringDefinition2
        scoringDefinition3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Tags {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listMedia = /* GraphQL */ `
  query ListMedia(
    $filter: ModelMediaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedia(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        s3Key
        momentID
        organisationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMedia = /* GraphQL */ `
  query SyncMedia(
    $filter: ModelMediaFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMedia(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        s3Key
        momentID
        organisationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      mediaID
      Media {
        id
        s3Key
        momentID
        organisationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      userID
      User {
        id
        surname
        firstName
        preferredFirstName
        preferredSurname
        aaa
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mediaID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTags = /* GraphQL */ `
  query SyncTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTags(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        mediaID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
