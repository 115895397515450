import { StyleSheet, FlatList, Pressable, Animated,useWindowDimensions } from "react-native";
import { View, Text } from "../../constants/Themed";
import { useState, useRef, useEffect,useContext } from "react";
import { Feather } from "@expo/vector-icons";
import Colors from "../../constants/Colors";
import useColorScheme from "../../hooks/useColorScheme";
import SearchBarMobile from "../../components/mobile/SearchBar";
import CardTimeline from "../../components/CardTimeline";
import { API, graphqlOperation } from "aws-amplify";
import { UserContext } from "../../components/Context";
import { DrawerContentScrollView } from "@react-navigation/drawer";
import ThumbnailTimeline from "../../components/ThumbnailTimeline";
import { BoldText } from "../../constants/StyledText";

//note, you have removed the line about limit from the top of this query (where it gets defined as an int) and added a limit of 2000. If you don't do this, you don't get all of the results!
export const listUsers = /* GraphQL */ `
  query ListUsers($filter: ModelUserFilterInput, $nextToken: String) {
    listUsers(filter: $filter, limit: 9999, nextToken: $nextToken) {
      items {
        id
        surname
        aaa
        preferredFirstName
        type
        photoURL
        isActive
        Enrolments {
          items {
            groupingData1
            groupingData2
            groupingData3
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const listConnections = /* GraphQL */ `
  query ListConnections(
    $filter: ModelConnectionFilterInput
    
    $nextToken: String
  ) {
    listConnections(filter: $filter, limit: 9999, nextToken: $nextToken) {
      items {
        id
        connectorID
        connecteeID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export default function TimelinesRootMobile({ navigation }) {

  const userDetailsFromUserContext = useContext(UserContext);
  const [searchBarToggle, setSearchBarToggle] = useState(true);
  const colorScheme = useColorScheme();
  const windowWidth = useWindowDimensions().width;
  const [searchPhrase, setSearchPhrase] = useState("");
  const isCancelled = useRef(false);
  const [usersList, setUsersList] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [search, setSearch] = useState('');
  const [connectionsListData, setConnectionsListData] = useState([]);
  const getConnectionsForThisID = userDetailsFromUserContext.id
  
  const fetchUsersList = async () => {
    const response = await API.graphql(
      graphqlOperation(listUsers, {
        filter: {




          // and: [
          //   {
          //     aaa: {
          //       contains: searchPhrase,
          //     },
          //   },
          //   {
              type: { ne: "ALUMNI", eq: "CHILD" },
            },
          // ],
        // },
      })
    );
    // if (!isCancelled.current) {
      // setUsersList(response.data.listUsers.items);
      setFilteredData(response.data.listUsers.items);
      setMasterData(response.data.listUsers.items);
    // }
  };
  // const firstRender = useRef(true); //run the query only when the user starts typing, not when the screen first loads
  

  const fetchConnectionsList = async () => {
    const response = await API.graphql(
      graphqlOperation(listConnections, {
        filter: {

          connectorID: {eq:userDetailsFromUserContext.id},
            },
          
      })
    );
    
    setConnectionsListData(response.data.listConnections.items);
  };

  console.warn(connectionsListData)

  
  useEffect(() => {
    
    // if (firstRender.current) {
    //   firstRender.current = false;
    // } else {
      // console.warn(userDetailsFromUserContext.id)
        fetchUsersList(); 
        fetchConnectionsList();
    // }
    // return () => {
      // isCancelled.current = true;
    // };
  }, []);
  // },[]);

  //function to toggle the visibility of the search bar
  const ToggleSearchBar = () => {
    setSearchBarToggle(!searchBarToggle);
  };

  //when the togglesearchbar state variable changes state, change the look of the search button
  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Pressable onPress={ToggleSearchBar}>
          <Feather
            name="search"
            size={24}
            color={
              searchBarToggle
                ? Colors.palette.logoGreen
                : Colors[colorScheme].buttons
            }
            style={{ marginRight: 10 }}
          />
        </Pressable>
      ),
    });
  }, [ToggleSearchBar]);

  const searchBarAnim = useRef(new Animated.Value(-145)).current;
  useEffect(() => {
    if (searchBarToggle) {
      Animated.timing(searchBarAnim, {
        toValue: 0,
        duration: 150,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(searchBarAnim, {
        toValue: -70,
        duration: 150,
        useNativeDriver: true,
      }).start();
      setSearch('');
      setFilteredData(masterData);
    }
  }, [searchBarToggle]);

 



// THIS IS NEW - TRYING TO MATCH THE QUERY STYLE TO THE OPPORTUNITIES
  // const renderItem = ({ item }) => {
  //   if (searchPhrase === "") {
  //     return (
  //       <CardTimeline connection={item} />
  //     );
  //   }
  //   if (
  //     item.aaa
  //       .toUpperCase()
  //       .includes(searchPhrase.toUpperCase().trim().replace(/\s/g, ""))
  //   ) {
  //     return (
  //       <CardTimeline connection={item} />
  //     );
  //   }
  // };




  const searchFilter = (text) => {
    if(text) {
      const newData = masterData.filter((item) => {
        const itemData = item.aaa ? item.aaa.toUpperCase() : ''.toLowerCase()
        const textData = text.toUpperCase();
        // console.warn(item.Enrolments.groupingData3)
        return itemData.indexOf(textData) > -1; 
      });
      //new
      // const newDataYear9Only = newData.filter((item) => )

      
      //end of new
      setFilteredData(newData); 
      setSearch(text);
    }
    else {
      setFilteredData(masterData);
      setSearch(text);
    }
  }



const numberOfColumns = windowWidth > 500 ? 2 : 1

const [stateVariableForNumberOfColumns,setStateVariableForNumberOfColumns] = useState(numberOfColumns)

useEffect(() => {
  setStateVariableForNumberOfColumns(numberOfColumns)
}, [numberOfColumns]);





  return (
    // <View style ={{flex: 1, width: '100%', backgroundColor: Colors[colorScheme].almostBackground}}>
    <View style={[styles.root, {backgroundColor: Colors[colorScheme].almostBackground}]}>

      {/* search bar */}
      <Animated.View style={{ transform: [{ translateY: searchBarAnim }] }}>
        <SearchBarMobile
          searchPhrase={search}
          setSearchPhrase={(text) => searchFilter(text)}
          clicked={clicked}
          setClicked={setClicked}
        />
      </Animated.View>

      {/* View to hold the flatlist */}
      <Animated.View
        style={{ transform: [{ translateY: searchBarAnim }], height: "100%",alignItems: 'center', width: '100%', marginTop:5 }}
      >
        {/* flatlist of pupil photos */}
        {search ? 
        <FlatList
          data={filteredData}
          numColumns={stateVariableForNumberOfColumns}
          renderItem={({ item }) => (
            <CardTimeline connection={item} />
          )}
          // renderItem={renderItem}
          keyExtractor={(item) => item.id}
          key={stateVariableForNumberOfColumns}
        /> : connectionsListData.length > 0 ? 
      //   <View style={{backgroundColor: 'transparent', flex:1}}>
      //   <BoldText
      //   style={{
      //     color: Colors[colorScheme].text,
      //     fontSize: 16,
      //     alignSelf: "center",
      //     padding: 15,
      //   }}
      // >
      //   {userDetailsFromUserContext.title} {userDetailsFromUserContext.surname}'s Coaching Group
      // </BoldText>
        <FlatList
          data={connectionsListData}
          numColumns={stateVariableForNumberOfColumns+2}
          // horizontal
          renderItem={({ item }) => (
            <ThumbnailTimeline id={item.connecteeID} />
          )}
          // renderItem={renderItem}
          keyExtractor={(item) => item.id}
          key={stateVariableForNumberOfColumns + 2}
        /> 
        // </View>
        : null
        
        }
      </Animated.View>
    </View>
    // </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    // backgroundColor: "transparent",
  },

  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
