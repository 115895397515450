import { StyleSheet, Pressable, ActivityIndicator } from "react-native";
import { View, Text } from "../constants/Themed";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import { BoldText } from "../constants/StyledText";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { StatusBar } from "expo-status-bar";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { useWindowDimensions, Platform } from "react-native";
import MobileNavigation from "../navigation/Mobile";
import DesktopNavigation from "../navigation/Desktop";
import useColorScheme from "../hooks/useColorScheme";
import { UserContext } from "../components/Context";
import Colors from "../constants/Colors";
import NavigationCheck from "./NavigationCheck";

export const listUsers = /* GraphQL */ `
  query ListUsers($filter: ModelUserFilterInput, $nextToken: String) {
    listUsers(filter: $filter, limit: 2000, nextToken: $nextToken) {
      items {
        id
        surname
        firstName
        preferredFirstName
        aaa
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      surname
      firstName
      preferredFirstName
      preferredSurname
      aaa
      title
      type
      photoURL
      dateOfBirth
      email
      gender
      isActive
      pushToken
      Enrolments {
        nextToken
        startedAt
      }
      Opportunities {
        nextToken
        startedAt
      }
      AwardedBadges {
        nextToken
        startedAt
      }
      ConnectionsUsersThatSeeThisUser {
        nextToken
        startedAt
      }
      ConnectionsUsersThisUserSees {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export default function AuthenticationCheck() {
  const colorScheme = useColorScheme();
  const [pushToken, setPushToken] = useState();
  const windowSize = useWindowDimensions();
  const [currentLoggedInUser, setCurrentLoggedInUser] = useState();
  const [isGetUserDetailsDone, setIsGetUserDetailsDone] = useState(false);
  const [isGetMoreUserDetailsDone, setIsGetMoreUserDetailsDone] =
    useState(false);
  const [fullUserProfile, setFullUserProfile] = useState();
  const [isUserAllowedIn, setIsUserAllowedIn] = useState(false);
  const [groupArray, setGroupArray] = useState([]);

  // first call to check who is logged in
  // we get their email address and Cognito groups here
  const getUserDetails = async () => {
    const loggedInUserDetails = await Auth.currentAuthenticatedUser();
    setGroupArray(
      loggedInUserDetails.signInUserSession.accessToken.payload[
        "cognito:groups"
      ]
    );
    const { attributes } = loggedInUserDetails;
    setCurrentLoggedInUser(attributes);
    setIsGetUserDetailsDone(true);
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  //second query to check who that person is using their email as the lookup value
  //we get all the stuff in the graphQL query at the top of this file in this query
  const getMoreUserDetails = async () => {
    const response = await API.graphql(
      graphqlOperation(listUsers, {
        filter: {
          and: [
            {
              email: {
                eq: currentLoggedInUser.email,
              },
            },
            {
              isActive: { eq: true },
            },
          ],
        },
      })
    );
    setFullUserProfile(response.data.listUsers.items[0]);
    setIsGetMoreUserDetailsDone(true);
  };

  useEffect(() => {
    if (isGetUserDetailsDone) {
      getMoreUserDetails();
    }
  }, [isGetUserDetailsDone]);

  const checkEmailAndGroup = () => {
    if (currentLoggedInUser?.email.includes("@carrmanor.org.uk")) {
      //user's email address is from Carr Manor

      //IMPORTANT - DON'T DELETE!
      // the code below checks the cognito user groups. It works, but you would have to get each member of staff in the right group before they could use the app. Instead, use the 'type' field in the database instead (code below)

      // if (Array.isArray(groupArray) && groupArray.length) {
      //   // GroupArray exists and is not empty (ie. the staff member is in a group)

      //   if (groupArray.includes("CarrManorStaff")) {
      //     console.warn("we good!");
      //     setIsUserAllowedIn(true);
      //   } else {
      //     console.warn("not in the right group");
      //   }
      // } else {
      //   console.warn("not in any group");
      // }

      if (fullUserProfile?.type === "STAFF") {
        console.warn(
          "the user appears to have a staff account in the database :o)"
        );
        setIsUserAllowedIn(true);
      } else if (fullUserProfile?.type === "ADMIN") {
        setIsUserAllowedIn(true);
      } else {
        console.warn("the user might not be a staff member - not cool");
        console.warn(fullUserProfile);
      }
    } else {
      console.warn("not a Carr Manor email address");
    }
  };

  useEffect(() => {
    if (isGetMoreUserDetailsDone) {
      checkEmailAndGroup();
    }
  }, [isGetMoreUserDetailsDone]);

  

  const PageContent = () => {
    //the page that's displayed if the user isn't allowed access
    return (
      <View
        style={[
          styles.root,
          { backgroundColor: Colors[colorScheme].background },
        ]}
      >
        <BoldText style={[styles.text, { color: Colors[colorScheme].text }]}>
          Thanks for signing up to Recognition.
        </BoldText>

        <Text style={[styles.text, { color: Colors[colorScheme].text }]}>
          Your email address is:{" "}
          {isGetUserDetailsDone ? (
            currentLoggedInUser.email
          ) : (
            <ActivityIndicator />
          )}
        </Text>

        <Text style={[styles.text, { color: Colors[colorScheme].text }]}>
          At the moment, only authorised users from Carr Manor Community School
          can log in.
        </Text>
        <View
          style={{
            backgroundColor: Colors.palette.logoGreen,
            // borderWidth: 1,
            padding: 10,
            margin: 20,
            borderRadius: 10,
          }}
        >
          <Pressable onPress={() => Auth.signOut()}>
            <BoldText
              style={[styles.text, { color: Colors[colorScheme].text }]}
            >
              Sign Out
            </BoldText>
          </Pressable>
        </View>
      </View>
    );
  };

  if (isUserAllowedIn) {
    //the user is allowed in; this is the root of the entire app where we establish context (user), safe area and send them off to check for notification permissions and set up the navigation
    return (
      <UserContext.Provider value={fullUserProfile}>
        <NavigationCheck fullUserProfile={fullUserProfile} />
      </UserContext.Provider>
    );
  } else {
    return (
      //the user isn't allowed in so show the screen that tells them they don't have permission.
      <View style={styles.root}>
        {isGetMoreUserDetailsDone ? <PageContent /> : <ActivityIndicator />}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "transparent",
    alignSelf: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  text: {
    textAlign: "center",
    padding: 10,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
