import React from "react";
import { View, Text } from "../constants/Themed";
import { Opportunity } from "../API";

const formatDate = (date: Date) =>
  date.toLocaleDateString("en-GB", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

export default function DateTypeSelector({
  opportunity,
}: {
  opportunity: Opportunity;
}) {
  
  const {
    dateType,
    regularStartTime,
    regularMonday,
    regularTuesday,
    regularWednesday,
    regularThursday,
    regularFriday,
    multipleStartDate,
    oneOffDate,
    multipleEndDate,
  } = opportunity;

  switch (dateType) {
    case "REGULAR":
      return (
        <Text numberOfLines={1}>
          {regularStartTime} on{regularMonday ? " Monday" : null}
          {regularTuesday ? " Tuesday" : null}
          {regularWednesday ? " Wednesday" : null}
          {regularThursday ? " Thursday" : null}
          {regularFriday ? " Friday" : null}
        </Text>
      );

    case "MULTIPLEDATES":
      if (!multipleStartDate || !multipleEndDate) return null;

      const mutipleStartDateObject = new Date(multipleStartDate);
      const formattedMultipleStartDate = formatDate(mutipleStartDateObject);

      const multipleEndDateObject = new Date(multipleEndDate);
      const formattedMultipleEndDate = formatDate(multipleEndDateObject);

      return (
        <Text numberOfLines={1}>
          {formattedMultipleStartDate} until {formattedMultipleEndDate}{" "}
        </Text>
      );

    case "ONEOFFDATE":
      return oneOffDate ? (
        <Text numberOfLines={1}>{formatDate(new Date(oneOffDate))}</Text>
      ) : (
        <Text>No fixed date</Text>
      );

    default:
      return <Text>Error, no timing information</Text>;
  }
}
