import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Image,
  ScrollView,
  ActivityIndicator,
  Pressable,
  TextInput,
  Linking,
  Alert,
  FlatList
} from "react-native";
import { View, Text } from "../../constants/Themed";
import { RouteProp, useRoute } from "@react-navigation/native";
import { BoldText } from "../../constants/StyledText";
import Colors from "../../constants/Colors";
import * as ImagePicker from "expo-image-picker";
import MappedCriteria from "../../components/desktop/MappedCriteria";
import useColorScheme from "../../hooks/useColorScheme";
import { RootStackParamList } from "../../navigation/types";
import ImageColors from 'react-native-image-colors'
import { API, graphqlOperation } from "aws-amplify";
import DynamicBadge from "../../components/DynamicBadge";
import { Storage } from "aws-amplify";
import TimelineIcon from "../../../assets/icons/Timeline";
import { Feather } from "@expo/vector-icons";
import DateTypeSelector from '../../functions/DateTypeSelector'


export const getOpportunity = /* GraphQL */ `
  query GetOpportunity($id: ID!) {
    getOpportunity(id: $id) {
      id
      isActive
      name
      description
      logoURL
      availableTo
      location
      colour

      opportunitycategoryID
      OpportunityCategory {
        id
        name
        logoWhiteURI
        logoColourURI
        logoGreyURI
        colour
      }
      engagementLevel
      regularMonday
      regularTuesday
      regularWednesday
      regularThursday
      regularFriday
      regularStartTime
      regularEndTime
      dateType
      oneOffDate
      regularStartDat
      regularEndDate
      multipleStartDate
      multipleEndDate
      totalHours
      userID
      User {
        id
        surname
        firstName
        preferredFirstName
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      AwardedBadges {
        items {
          id
          dateAwarded
          User {
            id
            surname
            firstName
            Enrolments{
              items{
              groupingData3
              }
            }
          }
        }
        nextToken
        startedAt
      }
      Mappings {
        items {
          Criteria {
            id
            name
            logoWhiteURI
            colour
          }
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const updateOpportunity = /* GraphQL */ `
  mutation UpdateOpportunity(
    $input: UpdateOpportunityInput!
    $condition: ModelOpportunityConditionInput
  ) {
    updateOpportunity(input: $input, condition: $condition) {
      id
      isActive
      name
      aaa
      description
      logoURL
      location
      colour
      engagementLevel
      regularMonday
      regularTuesday
      regularWednesday
      regularThursday
      regularFriday
      regularStartTime
      regularEndTime
      dateType
      oneOffDate
      regularStartDat
      regularEndDate
      multipleStartDate
      multipleEndDate
      totalHours
      userID
      opportunitycategoryID
      availableTo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

//////////////////////////////////////////////////////////////
//THIS IS THE NEW MORE DETAILED VERSION CURRENTLY IN TESTING//
//////////////////////////////////////////////////////////////


export default function AdminOpportunityDetailEdit({ navigation }) {
  const route = useRoute<RouteProp<RootStackParamList, "OpportunityDetail">>();
  const [isLoading, setIsLoading] = useState(true);
  const [localImageUri, setLocalImageUri] = useState("");
  const { opportunityID } = route.params; //this isn't type checked and is bad practice. According to the Istagram course by Vadim (video 4.3) we should only be sending the shoutout ID and then querying the database on the detail screen
  const [badgeName, setBadgeName] = useState('');
  const [badgeDescription, setBadgeDescription] = useState('');
  const [downloadedContactPhotoURI, setDownloadedContactPhotoURI] = useState(); //state variable for imageURI
  const [imageToDisplay, setImageToDisplay] = useState('');
  const [dominantColour, setDominantColour] = useState(null)
  const [shouldUploadImage, setShouldUploadImage] = useState(false)
  const [updatedImageURL, setUpdatedImageURL] = useState('')

  const [downloadedImageURI, setDownloadedImageURI] = useState<string | null>(
    null
  ); //state variable for imageURI

  //the cloud query for a single record (that has a filter applied here)
  const [opportunitySingleRecordData, setOpportunitySingleRecordData] =
    useState([]);
  const [downloadedMappings, setDownloadedMappings] = useState([]);
  const [pupilsAwardedThisBadge, setPupilsAwardedThisBadge] = useState([])
  const fetchOpportunity = async () => {
    const response = await API.graphql(
      graphqlOperation(getOpportunity, {
        id: opportunityID,
      })
    );
      console.log('this record:', response)
    setOpportunitySingleRecordData(response.data.getOpportunity); //all data in a variable to be destructured further down
    setDownloadedMappings(response.data.getOpportunity.Mappings.items); //Mappings data in a variable



      if (response.data.getOpportunity.AwardedBadges.items.length >0) {
        console.log('this has been awarded', response.data.getOpportunity.AwardedBadges.items.length, 'times!')
        setPupilsAwardedThisBadge(response.data.getOpportunity.AwardedBadges.items)
      }


    setBadgeName(response.data.getOpportunity.name)
    setBadgeDescription(response.data.getOpportunity.description)
    setDominantColour(response.data.getOpportunity.colour)
    const file = await Storage.get(response.data.getOpportunity.logoURL, {
      level: "public",
    }); //get file using query data

    setDownloadedImageURI(file); //store imageURI in state variable to be used elsewhere on the page
    setImageToDisplay(file)



    const contactPhotoURL = await Storage.get(
      response.data.getOpportunity.User.photoURL,
      {
        level: "public",
      }
    );
    setDownloadedContactPhotoURI(contactPhotoURL);

    setIsLoading(false);
  };
  useEffect(() => {
    fetchOpportunity();
  }, []);



  useEffect(() => {
    const getDominantColour = async (
      // imageToScan:string
    ) => {


      console.warn('about to get colour for:', imageToDisplay)

      const result = await ImageColors.getColors(
        // imageToScan
        imageToDisplay

        , {
          fallback: '#6e8379',
          // cache: true,
          key: 'unique_key',
        })
      console.warn(result)
      setDominantColour(result.vibrant)
    }

    getDominantColour()

  }, [imageToDisplay])



  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });
    console.log(result);
    if (!result.cancelled) {
      setLocalImageUri(result.uri);
      setImageToDisplay(result.uri);
      setShouldUploadImage(true)
      // getDominantColour(imageToDisplay)
    }
  };

  const {
    id,
    name,
    description,
    logoURL,
    engagementLevel,
    Criteria,
    colour,
    multipleStartDate,
    oneOffDate,
    availableTo,
    Mappings,
    multipleEndDate,
    dateType,
    location,
    regularStartTime,
    regularMonday,
    regularTuesday,
    regularWednesday,
    regularThursday,
    regularFriday,
    _version: _version,
    OpportunityCategory: { name: categoryName } = {}, // Destructing the property name in the object opportunityCategory in the object pulled from the query, then sticking it in a new variable called cateogryName. Not sure why the = {} is needed at the end, but it is.
    User: {
      title: contactTitle,
      surname: contactSurname,
      email: contactEmail,
    } = {},
  } = opportunitySingleRecordData;


  const onSubmitPressed = () => {

    console.warn('shoudl upload image:', shouldUploadImage)

    const updateOpportunityinDatabase = async () => {

      console.warn(updatedNameAndDescription)
      await API.graphql({
        query: updateOpportunity,
        variables: { input: updatedNameAndDescription },
      });
    };


    //construct the object for the upload
    const updatedNameAndDescription = {
      id: id,
      name: badgeName,
      description: badgeDescription,
      _version: _version,
    }

    //does the image need updating?

    if (shouldUploadImage) {
      //upload the image


      const uploadImage = async () => {
        const badgeNameWithOutSpaces = badgeName.replace(/\s+/g, '');
        const generatedImageFileName = 'organisation-assets/carrmanor/opportunityLogos/OppIcon' + Date.now() + badgeNameWithOutSpaces + Math.floor(Math.random() * 1000001) + ".png"

        try {
          console.warn('IMAGE IN UPLOADIMAGE:', imageToDisplay)
          const response = await fetch(imageToDisplay)
          const blob = await response.blob()
          const s3ResponseKey = await Storage.put(generatedImageFileName, blob)
          const newKey = s3ResponseKey.key

          console.warn('s3response = ', s3ResponseKey.key)
          console.warn(id, dominantColour, _version, newKey)
          const stuffToUpload = {
            id: id,
            logoURL: newKey,
            name: badgeName,
            description: badgeDescription,
            colour: dominantColour,
            _version: _version,
          }
          // setUpdatedImageURL(s3ResponseKey.key)
          // updateOpportunityImageinDatabase()
          await API.graphql({
            query: updateOpportunity,
            variables: {
              input: stuffToUpload
            },
          });
          // console.warn(didItWork)
          return
        } catch (e) {
          Alert.alert('error uploading file')
        }


      }

      if (imageToDisplay) {
        // uploadImage(imageToDisplay)
        console.warn('imagetodisplay is working')
        //   const promise = uploadImage();
        // promise.then((newKey) =>


        // setUpdatedImageURL(newKey)

        uploadImage();

        // );
        console.warn('updateed url = ', updatedImageURL)

        //get the image's new URL
        //write the url to the datebase
      }
    }

    else {
      updateOpportunityinDatabase() // update the name and description
    }

    navigation.navigate("AdminBadgeConfirmation");//go to the success screen

  }

  const colorScheme = useColorScheme();

  const emailString = `mailto:${contactEmail}?subject=${name}&body=Hi ${contactTitle} ${contactSurname}. I'm interested in signing up to ${name}. Please can you reply to this email with more information? Thanks!`;

  return (
    <ScrollView>
      <View style={styles.root}>
        {isLoading ? (
          <View
            style={{
              flex: 1,
              backgroundColor: "transparent",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ActivityIndicator size="large" color={Colors.palette.logoGreen} />
          </View>
        ) : (
          <>
            <View style={styles.headlineDetailsContainer}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 20,
                }}
              >
                <View style={styles.metaDataContainer}>
                  <View style={styles.nameContainer}>
                    {/* Badge Name */}

                    <BoldText
                      style={[
                        styles.badgeName,
                        // { color: Colors[colorScheme].text },
                        { color: dominantColour },
                      ]}
                    >
                      {badgeName}
                    </BoldText>
                  </View>

                  <View style={[styles.metaDataPairContainer, { alignItems: 'center' }]}>
                    <Text
                      style={[
                        styles.title,
                        { color: Colors[colorScheme].textLessEmphasised },
                      ]}
                    >
                      Title:
                    </Text>
                    <TextInput
                      style={[
                        styles.textInputBox,
                        {
                          outlineStyle: "none",
                          color: Colors[colorScheme].textLessEmphasised,
                          backgroundColor: Colors[colorScheme].almostBackground,
                          borderColor: Colors[colorScheme].edging,
                        },
                      ]}
                      onChangeText={setBadgeName}
                      value={badgeName}
                      maxLength={70}
                    />
                  </View>

                  {availableTo ? (
                    <View style={styles.metaDataPairContainer}>
                      <Text
                        style={[
                          styles.title,
                          { color: Colors[colorScheme].textLessEmphasised },
                        ]}
                      >
                        Available to:
                      </Text>
                      <Text
                        style={[
                          styles.metaData,
                          { color: Colors[colorScheme].text },
                        ]}
                      >
                        {availableTo}
                      </Text>
                    </View>
                  ) : null}
                  <View style={styles.metaDataPairContainer}>
                    <Text
                      style={[
                        styles.title,
                        { color: Colors[colorScheme].textLessEmphasised },
                      ]}
                    >
                      Category:
                    </Text>
                    <Text
                      style={[
                        styles.metaData,
                        { color: Colors[colorScheme].text },
                      ]}
                    >
                      {categoryName}
                    </Text>
                  </View>

                  {location ? (
                    <View style={styles.metaDataPairContainer}>
                      <Text
                        style={[
                          styles.title,
                          { color: Colors[colorScheme].textLessEmphasised },
                        ]}
                      >
                        Location:
                      </Text>
                      <Text
                        style={[
                          styles.metaData,
                          { color: Colors[colorScheme].text },
                        ]}
                      >
                        {location}
                      </Text>
                    </View>
                  ) : null}

                  <View style={styles.metaDataPairContainer}>
                    <Text
                      style={[
                        styles.title,
                        { color: Colors[colorScheme].textLessEmphasised },
                      ]}
                    >
                      Date:
                    </Text>
                    <Text
                      style={[
                        styles.metaData,
                        { color: Colors[colorScheme].text },
                      ]}
                    >
                      <DateTypeSelector opportunity={opportunitySingleRecordData} />
                    </Text>
                  </View>
                  <View
                    style={{
                      justifyContent: "space-between",
                      flexDirection: "row",
                      flex: 1,
                      backgroundColor: "transparent",
                      marginVertical: 10,
                      // marginHorizontal: 20,
                    }}
                  >
                    <View style={styles.metaDataPairContainer}>
                      <Text
                        style={[
                          styles.title,
                          { color: Colors[colorScheme].textLessEmphasised },
                        ]}
                      >
                        Contact:
                      </Text>

                      <View>
                        <Text
                          style={[
                            styles.metaData,
                            { color: Colors[colorScheme].textLessEmphasised },
                          ]}
                        >
                          {contactTitle} {contactSurname}
                        </Text>

                        <Pressable onPress={() => Linking.openURL(emailString)}>
                          <Text style={styles.hyperlink}>{contactEmail}</Text>
                        </Pressable>
                      </View>
                      <View
                        style={{ width: 50, height: 50, marginHorizontal: 20 }}
                      >
                        <TimelineIcon color={"#ccc"} />
                        <Image
                          style={[
                            styles.contactPhoto,
                            { borderColor: Colors[colorScheme].edging },
                          ]}
                          source={{ uri: downloadedContactPhotoURI }}
                        />
                      </View>
                    </View>
                  </View>
                </View>

                {/* badge image */}
                <View style={{ backgroundColor: "transparent" }}>
                  <View
                    style={[
                      styles.textInputBox,
                      {
                        outlineStyle: "none",
                        color: Colors[colorScheme].textLessEmphasised,
                        backgroundColor: Colors[colorScheme].background,
                        borderColor: Colors[colorScheme].edging,
                        // flexDirection: "row",
                      },
                    ]}
                  >
                    <View style={styles.badgeIcon}>
                      {/* Badge logo */}
                      <DynamicBadge
                        size={200}
                        edgeColour={dominantColour}
                        engagementLevel={3}
                        logo={imageToDisplay}
                      />
                    </View>

                    <Pressable
                      onPress={pickImage}
                      style={{
                        // alignItems: "center",
                        flexDirection: "row",
                        // width: 75,
                        // height: 85,
                        // width:'90%',
                        flex: 1,
                        backgroundColor: Colors[colorScheme].almostBackground,
                        padding: 10,
                        margin: 10,
                        borderRadius: 10,
                        borderColor: Colors[colorScheme].edging,
                        borderWidth: 1,
                      }}
                    >
                      <Feather
                        name="image"
                        size={24}
                        color={Colors[colorScheme].text}
                      />
                      <View
                        style={{
                          marginHorizontal: 10,
                          backgroundColor: "transparent",
                          justifyContent: "center",
                        }}
                      >
                        <Text
                          style={{
                            // textAlign: "center",
                            color: Colors[colorScheme].text,
                            fontSize: 16,
                          }}
                        >
                          Browse images
                        </Text>
                      </View>
                    </Pressable>
                  </View>
                </View>
              </View>

              <View style={styles.descriptionContainer}>
                <TextInput
                  style={[
                    styles.textInputBox,
                    {
                      width: '98%',

                      outlineStyle: "none",
                      color: Colors[colorScheme].textLessEmphasised,
                      backgroundColor: Colors[colorScheme].almostBackground,
                      borderColor: Colors[colorScheme].edging,
                    },
                  ]}
                  onChangeText={setBadgeDescription}
                  value={badgeDescription}
                  multiline={true}
                  numberOfLines={5}
                // maxLength={70}
                />
              </View>
<View style={{backgroundColor:'transparent', flexDirection:'row', flex:1}}>
<View style={{
borderWidth: 1,
margin: 10,
padding: 10,
borderRadius: 8,
// fontSize: 16,
// fontFamily: "avenir-next",
width: 400,
// outlineStyle: "none",
// color: Colors[colorScheme].textLessEmphasised,
backgroundColor: Colors[colorScheme].background,
borderColor: Colors[colorScheme].edging,


}}>
                    <FlatList 
                      data={pupilsAwardedThisBadge}
                      renderItem={({item}) => (
                        <View><Text
                        
                       style={{ color: Colors[colorScheme].text,
                          fontSize: 16,
                          paddingVertical: 5,
                          marginLeft: 10,
                        
                        }}
                        >{item.User.firstName} {item.User.surname}
                        
                        {item.User.Enrolments.items.length>0?

' (' + 
item.User.Enrolments.items[0].groupingData3
+ ')'

:null }
                        
                        
                        
                        </Text></View>
                        )}
                    
                    
                    
                    />

</View>

<View style={{
borderWidth: 1,
margin: 10,
padding: 10,
borderRadius: 8,
// fontSize: 16,
// fontFamily: "avenir-next",
width: 200,
// outlineStyle: "none",
// color: Colors[colorScheme].textLessEmphasised,
backgroundColor: Colors[colorScheme].background,
borderColor: Colors[colorScheme].edging,
}}>
  
</View>


</View>
              <View style={{ alignItems: 'center' }}>
                <Pressable
                  onPress={onSubmitPressed}
                  style={{
                    alignItems: "center",
                    width: 120,
                    backgroundColor: Colors.palette.logoGreen,
                    padding: 10,
                    margin: 20,
                    borderRadius: 10,
                  }}
                >
                  <Feather
                    name="upload"
                    size={48}
                    color={Colors[colorScheme].background}
                  />
                  <BoldText
                    style={{
                      textAlign: "center",
                      color: Colors[colorScheme].background,
                    }}
                  >
                    Update details
                  </BoldText>
                </Pressable>
              </View>
            </View>

            <View
              style={[
                styles.descriptionContainer,
                // { backgroundColor: Colors[colorScheme].almostButtons },
              ]}
            >
              <BoldText
                style={[
                  styles.title,
                  { color: Colors[colorScheme].text, marginBottom: 10 },
                ]}
              >
                Character attributes you will develop:
              </BoldText>
            </View>

            <ScrollView
              horizontal
              style={[
                styles.mappingRowContainer,
                { backgroundColor: Colors[colorScheme].almostBackground },
              ]}
            >
              {downloadedMappings.map((item) => (
                <MappedCriteria key={item.id} mapping={item} />
              ))}
            </ScrollView>
          </>
        )}
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    // alignItems: "center",
    backgroundColor: "transparent",
    // backgroundColor: 'red',
    width: 800,
    alignSelf: "center",
  },
  descriptionContainer: {
    marginTop: 20,
    backgroundColor: "transparent",
  },
  mappingRowContainer: {
    flexDirection: "row",
  },
  nameContainer: {
    marginBottom: 20,
    flex: 1,
    maxWidth: 500,
    // width: '50%'
  },
  contactPhoto: {
    width: 50,
    height: 50,
    borderRadius: 25,
    borderWidth: 1,
    position: "absolute",
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    marginRight: 10,
  },
  metaDataPairContainer: {
    flexDirection: "row",
    marginVertical: 5,
  },
  badgeName: {
    fontSize: 30,
    maxWidth: 380,
  },
  metaData: {
    fontSize: 16,
    padding: 2,
  },
  hyperlink: {
    color: "blue",
    textDecorationLine: "underline",
    // marginLeft: 10,
  },
  metaDataContainer: {
    backgroundColor: "transparent",
    justifyContent: "center",
  },
  badgeIcon: {
    alignSelf: "center",
    height: 200,
    width: 200,
    marginVertical: 20,
  },
  headlineDetailsContainer: {
    borderRadius: 10,
    padding: 30,
  },
  textInputBox: {
    borderWidth: 1,
    margin: 10,
    padding: 10,
    borderRadius: 8,
    fontSize: 16,
    fontFamily: "avenir-next",
    width: 280,
  },
});
