import React from "react";
import { Pressable, StyleSheet } from "react-native";
import RecognitionLogoIndentIcon from "../../../assets/icons/RecognitionLogoIndent";
import { Text, View } from "../../constants/Themed";
import useColorScheme from '../../hooks/useColorScheme';
import Colors from '../../constants/Colors';
import { useNavigation } from "@react-navigation/native";


const HeaderLogo = () => {
    const colorScheme = useColorScheme();
    const navigation = useNavigation();




  return (
    
    <Pressable onPress={() =>
      navigation.navigate('ShoutOutsRoot',
          )
    }>
    <View style={styles.container}>
      <View style={{ width: 20, height: 20, backgroundColor:'transparent' }}>
        <RecognitionLogoIndentIcon />
      </View>
      <Text style={[styles.beautifulText, {color: Colors[colorScheme].text}]}>
        RECOGNITION
      </Text>
    </View></Pressable>
  );
};

export default HeaderLogo;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: 'transparent',
  },

  beautifulText: {
    marginTop: 2,
    fontSize: 20,
    marginLeft: 10,
    letterSpacing: 4,
  },
});
