import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as React from "react";
import { ColorSchemeName } from "react-native";
import Colors from "../constants/Colors";
import { useContext, useState } from "react";
import useColorScheme from "../hooks/useColorScheme";
import NotFoundScreen from "../screens/NotFoundScreen";
import { createDrawerNavigator } from "@react-navigation/drawer";
import {
  AdminStackParamList,
  DrawerRootTabParamList,
  OpportunitiesStackParamList,
  RootStackParamList,
  RootTabScreenProps,
  SettingsStackParamList,
  ShoutOutsStackParamList,
  TimelinesStackParamList,
} from "./types";
import LinkingConfiguration from "./LinkingConfiguration";
import HeaderLogo from "../components/desktop/HeaderLogo";
import DrawerMenuItemShoutOuts from "../components/desktop/DrawerMenuItemShoutOuts";
import DrawerMenuItemOpportunities from "../components/desktop/DrawerMenuItemOpportunities";
import DrawerMenuItemTimelines from "../components/desktop/DrawerMenuItemTimeslines";
import DrawerMenuItemSettings from "../components/desktop/DrawerMenuItemSettings";
import OpportunitiesRoot from "../screens/desktop/OpportunitiesRoot";
import ScreenTitleShoutOuts from "../components/desktop/ScreenTitleShoutOuts";
import ScreenTitleOpportunities from "../components/desktop/ScreenTitleOpportunities";
import ShoutOutsRoot from "../screens/desktop/ShoutOutsRoot";
import ScreenTitleTimelines from "../components/desktop/ScreenTitleTimelines";
import ScreenTitleSettings from "../components/desktop/ScreenTitleSettings";
import SettingsRoot from "../screens/desktop/SettingsRoot";
import TimelinesRoot from "../screens/desktop/TimelinesRoot";
import HeaderUser from "../components/desktop/HeaderUser";
import ShoutOutsDetail from "../screens/desktop/ShoutOutsDetail";
import OpportunitiesList from "../screens/desktop/OpportunitiesList";
import OpportunityDetail from "../screens/desktop/OpportunitiesDetail";
import OpportunityDetailEdit from "../screens/desktop/OpportunitiesDetailEdit";
import TimelinesDetail from "../screens/desktop/TimelinesDetail";
import CriteriaDetail from "../screens/desktop/CriteriaDetail";
import DrawerMenuItemHome from "../components/desktop/DrawerMenuItemHome";
import TimelinesRootMobileV2 from "../screens/mobile/TimelinesRootV2";
import ScreenTitleHousePoints from "../components/desktop/ScreenTitleHousePoints";
import { UserContext } from "../components/Context";
import DrawerMenuItemAdmin from "../components/desktop/DrawerMenuItemAdmin";
import AdminRoot from "../screens/desktop/AdminRoot";
import NewOpportunityForm from "../components/NewOpportunityForm";
import BadgesList from "../screens/desktop/BadgesList";
import AdminBadgeConfirmation from "../screens/desktop/AdminBadgeConfirmation";
import AdminHouseCompetitions from "../screens/desktop/AdminHouseCompetitions";
import AdminAddShoutOut from "../screens/desktop/AdminAddShoutOut";
import AdminBadgesListByDate from "../screens/desktop/AdminBadgesListByDate";
import AdminUserManagement from "../screens/desktop/AdminUserManagement";
import AdminUploadConfirmation from "../screens/desktop/AdminUploadConfirmation";
import AdminMomentList from "../screens/desktop/AdminMomentList";
import AdminOpportunityManagement from "../screens/desktop/AdminOpportunityManagement";
import AdminHousePoints from "../screens/desktop/AdminHousePoints";
import AdminOpportunityDetailEdit from "../screens/desktop/AdminOpportunitiesDetailEdit";

const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: Colors.light.almostBackground,
  },
};

export default function DesktopNavigation({
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
}) {
  const userDetailsFromUserContext = useContext(UserContext);
  // console.warn("full user details from context:", userDetailsFromUserContext);
  // console.warn(userDetailsFromUserContext.type);

  const [isAdmin, setIsAdmin] = useState(false);

  return (
    <NavigationContainer
      independent={true}
      linking={LinkingConfiguration}
      theme={colorScheme === "dark" ? DarkTheme : MyTheme}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}

/* Stack (Native Stack) gives the screen title above the drawer
      Drawer gives the drawer menu
        StackNavigator allows for individual screens to have their own headers (eg for back button)
          TabOneScreen the actual screen */

const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Root"
        component={DrawerNavigator}
        options={{
          headerRight: () => <HeaderUser />,
          headerShown: true,
          headerShadowVisible: true,
          headerStyle: {
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
            shadowColor: "grey",
            shadowOffset: {
              width: 0,
              height: 2,
            },
          },
          headerTitle: () => <HeaderLogo />,
        }} // this gives the overall screen header
      />
      {/* <Stack.Screen
        name="NotFound"
        component={RootNavigator}
        options={{ title: "Oops!", headerShown: false, }}
      /> */}
      {/* <Stack.Screen
      name="OpportunityDetail"
      component={OpportunityDetail}
      /> */}
      <Stack.Group
        screenOptions={{
          presentation: "modal",
          title: "RECOGNITION",
          headerTitle: "",
        }}
      >
        <Stack.Screen name="Modal" component={CriteriaDetail} />
        <Stack.Screen name="TimelinesV2" component={TimelinesRootMobileV2} />
      </Stack.Group>
    </Stack.Navigator>
  );
}

const ShoutOutsStack = createNativeStackNavigator<ShoutOutsStackParamList>();
function ShoutOutsStackNavigator() {
  const colorScheme = useColorScheme();
  return (
    <ShoutOutsStack.Navigator screenOptions={{ headerShown: true }}>
      <ShoutOutsStack.Screen
        name="ShoutOutsRoot"
        component={ShoutOutsRoot}
        options={{
          title: "RECOGNITION: ShoutOuts",
          headerShadowVisible: false,
          headerStyle: {
            backgroundColor: Colors[colorScheme].almostBackground,
          },

          //the line below needs re-adding when the temporary home screen is returned to a shoutouts screen
          // headerLeft: ()=> <ScreenTitleShoutOuts />,
          // headerLeft: () => <ScreenTitleHousePoints />,
          headerTitle: "",
        }}
      />
      <ShoutOutsStack.Screen
        name="ShoutOutsDetail"
        component={ShoutOutsDetail}
        options={{
          // headerTitle: ()=> <ScreenTitleShoutOuts />,
          headerTransparent: true,
          title: "RECOGNITION: ShoutOuts",
          headerTitle: "",
          headerShadowVisible: false,
          headerTintColor: Colors[colorScheme].buttons, //AJL back arrow colour
        }}
      />

      <ShoutOutsStack.Screen
        name="TimelinesDetail"
        component={TimelinesDetail}
        options={{
          headerShown: true, //AJL: this is how you show a back button for some of the screens!
          title: "RECOGNITION: ShoutOuts",
          headerTintColor: Colors[colorScheme].buttons,
          headerShadowVisible: false,
          headerBackTitle: "",
          // title:'',
          headerTitle: "",
          headerStyle: {
            backgroundColor: Colors[colorScheme].almostBackground,
          },
        }}
      />

      <ShoutOutsStack.Screen
        name="OpportunityDetail"
        component={OpportunityDetail}
        options={{
          headerShown: true, //AJL: this is how you show a back button for some of the screens!
          title: "RECOGNITION: ShoutOuts",
          headerTintColor: Colors[colorScheme].buttons,
          headerShadowVisible: false,
          headerBackTitle: "",
          headerTitle: "",
        }}
      />
    </ShoutOutsStack.Navigator>
  );
}

const OpportunitiesStack =
  createNativeStackNavigator<OpportunitiesStackParamList>();
function OppotuntitiesStackNavigator() {
  const colorScheme = useColorScheme();
  return (
    <OpportunitiesStack.Navigator screenOptions={{ headerShown: true }}>
      <OpportunitiesStack.Screen
        name="OpportunitiesRoot"
        component={OpportunitiesRoot}
        options={{
          title: "RECOGNITION: Opportunities",
          headerShadowVisible: false,
          headerStyle: {
            backgroundColor: Colors[colorScheme].almostBackground,
          },
          headerLeft: () => <ScreenTitleOpportunities />,
          headerTitle: "",
        }}
      />
      <OpportunitiesStack.Screen
        name="OpportunitiesList"
        component={OpportunitiesList}
        options={{
          headerShadowVisible: false,
          title: "RECOGNITION: Opportunities",
          headerTitle: "",
          headerTintColor: Colors[colorScheme].buttons,
          headerStyle: {
            backgroundColor: Colors[colorScheme].almostBackground,
          },
        }}
      />
      <OpportunitiesStack.Screen
        name="OpportunityDetail"
        component={OpportunityDetail}
        options={{
          headerShadowVisible: false,
          title: "RECOGNITION: Opportunities",
          headerTitle: "",
          headerTintColor: Colors[colorScheme].buttons,
          headerStyle: {
            backgroundColor: Colors[colorScheme].almostBackground,
          },
        }}
      />
    </OpportunitiesStack.Navigator>
  );
}

const TimelinesStack = createNativeStackNavigator<TimelinesStackParamList>();
function TimelinesStackNavigator() {
  const colorScheme = useColorScheme();
  return (
    <TimelinesStack.Navigator screenOptions={{ headerShown: true }}>
      <TimelinesStack.Screen
        name="TimelinesRoot"
        component={TimelinesRoot}
        options={{
          headerShadowVisible: false,
          title: "RECOGNITION: Timelines",
          headerTitle: "",
          headerStyle: {
            backgroundColor: Colors[colorScheme].almostBackground,
          },
          headerLeft: () => <ScreenTitleTimelines />,
        }}
      />
      <TimelinesStack.Screen
        name="TimelinesDetail"
        component={TimelinesDetail}
        options={{
          headerShown: true, //AJL: this is how you show a back button for some of the screens!
          headerShadowVisible: false,
          title: "RECOGNITION: Timelines",
          headerTitle: "",
          headerTintColor: Colors[colorScheme].buttons,
          headerStyle: {
            backgroundColor: Colors[colorScheme].almostBackground,
          },
        }}
      />
      <TimelinesStack.Screen
        name="OpportunityDetail"
        component={OpportunityDetail}
        options={{
          headerShadowVisible: false,
          title: "RECOGNITION: Timelines",
          headerTitle: "",
          headerTintColor: Colors[colorScheme].buttons,
          headerStyle: {
            backgroundColor: Colors[colorScheme].almostBackground,
          },
        }}
      />
    </TimelinesStack.Navigator>
  );
}

const SettingsStack = createNativeStackNavigator<SettingsStackParamList>();
function SettingsStackNavigator() {
  const colorScheme = useColorScheme();
  return (
    <SettingsStack.Navigator screenOptions={{ headerShown: true }}>
      <SettingsStack.Screen
        name="SettingsRoot"
        component={SettingsRoot}
        options={{
          title: "RECOGNITION: Settings",
          headerStyle: {
            backgroundColor: Colors[colorScheme].almostBackground,
          },
          headerTitle: "",
          headerShadowVisible: false,
          headerLeft: () => <ScreenTitleSettings />,
        }}
      />
    </SettingsStack.Navigator>
  );
}

const AdminStack = createNativeStackNavigator<AdminStackParamList>();
function AdminStackNavigator() {
  const colorScheme = useColorScheme();
  return (
    <AdminStack.Navigator screenOptions={{ headerShown: true }}>
      <AdminStack.Screen
        name="AdminRoot"
        component={AdminRoot}
        options={{
          title: "RECOGNITION: Administration",
          headerStyle: {
            backgroundColor: Colors[colorScheme].almostBackground,
          },
          headerTitle: "",
          headerShadowVisible: false,
          // headerLeft: () => <ScreenTitleSettings />,
        }}
      />
      <AdminStack.Screen
        name="BadgesList"
        component={BadgesList}
        options={{
          title: "RECOGNITION: Administration",
          headerStyle: {
            backgroundColor: Colors[colorScheme].almostBackground,
          },
          headerTitle: "",
          headerShadowVisible: false,
          // headerLeft: () => <ScreenTitleSettings />,
        }}
      />
      <AdminStack.Screen
        name="AdminBadgesListByDate"
        component={AdminBadgesListByDate}
        options={{
          title: "RECOGNITION: Administration",
          headerStyle: {
            backgroundColor: Colors[colorScheme].almostBackground,
          },
          headerTitle: "",
          headerShadowVisible: false,
          // headerLeft: () => <ScreenTitleSettings />,
        }}
      />
      <AdminStack.Screen
        name="AddNewBadge"
        component={NewOpportunityForm}
        options={{
          title: "RECOGNITION: Administration",
          headerStyle: {
            backgroundColor: Colors[colorScheme].almostBackground,
          },
          headerTitle: "",
          headerShadowVisible: false,
          // headerLeft: () => <ScreenTitleSettings />,
        }}
      />
      <AdminStack.Screen
        name="OpportunityDetailEdit"
        component={OpportunityDetailEdit}
        options={{
          headerShadowVisible: false,
          title: "RECOGNITION: Administration",
          headerTitle: "",
          headerTintColor: Colors[colorScheme].buttons,
          headerStyle: {
            backgroundColor: Colors[colorScheme].almostBackground,
          },
        }}
      />

<AdminStack.Screen
        name="AdminOpportunityDetailEdit"
        component={AdminOpportunityDetailEdit}
        options={{
          headerShadowVisible: false,
          title: "RECOGNITION: Administration",
          headerTitle: "✋ TEST VERSION: DO NOT USE!",
          headerTintColor: Colors[colorScheme].buttons,
          headerStyle: {
            backgroundColor: Colors[colorScheme].almostBackground,
          },
        }}
      />

      <AdminStack.Screen
        name="AdminBadgeConfirmation"
        component={AdminBadgeConfirmation}
        options={{
          headerShadowVisible: false,
          title: "RECOGNITION: Administration",
          headerTitle: "",
          headerTintColor: Colors[colorScheme].buttons,
          headerStyle: {
            backgroundColor: Colors[colorScheme].almostBackground,
          },
          headerShown: false
        }}
      />
      <AdminStack.Screen
        name="AdminUploadConfirmation"
        component={AdminUploadConfirmation}
        options={{
          headerShadowVisible: false,
          title: "RECOGNITION: Administration",
          headerTitle: "",
          headerTintColor: Colors[colorScheme].buttons,
          headerStyle: {
            backgroundColor: Colors[colorScheme].almostBackground,
          },
          headerShown: false
        }}
      />
         <AdminStack.Screen
        name="AdminMomentList"
        component={AdminMomentList}
        options={{
          headerShadowVisible: false,
          title: "RECOGNITION: Administration",
          headerTitle: "",
          headerTintColor: Colors[colorScheme].buttons,
          headerStyle: {
            backgroundColor: Colors[colorScheme].almostBackground,
          },
          headerShown: true
        }}
      />
      <AdminStack.Screen
        name="AdminHouseCompetitions"
        component={AdminHouseCompetitions}
        options={{
          headerShadowVisible: false,
          title: "RECOGNITION: Administration",
          headerTitle: "",
          headerTintColor: Colors[colorScheme].buttons,
          headerStyle: {
            backgroundColor: Colors[colorScheme].almostBackground,
          },
        }}
      />
       <AdminStack.Screen
        name="AdminAddShoutOut"
        component={AdminAddShoutOut}
        options={{
          headerShadowVisible: false,
          title: "RECOGNITION: Administration",
          headerTitle: "",
          headerTintColor: Colors[colorScheme].buttons,
          headerStyle: {
            backgroundColor: Colors[colorScheme].almostBackground,
          },
        }}
      />
      <AdminStack.Screen
        name="AdminUserManagement"
        component={AdminUserManagement}
        options={{
          headerShadowVisible: false,
          title: "RECOGNITION: Administration",
          headerTitle: "",
          headerTintColor: Colors[colorScheme].buttons,
          headerStyle: {
            backgroundColor: Colors[colorScheme].almostBackground,
          },
        }}
      />
      <AdminStack.Screen
        name="AdminOpportunityManagement"
        component={AdminOpportunityManagement}
        options={{
          headerShadowVisible: false,
          title: "RECOGNITION: Administration",
          headerTitle: "",
          headerTintColor: Colors[colorScheme].buttons,
          headerStyle: {
            backgroundColor: Colors[colorScheme].almostBackground,
          },
        }}
      />
      <AdminStack.Screen
        name="ShoutOutsDetail"
        component={ShoutOutsDetail}
        options={{
          headerShadowVisible: false,
          title: "RECOGNITION: Administration",
          headerTitle: "",
          headerTintColor: Colors[colorScheme].buttons,
          headerStyle: {
            backgroundColor: Colors[colorScheme].almostBackground,
          },
        }}
      />
<AdminStack.Screen
        name="AdminHousePoints"
        component={AdminHousePoints}
        options={{
          headerShadowVisible: false,
          title: "RECOGNITION: Administration",
          headerTitle: "",
          headerTintColor: Colors[colorScheme].buttons,
          headerStyle: {
            backgroundColor: Colors[colorScheme].almostBackground,
          },
        }}
      />





    </AdminStack.Navigator>
  );
}


const Drawer = createDrawerNavigator<DrawerRootTabParamList>();

function DrawerNavigator() {
  const colorScheme = useColorScheme();
  const userDetailsFromUserContext = useContext(UserContext);
  return (
    <Drawer.Navigator
      initialRouteName="ShoutOuts"
      screenOptions={{
        headerShadowVisible: false,
        drawerStyle: {
          backgroundColor: Colors[colorScheme].background,
          width: 200,
        },
        drawerType: "permanent",
        headerShown: false,
        drawerActiveBackgroundColor: Colors[colorScheme].almostButtons,
        drawerActiveTintColor: Colors.palette.menuActive, //AJL: icon colour
        drawerInactiveTintColor: Colors.palette.menuInactive,
      }}
    >
      <Drawer.Screen
        name="ShoutOuts"
        component={ShoutOutsStackNavigator}
        options={({ navigation }: RootTabScreenProps<"ShoutOuts">) => ({
          drawerLabel: (color: any) => <DrawerMenuItemHome color={color} />,
        })}
      />
      <Drawer.Screen
        name="Opportunities"
        component={OppotuntitiesStackNavigator}
        options={{
          drawerLabel: (color: any) => (
            <DrawerMenuItemOpportunities color={color} />
          ),
        }}
      />
      <Drawer.Screen
        name="Timelines"
        component={TimelinesStackNavigator}
        options={{
          drawerLabel: (color: any) => (
            <DrawerMenuItemTimelines color={color} />
          ),
        }}
      />
      <Drawer.Screen
        name="Settings"
        component={SettingsStackNavigator}
        options={{
          drawerLabel: (color: any) => <DrawerMenuItemSettings color={color} />,
        }}
      />
      {userDetailsFromUserContext.type === "ADMIN" ? (
        <Drawer.Screen
          name="Admin"
          component={AdminStackNavigator}
          options={{
            drawerLabel: (color: any) => <DrawerMenuItemAdmin color={color} />,
          }}
        />
      ) : null}
    </Drawer.Navigator>
  );
}
