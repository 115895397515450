import React from "react";
import { StyleSheet, useWindowDimensions } from "react-native";
import Colors from "../../constants/Colors";
import { View } from "../../constants/Themed";
import OpportunityIcon from "../../../assets/icons/Opportunity";
import { BoldText } from "../../constants/StyledText";
import useColorScheme from "../../hooks/useColorScheme";

const ScreenTitleOpportunities = () => {
  const {width} = useWindowDimensions();
  const colorScheme = useColorScheme();
  return (
    <View style={[styles.root, {left: (width-1200)/2}]}>
      <View style={styles.titleContainer}>
        <View style={styles.iconContainer}>
          <OpportunityIcon color={Colors.palette.logoGreen} />
        </View>
        <BoldText style={[styles.title, { color: Colors[colorScheme].text }]}>
          Opportunities
        </BoldText>
      </View>
    </View>
  );
};

export default ScreenTitleOpportunities;

const styles = StyleSheet.create({
  root: {
    backgroundColor: "transparent",
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "transparent",
  },
  title: {
    fontSize: 22,
  },
  iconContainer: {
    width: 50,
    height: 50,
    backgroundColor: "transparent",
  },
});
