import React from "react";
import Svg, { Path, G } from "react-native-svg";

const LCAEdgeLevel2 = (edgeColour: any) => {
  return (
    <Svg id="Layer_1" viewBox="0 0 590 590">
      <G>
        {/* badge background */}
        <Path
          d="M295,590c-31.72,0-62.91-5-92.71-14.87-3.2-1.06-6.38-2.17-9.44-3.3-56-20.67-103.95-57.45-138.64-106.37C18.74,415.46,0,356.51,0,295,0,216.2,30.69,142.12,86.4,86.4,142.12,30.69,216.2,0,295,0s152.88,30.69,208.6,86.4c55.72,55.72,86.4,129.8,86.4,208.6,0,61.61-18.8,120.64-54.37,170.68-34.8,48.97-82.87,85.74-139.02,106.34-3.15,1.16-6.32,2.27-9.44,3.29-29.66,9.75-60.67,14.69-92.17,14.69Z"
          fill={"#fff"}
        />

{/* outer edge */}
        <Path
          d="M295,10c38.48,0,75.8,7.53,110.93,22.39,33.94,14.35,64.42,34.91,90.59,61.08,26.18,26.18,46.73,56.66,61.08,90.59,14.86,35.13,22.39,72.45,22.39,110.93,0,59.53-18.16,116.55-52.52,164.9-16.55,23.29-36.45,43.93-59.12,61.33-22.9,17.58-48.2,31.51-75.2,41.41-3.05,1.12-6.11,2.19-9.1,3.17-28.65,9.42-58.61,14.19-89.05,14.19s-60.78-4.83-89.57-14.36c-3.08-1.02-6.15-2.09-9.12-3.19-26.92-9.94-52.15-23.89-74.99-41.47-22.62-17.41-42.45-38.04-58.96-61.31-34.26-48.3-52.37-105.25-52.37-164.68,0-38.48,7.53-75.8,22.39-110.93,14.35-33.94,34.91-64.42,61.08-90.59,26.18-26.18,56.66-46.73,90.59-61.08,35.13-14.86,72.45-22.39,110.93-22.39m0-10C132.08,0,0,132.08,0,295c0,127.01,80.28,235.28,192.85,276.83,3.12,1.15,6.27,2.25,9.44,3.3,29.15,9.65,60.32,14.87,92.71,14.87s63.17-5.16,92.17-14.69c3.17-1.04,6.32-2.14,9.44-3.29,112.86-41.4,193.39-149.81,193.39-277.02C590,132.08,457.92,0,295,0h0Z"
          fill={edgeColour.edgeColour}
        />
      </G>

{/* LCA notch */}
      <G>
        <Path
          d="M396.61,572.02c-3.12,1.15-6.27,2.25-9.44,3.29-29,9.53-59.98,14.69-92.17,14.69s-63.56-5.22-92.71-14.87c-3.17-1.05-6.32-2.15-9.44-3.3,.23-.66,.54-1.31,.91-1.92l10.13-16.56,23.34-38.18c7.13-12.27,20.36-19.89,34.57-19.89h66.47c14.22,0,27.47,7.64,34.59,19.93l22.94,38.34,9.95,16.62c.35,.59,.64,1.21,.86,1.85Z"
          fill={"#fff"}
        />
        <Path
          d="M377.22,558.69l-8.93-14.92-14.05-23.49c-5.35-9.28-15.26-15-25.97-15h-66.47c-10.72,0-20.63,5.72-25.98,15l-14.32,23.43-9.08,14.86-10.13,16.56c29.15,9.65,60.32,14.87,92.71,14.87s63.17-5.16,92.17-14.69l-9.95-16.62Z"
          fill={"#013334"}
        />
        <Path
          d="M328.21,547.84l-3.76-12.38s-.02-.05-.03-.07c-2.56-10.12-11.56-17.09-21.98-17.09-.13,0-.27,0-.4,.01-1.49,.02-17.47-.04-19.28,0-6.09,.1-11.64,2.67-15.67,6.75-4.04,4.07-6.56,9.63-6.61,15.73-.02,2.69,.42,5.32,1.32,7.82,1.63,4.58,2.62,9.13,2.92,13.52l.49,7.12v2.31h4.76l-.15-4.78-.34-4.98c-.33-4.82-1.4-9.8-3.19-14.8-.69-1.93-1.04-3.96-1.04-6.04v-.13c.07-8.01,5.55-14.87,12.94-17.04-4.83,4.14-7.92,10.25-7.97,17-.03,2.69,.41,5.32,1.31,7.82,1.64,4.58,2.62,9.13,2.92,13.52l.03,.37,.29,4.28,.17,2.47v2.31h4.72l-.1-4.78-.35-4.98c-.33-4.82-1.4-9.8-3.18-14.8-.69-1.92-1.04-3.95-1.04-6.01v-.16c.07-7.9,5.39-14.68,12.62-16.94-4.75,4.13-7.8,10.2-7.85,16.9-.02,2.69,.42,5.32,1.31,7.82,1.64,4.58,2.62,9.13,2.92,13.52l.12,1.66,.21,2.99,.17,2.47v2.31h28.23c6.84,0,12.4-5.57,12.4-12.4v-4.99l1.23-.87c1.73-1.24,2.48-3.43,1.86-5.46Zm-27.14,3.86v7.46c0,4.2-3.42,7.62-7.63,7.62h-4.34l-.34-4.98c-.33-4.82-1.41-9.8-3.19-14.8-.69-1.92-1.04-3.95-1.04-6.01v-.16c.05-6.22,3.38-11.76,8.32-14.93,3.72,2.39,6.53,6.14,7.68,10.7l.03,.11c.03,.1,3.8,12.52,3.8,12.52,.02,.06,0,.14-.06,.17l-3.23,2.3Zm9.73,0v7.46c0,4.19-3.4,7.6-7.59,7.62,1.65-2.11,2.63-4.75,2.63-7.62v-4.99l1.23-.87c1.73-1.24,2.48-3.43,1.86-5.46l-3.76-12.38s-.02-.05-.02-.07c-1.18-4.64-3.72-8.63-7.13-11.58,5.94,1.76,10.66,6.51,12.24,12.79l.03,.11c.03,.1,3.81,12.52,3.81,12.52,.02,.06,0,.14-.06,.17l-3.24,2.3Zm12.78-2.3l-3.23,2.3v7.46c0,4.12-3.3,7.5-7.4,7.62,1.65-2.11,2.63-4.75,2.63-7.62v-4.99l1.23-.87c1.73-1.24,2.47-3.43,1.86-5.46l-3.77-12.38s-.01-.05-.02-.07c-1.17-4.6-3.66-8.54-7.02-11.49,5.79,1.83,10.39,6.53,11.94,12.7l.04,.11c.03,.1,3.8,12.52,3.8,12.52,.02,.06,0,.14-.06,.17Z"
          fill={"#fff"}
        />
      </G>
    </Svg>
  );
};

export default LCAEdgeLevel2;
