import React from "react";
import { StyleSheet } from "react-native";
import RecognitionLogoIndentIcon from "../../../assets/icons/RecognitionLogoIndent";
import { Text, View } from "../../constants/Themed";
import useColorScheme from '../../hooks/useColorScheme';
import Colors from '../../constants/Colors';

const HeaderLogoMobile = () => {
    const colorScheme = useColorScheme();
  return (
    <View style={styles.container}>
      <View style={{ width: 16, height: 16, backgroundColor:'transparent' }}>
        <RecognitionLogoIndentIcon />
      </View>
      <Text style={[styles.beautifulText, {color: Colors[colorScheme].text}]}>
        RECOGNITION
      </Text>
    </View>
  );
};

export default HeaderLogoMobile;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: 'transparent',
  },

  beautifulText: {
    marginTop: 2,
    fontSize: 16,
    marginLeft: 10,
    letterSpacing: 2,
  },
});
