import { StyleSheet, Image, ActivityIndicator, ScrollView, Pressable } from "react-native";
import { View, Text } from "../../constants/Themed";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { BoldText } from "../../constants/StyledText";
import Colors from "../../constants/Colors";
import useColorScheme from "../../hooks/useColorScheme";
import React, { useEffect, useState } from "react";
import { RootStackParamList } from "../../navigation/types";
import { Video, AVPlaybackStatus } from "expo-av";

//these imports are for the cloud query
import { API, graphqlOperation } from "aws-amplify";
import { getCriteria } from "../../graphql/queries";
import { Criteria } from "../../API";

//this import is for getting the image out of storage
import { Storage } from "aws-amplify";

export default function CriteriaDetail() {
  const route = useRoute<RouteProp<RootStackParamList, "Modal">>();
  const navigationUsingHook = useNavigation();
  const { criteriaID } = route.params;
  const colorScheme = useColorScheme();
  const [isLoading, setIsLoading] = useState(true);
  //the cloud query and image from storage request
  const [criteriaQueryResponse, setCriteriaQueryResponse] = useState<Criteria>(
    []
  ); //state variabel for query data
  const [downloadedImageURI, setDownloadedImageURI] = useState<string | null>(
    null
  ); //state variable for imageURI
  const [downloadedVideoURI, setDownloadedVideoURI] = useState<string | null>(
    null
  ); //state variable for imageURI

  const fetchCriteria = async () => {
    const response = await API.graphql(
      graphqlOperation(getCriteria, { id: criteriaID })
    ); //run query
    setCriteriaQueryResponse(response.data.getCriteria); //store query data in state variable

    const file = await Storage.get(response.data.getCriteria.logoColourURI, {
      level: "public",
    }); //get file using query data
    setDownloadedImageURI(file); //store imageURI in state variable to be used elsewhere on the page

    const video = await Storage.get(response.data.getCriteria.videoURL, {
      level: "public",
    }); //get file using query data
    setDownloadedVideoURI(video); //store imageURI in state variable to be used elsewhere on the page
    
    setIsLoading(false)
  
  
  };
  useEffect(() => {
    fetchCriteria();
  }, []);

  const { description, synonyms, colour, name, strapline } =
    criteriaQueryResponse; //destructure the text data to be used elsewhere on the page

  useEffect(() => {
    navigationUsingHook.setOptions({
      headerLeft: () => (
        <Pressable
          style={{ marginLeft: 20 }}
          onPress={() => navigationUsingHook.goBack()}
        >
          <Text style={{ color: Colors[colorScheme].buttons }}>Cancel</Text>
        </Pressable>
      ),
    });
  }, []);

  const video = React.useRef(null);

  return (
   <>
   
    {isLoading ? <View style={{flex: 1, backgroundColor: 'transparent', alignItems: 'center', justifyContent: 'center'}}><ActivityIndicator size="large" color = {Colors.palette.logoGreen} /></View> : 
   <ScrollView style={styles.root}>



      <View style={{ flexDirection: "row", flex: 1, justifyContent: "center" }}>
       
      

       
        <View style={{
            flex: 1}}>
          {/* CRITERIA image */}
          <View style={{ flex: 1, flexDirection: "row" }}>
            <Image
              source={{ uri: downloadedImageURI }}
              style={styles.badgeIcon}
            />

            {/* CRITERIA Name and strapline */}
            <View style={styles.nameContainer}>
              <BoldText style={styles.badgeName}>{name}</BoldText>
              <Text style={{ color: colour }}>{strapline}</Text>
            </View>
          </View>
          
        </View>

        <View
          style={{
            flex: 1,
            // justifyContent: "center",
            // marginTop: 5,
            // marginBottom: 25,
          }}
        >
          <Video
            ref={video}
            style={styles.video}
            source={{
              uri: downloadedVideoURI,
            }}
            useNativeControls
            shouldPlay
            resizeMode={"cover"}
          />
        </View>




      </View>

{/* description */}
      <View style={styles.textMetaDataContainer}>
        <Text
          style={[
            styles.metaData,
            { color: Colors[colorScheme].textLessEmphasised },
          ]}
        >
          {description}
        </Text>
      </View>

      {/* SYNONYMS */}
      <View
        style={[
          styles.descriptionContainer,
          { backgroundColor: Colors[colorScheme].almostButtons },
        ]}
      >
        <BoldText
          style={[
            styles.title,
            { color: Colors[colorScheme].text, textAlign: "center" },
          ]}
        >
          {synonyms}
        </BoldText>
      </View>
    
        
    </ScrollView>}</>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    paddingHorizontal: 20,
    // alignItems: "center",
    backgroundColor: "transparent",
    // width: 1000,
    // alignSelf: "center",
  },
  descriptionContainer: {
    marginBottom: 20,

    padding: 20,
    borderRadius: 10,
    // margin: 20,
  },
  mappingRowContainer: {
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
    backgroundColor: "transparent",
  },
  title: {
    fontSize: 14,
    // fontWeight: "bold",
  },
  textMetaDataContainer: {
    // marginHorizontal: 10,
    marginBottom: 20,
    padding: 20,
    borderBottomRightRadius:10,
    borderBottomLeftRadius: 10,
    // flex: 1,
  },
  badgeIcon: {
    alignSelf: "center",
    height: 200,
    width: 200,
    marginBottom: 20,
  },
  metaDataContainer: {
    backgroundColor: "transparent",
    justifyContent: "center",
    width: "50%",
  },
  badgeName: {
    fontSize: 26,
  },
  nameContainer: {
    margin: 20,
    justifyContent: "center",
    flex: 1,
  },
  metaData: {
    fontSize: 14,
    marginLeft: 10,
  },
  metaDataPairContainer: {
    flexDirection: "row",
  },
  video: {
    alignSelf: "center",
    width: "100%",
    aspectRatio: 16 / 9,
  },
});
