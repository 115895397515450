import React, { useEffect, useState, useContext } from "react";
import { StyleSheet, Image, Pressable } from "react-native";
import { Text, View } from "../../constants/Themed";
import useColorScheme from "../../hooks/useColorScheme";
import Colors from "../../constants/Colors";
import { useNavigation } from "@react-navigation/native";
import {Storage} from 'aws-amplify'
import { UserContext } from "../../components/Context";

const HeaderUser = () => {
  
 const userDetailsFromUserContext = useContext(UserContext);

  //set up a state variable to hold your proper, working image URL
  const [downloadedImageURI, setDownloadedImageURI] = useState<string | null>(null);

  //function to download the file and save it in the state variable
  const downloadMedia = async () => {
    const file = await Storage.get(
      userDetailsFromUserContext.photoURL, {
      level: "public"
    }
    );
    setDownloadedImageURI(file)
  }

  //call the function when the compoent mounts (only once)
  useEffect(()=> {
    downloadMedia();
  }, [])


  const colorScheme = useColorScheme();
  const navigation = useNavigation();
  return (
    <Pressable onPress={() =>
      navigation.navigate('Settings',
          )
    }>
    <View style={styles.container}>
      <Text style={[styles.userText, { color: Colors[colorScheme].text }]}>
        {userDetailsFromUserContext.title} {userDetailsFromUserContext.surname}
      </Text>
      <Image
      //the error below is because you are saying the URI can be null (which it can't). You need an if in the downloadMedia function
        source={{
          // uri: user.image,
          uri: downloadedImageURI
        }}
        style={styles.userImage}
      />
    </View></Pressable>
  );
};

export default HeaderUser;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "transparent",
    marginHorizontal: 10,
  },

  userText: {
    fontSize: 14,
  },
  userImage: {
    width: 40,
    height: 40,
    marginHorizontal: 10,
    borderRadius: 20,
  },
});
