import { Text, View } from "../constants/Themed";
import { StyleSheet, Image, Pressable,  } from "react-native";
import Colors from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme";
import React, { useEffect, useState } from "react";
import { BoldText } from "../constants/StyledText";
import { IBadge, IOpportunity } from "../models/types";
import {useNavigation} from '@react-navigation/native';
// import { getOpportunity } from "../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import DynamicBadge from "../../src/components/DynamicBadge";
import {Storage} from 'aws-amplify'

// interface BadgeProps {
//   opportunity: IBadge
// }

export const getAwardedBadge = /* GraphQL */ `
  query GetAwardedBadge($id: ID!) {
    getAwardedBadge(id: $id) {
      id
      opportunityID
      Opportunity {
        id
        isActive
        name
        aaa
        description
        logoURL
        location
        colour
        engagementLevel
        regularMonday
        regularTuesday
        regularWednesday
        regularThursday
        regularFriday
        regularStartTime
        regularEndTime
        dateType
        oneOffDate
        regularStartDat
        regularEndDate
        multipleStartDate
        multipleEndDate
        totalHours
        userID
        opportunitycategoryID

      }
    }
  }
`;



// const ThumbnailOpportunity = ({opportunity}: BadgeProps) => {
const ThumbnailOpportunity = ({opportunityToDownload}) => {

const {id} = opportunityToDownload;

  
  const [opportunityData, setOpportunityData] = useState([]);
  const [downloadableLogoURL, setDownloadableLogoURL] = useState();

  const colorScheme = useColorScheme();
  const navigation = useNavigation();

  
  const fetchOpportunityData = async () => {

    const response = await API.graphql(
      graphqlOperation(getAwardedBadge, { id: id })
    );
    setOpportunityData(response.data.getAwardedBadge.Opportunity);
 
   
    
    
    
    
    
    


  };


  useEffect(() => {
    fetchOpportunityData();
  },[]);



const downloadGraphic = async () => {

   // if(response.data.getAwardedBadge.Opportunity.logoURL) {
    const file = await Storage.get(opportunityData.logoURL, {level: "public"}); //get file using query data
    // const file = await Storage.get(response.data.getAwardedBadge.Opportunity.logoURL, {level: "public"}); //get file using query data
    setDownloadableLogoURL(file) //store imageURI in state variable to be used elsewhere on the page
    
  // }

  }



  useEffect(() => {
    downloadGraphic();
  },[opportunityData.logoURL]);



  const {
    
    colour,
    engagementLevel} = opportunityData





  // console.warn('colour=', colour, 'engagementLevel=', engagementLevel, 'name=', name)
    




// we need a stroage request to turn the logoURL into a proper working URL, then send this and the other properties to the dynamicBadge component
//also need to get the navigation to the badge working by renaming the id a differnet variable name 




  // console.warn(opportunity)
  const NavigateToOpportunityDetail = () => {
    // console.warn(opportunity.opportunity.id)
    navigation.navigate('OpportunityDetail', {opportunityID: opportunityData.id}) // TYPESCRIPT ERROR. Also, you shouldn't be sending the whole object through navigatin, just the ID of the shoutout then query the database on the detail screen
  }
  return (
    <Pressable onPress={NavigateToOpportunityDetail}>
    
{/* <Image source={{uri: logoURL}} style={styles.icon} />  */}

<View style={{backgroundColor: 'transparent', padding:5}}>
<DynamicBadge
            size={100}
            // edgeColour={colour == null ? '#222222' : '#333333'}
            // edgeColour={colour == null ? '#dbdbdb': colour }
            edgeColour={colour}
            // engagementLevel={engagementLevel == null ? 3: engagementLevel}
            engagementLevel={3}
            logo={downloadableLogoURL}
          />
</View>
 </Pressable>
 
  );
};

export default ThumbnailOpportunity;

const styles = StyleSheet.create({
  root: {
    borderRadius: 5,
          borderWidth: 1,
          padding: 10,
          margin: 5,
          width: 320,
  },
  textContainer:{
    marginLeft: 20,
    // width: '100%',
    flex: 1,
    justifyContent: 'center',

    
  },
  icon:{
    width: 120,
    height: 120,
    margin: 10,
  },
  title:{
    fontSize: 18,
    lineHeight: 18,
    marginBottom: 10,
  }
});
