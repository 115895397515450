import { StyleSheet, Pressable, ActivityIndicator} from "react-native";
import { View, Text } from "../../constants/Themed";
import { BoldText } from "../../constants/StyledText";
import { RootTabScreenProps } from "../../navigation/types";
import Colors from '../../constants/Colors'
import * as WebBrowser from "expo-web-browser";
import DynamicBadge from "../../components/DynamicBadge";
import {Auth} from 'aws-amplify';
import Constants from 'expo-constants';
import { useEffect, useState } from "react";
import { Feather } from '@expo/vector-icons';
import useColorScheme from "../../hooks/useColorScheme";
// import Animated, {FadeIn, SlideInLeft, SlideInUp} from 'react-native-reanimated';

import { DataStore } from '@aws-amplify/datastore';
import { AwardedBadge } from './../../models';

import { API } from "aws-amplify";
import HousePointsWeekly from "../../components/HousePointsWeekly";
// import * as mutations from './../../graphql/mutations';

export const createAwardedBadge = /* GraphQL */ `
  mutation CreateAwardedBadge(
    $input: CreateAwardedBadgeInput!
    $condition: ModelAwardedBadgeConditionInput
  ) {
    createAwardedBadge(input: $input, condition: $condition) {
      id
      dateAwarded
      scoringData1Value
      scoringData2Value
      scoringData3Value
      groupingData1Value
      groupingData2Value
      groupingData3Value
      opportunityID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;


export default function SettingsRoot({
  navigation,
}: RootTabScreenProps<"ShoutOuts">) {
  function handleHelpPress() {
    WebBrowser.openBrowserAsync(
      "https://support.recognition.org.uk"
    );
  }



  const awardedBadgeDetailsToUpload = {
    


		dateAwarded: "2022-09-09Z",
		scoringData3Value: 5,
		groupingData1Value: "Tomlinson",
		groupingData2Value: "AJL",
		groupingData3Value: "Year 10",
		opportunityID: "CARRMANOR-OPP-0004",
		userID: "CARRMANOR-005717-LAKIN"
		



  };


  


  function handleBugPress() {
    WebBrowser.openBrowserAsync(
      "https://forms.office.com/Pages/ResponsePage.aspx?id=4i86_GOrvEutrKyNOzdxNAZG0OuFsM1Ajh1DmdVoPvxUMkxYUEVaV1JYRUFXSDFFTDdETlRZQTEwTy4u"
    );
  }

  function handleDeletePress() {
    WebBrowser.openBrowserAsync(
      "https://forms.office.com/r/BH8gx43W8i"
    );
  }

  function handleAddRecordPress() {

   
    writeToDatabase();
  }

  function printPage() {
    window.print();
  }




const writeToDatabase = async () => {

  await API.graphql({ query: createAwardedBadge, variables: {input: awardedBadgeDetailsToUpload}});

console.warn('in theory, its written')

}
//asasas


  const [currentLoggedInUser, setCurrentLoggedInUser] = useState();
  const [isDone, setIsDone] = useState(false)
  const colorScheme = useColorScheme();
  const getUserDetails = async () => {
    const { attributes } = await Auth.currentAuthenticatedUser();
    setCurrentLoggedInUser(attributes)
    setIsDone(true)
  }
  
  useEffect(() => {
   getUserDetails();
  }, []);








  return (
    
      <View style={[styles.root, {backgroundColor: Colors[colorScheme].almostBackground}]}>
  
  {/* <HousePointsWeekly /> */}
        <Text>You are using Recognition version {Constants.manifest.version}</Text>
  
  {/* <Text>Your email address is: {isDone ? currentLoggedInUser.email: <ActivityIndicator />}</Text> */}
  {/* <Text>Your date of birth is: {userDetailsFromUserContext.dateOfBirth}</Text> */}
  {/* <Text> Your ID in Recognition us: {currentLoggedInUser.sub}</Text> */}
  {/* <Pressable onPress={() => navigation.navigate('TimelinesV2')} ><Text>Alternative Pupil Search System</Text></Pressable> */}
      
  
  
      <View style={{justifyContent: 'space-between', backgroundColor: 'transparent', }}>
  
      {/* <View style={{borderColor: 'black', borderWidth: 1, padding:10, margin: 20, borderRadius:10, }}>
      <Pressable onPress={printPage}>
        <BoldText>Print test</BoldText>
      </Pressable>
      </View> */}






      <View style={{backgroundColor: Colors.palette.logoGreen, padding:10, margin: 20, borderRadius:10}}>
        <Pressable onPress={handleHelpPress} style={{alignItems:'center'}}>
        <Feather name="help-circle" size={24} color={Colors[colorScheme].background} />
          <BoldText style={{color: Colors[colorScheme].background}}>Get Support</BoldText>
        </Pressable>
        </View>
  
  
  
        <View style={{backgroundColor: Colors.palette.logoGreen, padding:10, margin: 20, borderRadius:10}}>
        <Pressable onPress={handleBugPress} style={{alignItems:'center'}}>
        <Feather name="frown" size={24} color={Colors[colorScheme].background} />
          <BoldText style={{color: Colors[colorScheme].background}}>Report a Bug</BoldText>
        </Pressable>
        </View>
  
  
        <View style={{backgroundColor: Colors.palette.logoGreen, padding:10, margin: 20, borderRadius:10}}>
        <Pressable onPress={handleDeletePress} style={{alignItems:'center'}}>
        <Feather name="user-x" size={24} color={Colors[colorScheme].background} />
          <BoldText style={{color: Colors[colorScheme].background}}>Delete Account</BoldText>
        </Pressable>
        </View>
  
  
  
        <View style={{backgroundColor: Colors.palette.logoGreen, padding:10, margin: 20, borderRadius:10}}>
        <Pressable onPress={()=> Auth.signOut()} style={{alignItems:'center'}}>
        <Feather name="log-out" size={24} color={Colors[colorScheme].background} />
          <BoldText style={{color: Colors[colorScheme].background}}>Sign Out</BoldText>
        </Pressable>
        </View>
  
  
        </View>
  
   {/* add record button */}
        {/* <View style={{backgroundColor: Colors.palette.logoGreen, padding:10, margin: 20, borderRadius:10}}>
        <Pressable onPress={handleAddRecordPress} style={{alignItems:'center'}}>
        <Feather name="log-out" size={24} color={Colors[colorScheme].background} />
          <BoldText style={{color: Colors[colorScheme].background}}>test add</BoldText>
        </Pressable>
        </View> */}
  
    
      </View>
    );

  


     



}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    width: 1000,
    alignSelf: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },

});
