import { View, Text } from "../../constants/Themed";
import { StyleSheet, Image, Pressable } from 'react-native'
import React, { useEffect, useState } from 'react'
import { IMapping } from "../../models/types";

import {useNavigation} from "@react-navigation/native"; 
//this import is for getting the image out of storage
import {Storage} from 'aws-amplify'


interface IMappedProps {
  mapping: IMapping;
}

const MappedCriteria = ({mapping}) => {

  const criteriaName = mapping.Criteria.name
  const criteriaID = mapping.Criteria.id
  const criteriaLogo = mapping.Criteria.logoWhiteURI
  const criteriaColour = mapping.Criteria.colour

  // console.warn(criteriaLogo)
const navigation = useNavigation();

  const openAttributeDetailModal = () => {
    navigation.navigate("Modal", {criteriaID: criteriaID});
    //AJL here we are sending the ID 
  }



  const [downloadedImageURI, setDownloadedImageURI] = useState<string | null>(null); //state variable for imageURI

  const fetchCriteriaLogo = async () => {
    const file = await Storage.get(criteriaLogo, {level: "public"}); //get file using query data
  setDownloadedImageURI(file) //store imageURI in state variable to be used elsewhere on the page
  

};
useEffect(()=> {
  fetchCriteriaLogo();
}, []);
    



  return (
    <Pressable onPress={openAttributeDetailModal}>
    <View style={[styles.taggedItemContainer, {backgroundColor: criteriaColour}]}>
     
    <Image
      source={{
        uri: downloadedImageURI,
      }}
      style={styles.taggedProfileImage}
    />
   <View style={{flex: 1, justifyContent: 'center', backgroundColor: 'transparent'}}>
    <Text style={styles.taggedName}>{criteriaName}</Text></View>
  </View>
  </Pressable>
  )
}

export default MappedCriteria

const styles = StyleSheet.create({  taggedProfileImage: {
    width: 45,
    aspectRatio: 1,
    resizeMode: 'contain',
    marginLeft: 10,
    // borderRadius: 20,
    // marginBottom: 5,
  },
  taggedItemContainer: {
    alignItems: "center",
    margin: 5,
    width: 175,
    height: 60,
    borderRadius: 10,
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  taggedName: {
    fontSize: 16,
    flexWrap: "wrap",
    // textAlign: "center",
    alignSelf: 'center',
    color: 'white',
padding: 2
  },
})