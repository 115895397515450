import React from "react";
import { StyleSheet } from "react-native";
import { View, Text } from "../../constants/Themed";
import OpportunityIcon from "../../../assets/icons/Opportunity";
import Colors from '../../constants/Colors';
import useColorScheme from "../../hooks/useColorScheme";
const DrawerMenuItemOpportunities = (props: any) => {
  const colorScheme = useColorScheme();
  return (
    <View style={styles.root}>
    <View style={styles.iconContainer}>
      <OpportunityIcon color={props.color.color} />
    </View>
    <View style={{backgroundColor: 'transparent'}}>
    <Text style={[styles.labelText, {color:Colors[colorScheme].text}]}>Opportunities</Text>
    </View>
    </View>
  );
};

export default DrawerMenuItemOpportunities;

const styles = StyleSheet.create({
  root:{
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: "transparent",
  },
  iconContainer: {
    width: 30,
    height: 30,
    backgroundColor: "transparent",
  },
  labelText:{
    // color: Colors.palette.grey,
    fontSize: 16,
    paddingLeft: 5,
  },
});