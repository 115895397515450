import { useContext, useEffect, useState } from "react";

import { StyleSheet, Platform, FlatList, Image } from 'react-native';
import { View, Text } from '../../constants/Themed';
import DUMMYQUERYSHOUTOUTS from '../../models/DummyQueryShoutOutsWithTags.json';
import FeedItemShoutOut from '../../components/mobile/FeedItemShoutOut';
import { BoldText } from "../../constants/StyledText";
import useColorScheme from "../../hooks/useColorScheme";
import Colors from "../../constants/Colors";
import OpportunityIcon from '../../../assets/icons/Opportunity'
import TimelineIcon from '../../../assets/icons/Timeline'
import HousePointsWeekly from '../../components/HousePointsWeekly';


export default function ShoutOutsRootMobile() {
  const colorScheme = useColorScheme();


  


  return (
    <View style={[styles.root, {backgroundColor: Colors[colorScheme].almostBackground}]}>


    {/* <HousePointsWeekly /> */}
    <Image source={require('../../../assets/images/HC_black_whitebg.png')} style={{width: 426, height: 426, margin: 30}} />
<Text>The 2023-2024 House Points competition is coming soon...</Text>

      {/* SHOUTOUTS FLATLIST FOR WHEN THIS SCREEN IS WORKING PROPERLY */}
    {/* <FlatList
      data={DUMMYQUERYSHOUTOUTS}
      renderItem={({ item }) => <FeedItemShoutOut shoutout={item} />} 
      keyExtractor={(item) => item.id}
    /> */}
    {/* <View style={{backgroundColor: Colors[colorScheme].background,
    borderRadius: 10,
    padding: 10,
    width:'80%'
    }}>
    <BoldText style={{fontSize: 20, color:Colors[colorScheme].text}}>Welcome to Recognition</BoldText>
    <Text>Use the buttons at the bottom of the screen to explore...</Text>
    <View style={{paddingVertical:20}}>
    <View style={{flexDirection: 'row',  alignItems: 'center', paddingVertical: 10}}>
    <View style={{width:30, height: 30, }}>
    <OpportunityIcon color={Colors.palette.logoGreen} />
    
    </View>
    <BoldText style={{color:Colors[colorScheme].text, fontSize:16}}>Opportunities</BoldText>
    </View>
    
    <Text>The activities available to children at Carr Manor Community School.</Text>
    </View>
    <View style={{paddingVertical:20}}>
    <View style={{flexDirection: 'row',alignItems: 'center', paddingVertical: 10}}>
    <View style={{width:30, height: 30}}>
    <TimelineIcon color={Colors.palette.logoGreen} />
    
    </View>
    <BoldText style={{color:Colors[colorScheme].text, fontSize:16}}>Timelines</BoldText>
    </View>
    
    <Text>The badges achieved by the children at Carr Manor Community School.</Text>
    </View>
    </View> */}
  </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
    // backgroundColor: 'transparent',
    alignSelf: 'center',
    justifyContent: 'center',
  },
});






