import React from "react";
import { StyleSheet } from "react-native";
import Colors from "../../constants/Colors";
import { View, Text } from "../../constants/Themed";
import TimelineIcon from "../../../assets/icons/Timeline";
import { BoldText } from "../../constants/StyledText";
import useColorScheme from "../../hooks/useColorScheme";

const ScreenTitleTimelinesMobile = () => {
  const colorScheme = useColorScheme();
  return (
    <View style={styles.root}>
      <View style={styles.titleContainer}>
        <View style={styles.iconContainer}>
          <TimelineIcon color={Colors.palette.logoGreen} />
        </View>
        <Text style={[styles.title, { color: Colors[colorScheme].text }]}>
          Timelines
        </Text>
      </View>
    </View>
  );
};

export default ScreenTitleTimelinesMobile;

const styles = StyleSheet.create({
  root: {
    backgroundColor: "transparent",
    // width: 1000,
    alignSelf: 'center',
    // flex: 1,
    // paddingVertical: 10,
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "transparent",
  },
  title: {
    fontSize: 16,
  },
  iconContainer: {
    width: 35,
    height: 35,
    backgroundColor: "transparent",
  },
});
