import { StyleSheet, Image, Pressable, useWindowDimensions } from "react-native";
import { Text, View } from "../constants/Themed";
import React, { useEffect, useRef, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { Storage } from "aws-amplify";
import TimelineIcon from "../../assets/icons/Timeline";
import Colors from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme";
import { BoldText } from "../constants/StyledText";





const CardTimeline = ({ connection }) => {
  const windowWidth = useWindowDimensions().width

const [proxyNeeded, setProxyNeeded] = useState(false);





  const variableItemWidth = windowWidth > 1200 ? (windowWidth - (220+48)) /3 : (windowWidth > 500 ? (windowWidth - 32) /2 : windowWidth - 16)
  
    //double the margin

  const { surname, preferredFirstName, photoURL, id, Enrolments } = connection;
  // console.warn(connection)

  //destructure the first (only) object in the enrolment array using default vales if there's no data in the array
  //not sure if this works - probably not!


// function insertEnrolmentData = () => {
const enrolmentArray = connection.Enrolments.items[0]

//   function checkArrayForOrg(arrayItem) {
//     return arrayItem.organisationID === 'e5b5c6c5-9a99-45cf-9bb2-c94764a6ca6c';
//   }
  
//   const checkedArray = enrolmentArray.find(checkArrayForOrg);
//   if (checkedArray) {
  const {
    groupingData2 = "no data available",
    groupingData3 = "no data available",
  } = enrolmentArray;
//   return
//   <Text>{groupingData3}</Text>
// } else {
//   return null
// }
// }

// 
//   const groupingData3 = checkedArray.groupingData3
// 

// if(typeof checkedArray.groupingData3 === 'undefined') {
//   let useGroup3Proxy = true 
//   console.warn(useGroup3Proxy)
// }


// console.warn(checkedArray.groupingData3)

  // const enrolmentArray = connection.Enrolments.items[0];


// if (enrolmentArray.find(checkArrayForOrg)) {
//   console.warn('YES!')
//   const yearGroup = checkedArray.groupingData3
// const coachingGroup = checkedArray.groupingData2
// }
// else {
//   console.warn('NO')
//   const yearGroup = 'no no no no'
//   const coachingGroup = 'no no no no'
// }


  //the line below works!


  

  





  const navigation = useNavigation();
  const colorScheme = useColorScheme();
  const NavigateToTimelineDetail = () => {
    // console.warn(id)
    navigation.navigate("TimelinesDetail", { userIDForQuery: id }); // TYPESCRIPT ERROR. Also, you shouldn't be sending the whole object through navigatin, just the ID of the shoutout then query the database on the detail screen
  };
  const isCancelled = useRef(false);
  const [downloadedImageURI, setDownloadedImageURI] = useState<string | null>(
    null
  ); //state variable for imageURI

  //get the imageURI from storage
  const getImage = async () => {
    const file = await Storage.get(photoURL, { level: "public" });
    if (!isCancelled.current) {
      setDownloadedImageURI(file); //store imageURI in state variable to be used elsewhere on the page
    }
  };

  useEffect(() => {
    
    
    getImage();


    // if (typeof checkedArray === 'undefined') {
    //   console.warn('PROBLEM')
    //   setProxyNeeded(true)
    // }


    return () => {
      isCancelled.current = true;
    };
  }, []);

  return (
    <Pressable onPress={NavigateToTimelineDetail}>
      <View
        style={[
          styles.root,
          {
            backgroundColor: Colors[colorScheme].background,
            // borderColor: Colors[colorScheme].edging,
            width: variableItemWidth
            // maxWidth: {windowWidth > 500 ? windowWidth 20
          },
        ]}
      >
        <View
          style={{
            flexDirection: "row",
            // flex: 1,
            // width: "100%",
            justifyContent: "flex-start",
            alignItems: "center",
            // margin: 10,
          }}
        >
          <View style={{ width: 100, height: 100 }}>
            {/* <Image source={require('../../../assets/images/icon.png')} style={{height: 100, borderRadius: 100, width: 100, position:"absolute"}} />  */}
            <TimelineIcon color={"#ccc"} />
            <Image
              source={{ uri: downloadedImageURI }}
              style={[
                styles.image,
                { borderColor: Colors[colorScheme].edging },
              ]}
            />
          </View>
          <View style={{ marginHorizontal: 10, flex: 1}}>
            <BoldText
              numberOfLines={1}
              style={[styles.title, { color: Colors[colorScheme].text }]}
            >
              {preferredFirstName} {surname}
            </BoldText>

            <Text numberOfLines={1}>{groupingData3}</Text>
            <Text numberOfLines={1}>{groupingData2}</Text>
          </View>
        </View>
        {/* <RenderProfilePhoto /> */}
      </View>
    </Pressable>
  );
};

export default CardTimeline;

const styles = StyleSheet.create({
  image: {
    width: 100,
    height: 100,
    borderRadius: 100,
    // paddingRight:10,
    borderWidth: 1,
    // margin: 10,
    position: "absolute",
  },
  root: {
    borderRadius: 10,
    // borderWidth: 1,
    padding: 10,
    marginHorizontal: 8,
    marginVertical: 8,
    // minWidth: 380,
    // maxWidth: 390,
  },
  title: {
    fontSize: 18,
  },
});
