import "react-native-gesture-handler"; //this has to be the first import
import { StatusBar } from "expo-status-bar";

import { SafeAreaProvider } from "react-native-safe-area-context";
import { useWindowDimensions, Platform, Button, Image, Linking} from "react-native";
import useCachedResources from "./src/hooks/useCachedResources";
import useColorScheme from "./src/hooks/useColorScheme";
import { View, Text } from "./src/constants/Themed";
import { Amplify } from 'aws-amplify'
import AuthenticationStyles from './src/constants/AuthStyles'
import {withAuthenticator} from 'aws-amplify-react-native'
import AppRootWithAuthenticator from './src/components/AppRootWithAuthenticator';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import AuthenticationCheck from "./src/screens/AuthenticationCheck";
import awsconfig from './src/aws-exports'

Amplify.configure(awsconfig)

const App = () => {
  
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  const windowSize = useWindowDimensions();
  const Stack = createNativeStackNavigator();

  

  


  // const LogIn = ({ navigation }) => {
  //   return (
  //     <AppRootWithAuthenticator />
  //   );
  // }


//   const HomeScreen = ({navigation}) => {

// return (
// <View style={{flex:1, alignItems: 'center', justifyContent: 'center'}}>
// <Image
//         style={{width:220, height: 25, marginBottom: 50}}
//         source={require('./assets/images/Recognition_logo_equal.png')}
//       />

//   <View style ={{flexDirection: 'row', justifyContent: 'space-between', width: 200}}>
//   <Button
//   color="#51cc85"
// title="SIGN IN"
// onPress={() => navigation.navigate('LogIn')}
// />
// <Button
//   color="#51cc85"
// title="SUPPORT"
// onPress={() => Linking.openURL('https://support.recognition.org.uk/')}
// /></View>
//   <View>
    
//   </View>
//   </View>
// )

//   }




  if (!isLoadingComplete) {
    return null;
  } else {
    return (
     <SafeAreaProvider>
         

         {/* <NavigationContainer>
      <Stack.Navigator initialRouteName="Home" screenOptions={{headerShown:false}} >
        <Stack.Screen name="Home" component={HomeScreen} />
        <Stack.Screen name="LogIn" component={LogIn} />
      </Stack.Navigator>
    </NavigationContainer> */}


<AuthenticationCheck />
            
          </SafeAreaProvider>
    );
  }
}


// export default (App);



const signUpConfig = {
  hideAllDefaults: true,
  signUpFields: [
   
    {
      label: 'Email address',
      key: 'username',
      required: true,
      displayOrder: 1,
      type: 'string',
      placeholder: 'Type your email address here',
      
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      displayOrder: 2,
      type: 'password',
      placeholder: 'Type your password here',
      
    }
  ]
}
export default withAuthenticator(App, {signUpConfig, theme:Platform.OS === "ios" ||
Platform.OS === "android" ? (
  AuthenticationStyles.customNativeAppTheme
) : (
  AuthenticationStyles.customBrowserTheme
  
)});
