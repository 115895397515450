import { StyleSheet, Image, Pressable, useWindowDimensions } from "react-native";
import { Text, View } from "../constants/Themed";
import React, { useEffect, useRef, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { API, graphqlOperation, Storage } from "aws-amplify";
import TimelineIcon from "../../assets/icons/Timeline";
import Colors from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme";
import { BoldText } from "../constants/StyledText";

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      surname
      firstName
      preferredFirstName
      aaa
      title
      type
      photoURL
      dateOfBirth
      email
      gender
      isActive
      Enrolments {
        nextToken
        startedAt
      }
      Opportunities {
        nextToken
        startedAt
      }
      AwardedBadges {
        nextToken
        startedAt
      }
      ConnectionsUsersThatSeeThisUser {
        nextToken
        startedAt
      }
      ConnectionsUsersThisUserSees {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;



const ThumbnailTimeline = ({ id }) => {
  const windowWidth = useWindowDimensions().width
const [userData, setUserData] = useState([]);
const {preferredFirstName, photoURL} = userData;
// const variableItemWidth = windowWidth > 1200 ? (windowWidth - (220+48)) /3 : (windowWidth > 500 ? (windowWidth - 32) /2 : windowWidth - 16)
const navigation = useNavigation();
const colorScheme = useColorScheme();
const isCancelled = useRef(false);
const [downloadedImageURI, setDownloadedImageURI] = useState<string | null>(
  null
);
const NavigateToTimelineDetail = () => {
  navigation.navigate("TimelinesDetail", { userIDForQuery: id }); // TYPESCRIPT ERROR. Also, you shouldn't be sending the whole object through navigatin, just the ID of the shoutout then query the database on the detail screen
};


const fetchUser = async () => {
  const response = await API.graphql(
    graphqlOperation(getUser, {id: id
    })
  );
  setUserData(response.data.getUser);
  const file = await Storage.get(response.data.getUser.photoURL, { level: "public" });
  setDownloadedImageURI(file);
};

useEffect(() => {
  fetchUser();
}, []);



  return (
    <Pressable onPress={NavigateToTimelineDetail}>
      <View
        style={[
          styles.root,
          {
            backgroundColor: Colors[colorScheme].background,
            
            // width: variableItemWidth
            width: 120,
            // height: 120
          },
        ]}
      >
        {/* <View
          style={{
            // flexDirection: "row",
            flex: 1,
            // width: "100%",
            // justifyContent: "space-between",
            alignItems: "center",
            // margin: 10,
          }}
        > */}
          <View style={{ width: 100, height: 100 }}>
            {/* <Image source={require('../../../assets/images/icon.png')} style={{height: 100, borderRadius: 100, width: 100, position:"absolute"}} />  */}
            <TimelineIcon color={"#ccc"} />
            <Image
              source={{ uri: downloadedImageURI }}
              style={[
                styles.image,
                { borderColor: Colors[colorScheme].edging },
              ]}
            />
          </View>
          <View style={{ marginHorizontal: 10, flex: 1, 
            // marginVertical: 10
            }}>
            <BoldText
              numberOfLines={1}
              style={[styles.title, { color: Colors[colorScheme].text }]}
            >{preferredFirstName}
            </BoldText>

          </View>
        {/* </View> */}
        {/* <RenderProfilePhoto /> */}

        
      </View>
    </Pressable>
  );
};

export default ThumbnailTimeline;

const styles = StyleSheet.create({
  image: {
    width: 100,
    height: 100,
    borderRadius: 100,
    // paddingRight:10,
    borderWidth: 1,
    // margin: 10,
    position: "absolute",
  },
  root: {
    borderRadius: 10,
    // borderWidth: 1,
    padding: 10,
    marginHorizontal: 3,
    marginVertical: 8,
    // minWidth: 380,
    // maxWidth: 390,
  },
  title: {
    fontSize: 16,
    alignSelf: 'center',
    paddingTop: 8,
  },
});
