import { StyleSheet, Image, Pressable, ActivityIndicator} from "react-native";
import React, { useEffect, useState } from "react";
import {useNavigation} from '@react-navigation/native';
import { Text, View } from "../../constants/Themed";
import SignedURLImage from "../SignedURLImage";
// interface ShoutOutProps  {
//   shoutout: IShoutOut
// }

const ThumbnailShoutOut = ({shoutout}) => {
  
  const [imageS3Key, setImageS3Key] = useState(null)
 
  useEffect(() => {
  const firstMedia = shoutout.Media.items[0]
  console.warn(firstMedia)

  if (shoutout.Media && shoutout.Media.items && shoutout.Media.items[0]) {
    // const s3Key = shoutout.Media.items[0].s3Key;
    // console.log(s3Key);
    setImageS3Key(shoutout.Media.items[0].s3Key)
}
  }, [shoutout])



  const navigation = useNavigation();

  const NavigateToShoutOutsDetail = () => {
    
    navigation.navigate('ShoutOutsDetail',
     {shoutOutID: shoutout.id}
    // {shoutout}
     ) 
  }

  return <Pressable onPress={NavigateToShoutOutsDetail}>
    
    
    <View style={styles.image}>
    {imageS3Key?
    <SignedURLImage
            filter={"/filters:quality(100)/fit-in/500x500"}
            // filter={"/filters:quality(50)/filters:grayscale()/fit-in/100x100"}
            fileKey={"/public/" + imageS3Key}
          />:<ActivityIndicator />}
          </View>
    </Pressable>;
};

export default ThumbnailShoutOut;

const styles = StyleSheet.create({
  image: {
    width: 230,
    height: 230,
    borderRadius: 5,
    overflow: "hidden",
    margin: 10,
  },
});
