import { StyleSheet, Image } from "react-native";
import { View } from "../constants/Themed";
import React from "react";
import LCAEdgeLevel1 from "../../assets/icons/LCAEdgeLevel1";
import LCAEdgeLevel2 from "../../assets/icons/LCAEdgeLevel2";
import OpportunityIcon from "../../assets/icons/Opportunity";
import LCAEdgeLevel3 from "../../assets/icons/LCAEdgeLevel3";
import LCAEdgeLevel4 from "../../assets/icons/LCAEdgeLevel4";

interface DynamicBadgeProps {
  size: number;
  edgeColour: string;
  engagementLevel: number;
  logo: string;
}

const EdgeSelector = ({
  size,
  edgeColour,
  logo,
  engagementLevel,
}: DynamicBadgeProps) => {


  switch (engagementLevel) {
    case 1:
      return <LCAEdgeLevel1 edgeColour={edgeColour} />;

    case 2:
      return <LCAEdgeLevel2 edgeColour={edgeColour} />;

    case 3:
      return <LCAEdgeLevel3 edgeColour={edgeColour} />;

    case 4:
      return <LCAEdgeLevel4 edgeColour={edgeColour} />;

    default:
      return <OpportunityIcon edgeColour={edgeColour} />;
  }
};

const DynamicBadge = ({
  size,
  edgeColour,
  logo,
  engagementLevel,
}: DynamicBadgeProps) => {



  return (
    //   container for the badge which sets the dimensions

    <View style={{ width: size, height: size, backgroundColor: "transparent" }}>
      {/* The edge and background of the badge dependent on the engagement level */}
      <EdgeSelector
        engagementLevel={3}
        size={size}
        edgeColour={edgeColour}
        logo={""}
      />

      {/* The central logo assocaited with the badge inside the badge */}
      <Image
        source={{ uri: logo }}
        style={{
          width: "100%",
          backgroundColor: "transparent",
          height: "100%",
          position: "absolute",
          zIndex: 1,
        }}
      />

      {/* The shine over the top */}
      <Image
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: 2,
        }}
        source={require("../../assets/images/badge_shine.png")}
      />
    </View>
  );
};

export default DynamicBadge;

const styles = StyleSheet.create({});
