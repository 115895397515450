import {
  StyleSheet,
  ScrollView,
  Pressable,
  Image,
  ActivityIndicator,
} from "react-native";
import * as Linking from "expo-linking";
import { View, Text } from "../../constants/Themed";
import { RouteProp, useRoute } from "@react-navigation/native";
import { BoldText } from "../../constants/StyledText";
import Colors from "../../constants/Colors";
import MappedCriteria from "../../components/desktop/MappedCriteria";
import useColorScheme from "../../hooks/useColorScheme";
import React, { useState, useEffect } from "react";
import { RootStackParamList } from "../../navigation/types";

//these imports are for the cloud query
import { API, graphqlOperation } from "aws-amplify";
import DynamicBadge from "../../components/DynamicBadge";
//this import is for getting the image out of storage
import { Storage } from "aws-amplify";
import TimelineIcon from "../../../assets/icons/Timeline";

export const getOpportunity = /* GraphQL */ `
  query GetOpportunity($id: ID!) {
    getOpportunity(id: $id) {
      id
      isActive
      name
      description
      logoURL
      location
      colour
      availableTo
      engagementLevel
      regularMonday
      regularTuesday
      regularWednesday
      regularThursday
      regularFriday
      regularStartTime
      regularEndTime
      dateType
      oneOffDate
      regularStartDat
      regularEndDate
      multipleStartDate
      multipleEndDate
      totalHours
      userID
      opportunitycategoryID
      OpportunityCategory {
        id
        name
        logoWhiteURI
        logoColourURI
        logoGreyURI
        colour
      }
      OpportunityAvailabilities {
        items {
          groupingData3
        }
        nextToken
        startedAt
      }
      User {
        id
        surname
        firstName
        preferredFirstName
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Mappings {
        items {
          Criteria {
            id
            name
            logoWhiteURI
            colour
          }
        }
        nextToken
        startedAt
      }

      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export default function OpportunityDetail() {
  const route = useRoute<RouteProp<RootStackParamList, "OpportunityDetail">>();
  const { opportunityID } = route.params; //this isn't type checked and is bad practice. According to the Istagram course by Vadim (video 4.3) we should only be sending the shoutout ID and then querying the database on the detail screen

  // RUNNING A QUERY (...KIND OF!) ON A LOCAL JSON
  // const opportunityData = require('../../models/DummyQueryOpportunities.json') //which JSON?
  // const filteredData = opportunityData.filter(i => i.id == opportunityID) //where ID = ID from the route
  // const arrayRemovedData = filteredData.shift(); //pull out the first item in the array (there is only because of the line above)
  // const {iconURL, availableToYears, mapping, description, name, category, location, dateAndTime, volunteeringHours} = arrayRemovedData //destructure the fields you want.

  const [downloadedImageURI, setDownloadedImageURI] = useState<string | null>(
    null
  ); //state variable for imageURI

  const [downloadedContactPhotoURI, setDownloadedContactPhotoURI] = useState(); //state variable for imageURI
  const [availabilityForThis, setAvailabilityForThis] = useState([]);

  //the cloud query for a single record (that has a filter applied here)
  const [opportunitySingleRecordData, setOpportunitySingleRecordData] =
    useState([]);
  const [downloadedMappings, setDownloadedMappings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchOpportunity = async () => {
    const response = await API.graphql(
      graphqlOperation(getOpportunity, {
        id: opportunityID,
      })
    );

    setOpportunitySingleRecordData(response.data.getOpportunity); //all data in a variable to be destructured further down
    setDownloadedMappings(response.data.getOpportunity.Mappings.items); //Mappings data in a variable
    setAvailabilityForThis(
      response.data.getOpportunity.OpportunityAvailabilities.items
    );
    const file = await Storage.get(response.data.getOpportunity.logoURL, {
      level: "public",
    }); //get file using query data

    const contactPhotoURL = await Storage.get(
      response.data.getOpportunity.User.photoURL,
      {
        level: "public",
      }
    );
    setDownloadedContactPhotoURI(contactPhotoURL);
    console.warn(contactPhotoURL);

    setDownloadedImageURI(file); //store imageURI in state variable to be used elsewhere on the page
    setIsLoading(false);
  };
  useEffect(() => {
    fetchOpportunity();
  }, []);

  // console.warn(downloadedMappings)

  // console.warn(opportunitySingleRecordData.User);

  const {
    id,
    name,
    description,
    logoURL,
    engagementLevel,
    Criteria,
    colour,
    multipleStartDate,
    oneOffDate,
    Mappings,
    multipleEndDate,
    availableTo,
    dateType,
    location,
    regularStartTime,
    regularMonday,
    regularTuesday,
    regularWednesday,
    regularThursday,
    regularFriday,
    OpportunityCategory: { name: categoryName } = {}, // Destructing the property name in the object opportunityCategory in the object pulled from the query, then sticking it in a new variable called cateogryName. Not sure why the = {} is needed at the end, but it is.
    User: {
      title: contactTitle,
      surname: contactSurname,
      email: contactEmail,
    } = {},
    // User: title,
  } = opportunitySingleRecordData;

  // console.warn(title)

  // console.warn(contactTitle);

  //Timing line
  const DateTypeSelector = () => {
    switch (dateType) {
      case "REGULAR":
        return (
          <Text numberOfLines={1}>
            {regularStartTime} on{regularMonday ? " Monday" : null}
            {regularTuesday ? " Tuesday" : null}
            {regularWednesday ? " Wednesday" : null}
            {regularThursday ? " Thursday" : null}
            {regularFriday ? " Friday" : null}
          </Text>
        );

      case "MULTIPLEDATES":
        const mutipleStartDateObject = new Date(multipleStartDate);
        const multipleEndDateObject = new Date(multipleEndDate);
        const formattedMultipleStartDate =
          mutipleStartDateObject.toLocaleDateString("en-GB", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          });
        const formattedMultipleEndDate =
          multipleEndDateObject.toLocaleDateString("en-GB", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          });
        return (
          <Text numberOfLines={1}>
            {formattedMultipleStartDate} until {formattedMultipleEndDate}{" "}
          </Text>
        );

      case "ONEOFFDATE":
        const dateObject = new Date(oneOffDate);
        const formattedDate = dateObject.toLocaleDateString("en-GB", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        });

        return <Text numberOfLines={1}>{formattedDate}</Text>;
      default:
        return <Text>Error, no timing information</Text>;
    }
  };

  const colorScheme = useColorScheme();

  const emailString = `mailto:${contactEmail}?subject=${name}&body=Hi ${contactTitle} ${contactSurname}. I'm interested in signing up to ${name}. Please can you reply to this email with more information? Thanks!`;

  return (
    <ScrollView style={styles.root}>
      {isLoading ? (
        <View
          style={{
            flex: 1,
            backgroundColor: "transparent",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ActivityIndicator size="large" color={Colors.palette.logoGreen} />
        </View>
      ):(
        <>
          <View
            style={[
              styles.topBox,
              { backgroundColor: Colors[colorScheme].background },
            ]}
          >
            {/* badge */}
            <View style={styles.badgeIcon}>
              <DynamicBadge
                size={200}
                edgeColour={colour}
                engagementLevel={3}
                logo={downloadedImageURI}
              />
            </View>
            {/* meta data */}
            <View style={styles.metaDataContainer}>

              {/* badge name */}
              <View style={styles.nameContainer}>
                <BoldText style={[styles.badgeName, { color: Colors[colorScheme].text }]}>{name}</BoldText>
              </View>


              <View style={styles.textMetaDataContainer}>


                {/* available to */}



                {availableTo ? (
                <View style={styles.metaDataPairContainer}>
                  <BoldText
                    style={[styles.title, { color: Colors[colorScheme].text }]}
                  >
                    Available to:
                  </BoldText>
                  <Text
                    style={[
                      styles.metaData,
                      { color: Colors[colorScheme].textLessEmphasised },
                    ]}
                  >
                    {availableTo}
                  </Text>
                </View>):null}





                {/* category */}
                <View style={styles.metaDataPairContainer}>
                  <BoldText
                    style={[styles.title, { color: Colors[colorScheme].text }]}
                  >
                    Category:
                  </BoldText>
                  <Text
                    style={[
                      styles.metaData,
                      { color: Colors[colorScheme].textLessEmphasised },
                    ]}
                  >
                    {categoryName}
                  </Text>
                </View>


{/* location */}

                {location ? (
                  <View style={styles.metaDataPairContainer}>
                    <BoldText
                      style={[
                        styles.title,
                        { color: Colors[colorScheme].text },
                      ]}
                    >
                      Location:
                    </BoldText>
                    <Text
                      style={[
                        styles.metaData,
                        { color: Colors[colorScheme].textLessEmphasised },
                      ]}
                    >
                      {location}
                    </Text>
                  </View>)
                 : null}



                 {/* date */}
                <View style={styles.metaDataPairContainer}>
                  <BoldText
                    style={[styles.title, { color: Colors[colorScheme].text }]}
                  >
                    Date:
                  </BoldText>
                  <Text
                    style={[
                      styles.metaData,
                      { color: Colors[colorScheme].textLessEmphasised },
                    ]}
                  >
                    {DateTypeSelector()}
                  </Text>
                </View>



{/* available to */}

                {/* {availableTo ? (
                  <View style={styles.metaDataPairContainer}>
                    <BoldText
                      style={[
                        styles.title,
                        { color: Colors[colorScheme].text },
                      ]}
                    >
                      Available to:
                    </BoldText>
                    <View style={{ flexDirection: "row", marginLeft: 10 }}>
                      {availableTo}
                    </View>
                  </View>
                ) : null} */}





              </View>



            </View>






            {/* description */}
            <View style={[styles.descriptionContainer]}>
              <Text>{description}</Text>
            </View>
            {/* contact details */}
            <View
              style={{
                flex: 1,
                justifyContent: "space-between",
                flexDirection: "row",
                backgroundColor: "transparent",
                marginVertical: 10,
                marginHorizontal: 20,
              }}
            >
              <View style={styles.metaDataPairContainer}>
                <BoldText
                  style={[styles.title, { color: Colors[colorScheme].text }]}
                >
                  Contact:
                </BoldText>

                <View>
                  <Text
                    style={[
                      styles.metaData,
                      { color: Colors[colorScheme].textLessEmphasised },
                    ]}
                  >
                    {contactTitle} {contactSurname}
                  </Text>

                  <Pressable onPress={() => Linking.openURL(emailString)}>
                    <Text style={styles.hyperlink}>{contactEmail}</Text>
                  </Pressable>
                </View>
                <View style={{ width: 50, height: 50, marginHorizontal: 20 }}>
                  <TimelineIcon color={"#ccc"} />
                  <Image
                    style={[
                      styles.contactPhoto,
                      { borderColor: Colors[colorScheme].edging },
                    ]}
                    source={{ uri: downloadedContactPhotoURI }}
                  />
                </View>
              </View>
            </View>
          </View>
              {/* character attributes */}
          <View
            style={[
              styles.descriptionContainer,
              { backgroundColor: Colors[colorScheme].almostButtons },
            ]}
          >
            <BoldText
              style={[
                styles.title,
                { color: Colors[colorScheme].text, marginBottom: 10 },
              ]}
            >
              Character development:
            </BoldText>
            <ScrollView horizontal style={[styles.mappingRowContainer]}>
              {downloadedMappings.map((item) => (
                <MappedCriteria key={item.id} mapping={item} />
              ))}
            </ScrollView>
          </View>
        </>
      )}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    // paddingHorizontal: 10,
    // alignItems: "center",
    backgroundColor: "transparent",
    // width: 1000,
    // alignSelf: "center",
  },
  topBox: {
    width: "100%",
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    // margin:10,
  },
  hyperlink: {
    color: "blue",
    textDecorationLine: "underline",
    marginLeft: 10,
  },
  contactPhoto: {
    width: 50,
    height: 50,
    borderRadius: 25,
    borderWidth: 1,
    position: "absolute",
  },
  descriptionContainer: {
    marginBottom: 10,
    backgroundColor: "transparent",
    padding: 20,
    borderRadius: 10,
    // margin: 20,
  },
  mappingRowContainer: {
    flexDirection: "row",
    flex: 1,
    flexWrap: "wrap",
    // alignItems: "center",
    backgroundColor: "transparent",
  },
  title: {
    fontSize: 14,
    // fontWeight: "bold",
  },
  textMetaDataContainer: {
    marginHorizontal: 20,
    marginBottom: 10,
  },
  badgeIcon: {
    alignSelf: "center",
    height: 200,
    width: 200,
    marginBottom: 20,
  },
  metaDataContainer: {
    backgroundColor: "transparent",
    justifyContent: "center",
  },
  badgeName: {
    fontSize: 26,
  },
  nameContainer: {
    margin: 20,
  },
  metaData: {
    fontSize: 14,
    marginLeft: 10,
  },
  metaDataPairContainer: {
    flexDirection: "row",
    marginVertical: 2,
  },
});
