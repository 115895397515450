import { StyleSheet, Pressable, Image, ActivityIndicator } from "react-native";
import { View, Text } from "../../constants/Themed";
import { BoldText } from "../../constants/StyledText";
import Colors from "../../constants/Colors";
import useColorScheme from "../../hooks/useColorScheme";
import { RootTabScreenProps } from "../../navigation/types";
import * as WebBrowser from "expo-web-browser";
import DynamicBadge from "../../components/DynamicBadge";
import {Auth} from 'aws-amplify';
import Constants from 'expo-constants';
import { useEffect, useState,createContext, useContext } from "react";
import { UserContext } from "../../components/Context";
import { Feather } from '@expo/vector-icons';

export default function SettingsRootMobile({ navigation }) {
  function handleHelpPress() {
    WebBrowser.openBrowserAsync(
      "https://support.recognition.org.uk"
    );
  }

  function handleBugPress() {
    WebBrowser.openBrowserAsync(
      "https://forms.office.com/Pages/ResponsePage.aspx?id=4i86_GOrvEutrKyNOzdxNAZG0OuFsM1Ajh1DmdVoPvxUMkxYUEVaV1JYRUFXSDFFTDdETlRZQTEwTy4u"
    );
  }

  function handleDeletePress() {
    WebBrowser.openBrowserAsync(
      "https://forms.office.com/r/BH8gx43W8i"
    );
  }


const [currentLoggedInUser, setCurrentLoggedInUser] = useState();
const [isDone, setIsDone] = useState(false)
const colorScheme = useColorScheme();
const userDetailsFromUserContext = useContext(UserContext);
const getUserDetails = async () => {
  const { attributes } = await Auth.currentAuthenticatedUser();
  setCurrentLoggedInUser(attributes)
  setIsDone(true)
}

useEffect(() => {
 getUserDetails();
}, []);


// console.warn(user)

  return (
    <View style={[styles.root, {backgroundColor: Colors[colorScheme].almostBackground}]}>


      <Text>You are using Recognition version {Constants.manifest.version}</Text>

{/* <Text>Your email address is: {isDone ? currentLoggedInUser.email: <ActivityIndicator />}</Text> */}
{/* <Text>Your date of birth is: {userDetailsFromUserContext.dateOfBirth}</Text> */}
{/* <Text> Your ID in Recognition us: {currentLoggedInUser.sub}</Text> */}
{/* <Pressable onPress={() => navigation.navigate('TimelinesV2')} ><Text>Alternative Pupil Search System</Text></Pressable> */}
    


    <View style={{justifyContent: 'space-between', backgroundColor: 'transparent', }}>


    <View style={{backgroundColor: Colors.palette.logoGreen, padding:10, margin: 20, borderRadius:10}}>
      <Pressable onPress={handleHelpPress} style={{alignItems:'center'}}>
      <Feather name="help-circle" size={24} color={Colors[colorScheme].background} />
        <BoldText style={{color: Colors[colorScheme].background}}>Get Support</BoldText>
      </Pressable>
      </View>



      <View style={{backgroundColor: Colors.palette.logoGreen, padding:10, margin: 20, borderRadius:10}}>
      <Pressable onPress={handleBugPress} style={{alignItems:'center'}}>
      <Feather name="frown" size={24} color={Colors[colorScheme].background} />
        <BoldText style={{color: Colors[colorScheme].background}}>Report a Bug</BoldText>
      </Pressable>
      </View>


      <View style={{backgroundColor: Colors.palette.logoGreen, padding:10, margin: 20, borderRadius:10}}>
      <Pressable onPress={handleDeletePress} style={{alignItems:'center'}}>
      <Feather name="user-x" size={24} color={Colors[colorScheme].background} />
        <BoldText style={{color: Colors[colorScheme].background}}>Delete Account</BoldText>
      </Pressable>
      </View>



      <View style={{backgroundColor: Colors.palette.logoGreen, padding:10, margin: 20, borderRadius:10}}>
      <Pressable onPress={()=> Auth.signOut()} style={{alignItems:'center'}}>
      <Feather name="log-out" size={24} color={Colors[colorScheme].background} />
        <BoldText style={{color: Colors[colorScheme].background}}>Sign Out</BoldText>
      </Pressable>
      </View>


      </View>


    

  
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: "center",
    justifyContent: 'space-evenly',
    // backgroundColor: "transparent",
    alignSelf: "center",
    width: '100%',
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
