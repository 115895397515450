import { StyleSheet, Image, ScrollView, Pressable, Platform, Button } from "react-native";
import { View, Text } from "../../constants/Themed";
import { useRoute, useNavigation, RouteProp } from "@react-navigation/native";
import { BoldText } from "../../constants/StyledText";
import Colors from "../../constants/Colors";
import useColorScheme from "../../hooks/useColorScheme";
import React, { useEffect, useState } from "react";
import { RootStackParamList } from "../../navigation/types";
import { Video, AVPlaybackStatus } from 'expo-av';

//these imports are for the cloud query
import {API, graphqlOperation} from 'aws-amplify';
import {getCriteria} from '../../graphql/queries';
import {Criteria} from '../../API'

//this import is for getting the image out of storage
import {Storage} from 'aws-amplify'

export default function CriteriaDetailMobile() {
  const route = useRoute<RouteProp<RootStackParamList, 'Modal'>>();
  const navigationUsingHook = useNavigation();
  const {criteriaID} = route.params; 
  const colorScheme = useColorScheme();
  


//the cloud query and image from storage request
const [criteriaQueryResponse, setCriteriaQueryResponse] = useState<Criteria>([]); //state variabel for query data
const [downloadedImageURI, setDownloadedImageURI] = useState<string | null>(null); //state variable for imageURI
const [downloadedVideoURI, setDownloadedVideoURI] = useState<string | null>(null); //state variable for imageURI

const fetchCriteria = async () => {

  
  const response = await API.graphql(graphqlOperation(getCriteria, {id: criteriaID})); //run query
  setCriteriaQueryResponse(response.data.getCriteria) //store query data in state variable

  const file = await Storage.get(response.data.getCriteria.logoColourURI, {level: "public"}); //get file using query data
  setDownloadedImageURI(file) //store imageURI in state variable to be used elsewhere on the page
  
  const video = await Storage.get(response.data.getCriteria.videoURL, {level: "public"}); //get file using query data
  setDownloadedVideoURI(video) //store imageURI in state variable to be used elsewhere on the page
  
};
useEffect(()=> {
  fetchCriteria();
}, []);

const {description, synonyms, colour, name, strapline} = criteriaQueryResponse //destructure the text data to be used elsewhere on the page





useEffect(() => {
  navigationUsingHook.setOptions({
    headerLeft: () => (
  <Pressable style={{marginLeft: Platform.OS === 'web'? 20: 0}}
  onPress={() => navigationUsingHook.goBack()}>
    <Text style={{color:Colors[colorScheme].buttons}}>Cancel</Text></Pressable>
) 
  })

},[])



const video = React.useRef(null);


  return (
    <ScrollView style={styles.root}>

<View style={[styles.topBox, { backgroundColor: Colors[colorScheme].background }]}>

      {/* CRITERIA image */}
      <Image source={{ uri: 
        downloadedImageURI
        }} style={styles.badgeIcon} />









      <View style={styles.metaDataContainer}>
       
       
       
       
       
       
       {/* CRITERIA Name and strapline */}
        <View style={styles.nameContainer}>
          <BoldText style={styles.badgeName}>{name}</BoldText>
          <Text style={{color:colour}}>{strapline}</Text>
        </View>





{/* Video */}
        <View style={{flex: 1, justifyContent: 'center', marginTop: 5, marginBottom: 25,}}>
      <Video
        ref={video}
        style={styles.video}
        source={{
          uri: downloadedVideoURI
        }}
        useNativeControls
        shouldPlay
        resizeMode={"cover"}
      />
      
    </View>
        <View style={styles.textMetaDataContainer}>
       
            <Text
              style={[
                styles.metaData,
                { color: Colors[colorScheme].textLessEmphasised },
              ]}
            >
              {description}
            </Text>
          
          
         
        </View>
      </View>
</View>

 {/* SYNONYMS */}
 <View style={[styles.descriptionContainer, { backgroundColor: Colors[colorScheme].almostButtons }]}>
      <BoldText style={[styles.title, { color: Colors[colorScheme].text, textAlign: 'center' }]}>        
              {synonyms}
            </BoldText>
      </View>




      <View style={[styles.descriptionContainer, { backgroundColor: Colors[colorScheme].almostButtons }]}>
        {/* <Text>{opportunity.description}</Text> */}
      </View>

      
      
      
      
      
     


      
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    // padding: 20,
    // alignItems: "center",
    backgroundColor: "transparent",
    // width: 1000,
    // alignSelf: "center",
  },
  topBox:{
    width:'100%',
    borderBottomLeftRadius:20,
    borderBottomRightRadius:20,
  },
  descriptionContainer: {
    marginBottom: 20,
    
    padding: 20,
    borderRadius: 10,
    // margin: 20,
  },
  mappingRowContainer: {
    flexDirection: "column",
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  video: {
    alignSelf: 'center',
    width: "100%",
    aspectRatio: 16/9,
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 14,
    // fontWeight: "bold",
  },
  textMetaDataContainer: {
    marginHorizontal: 20,
    marginBottom: 20,
  },
  badgeIcon: {
    alignSelf: "center",
    height: 200,
    width: 200,
    marginBottom: 20,
  },
  metaDataContainer: {
    backgroundColor: "transparent",
    justifyContent: "center",
  },
  badgeName: {
    fontSize: 26,
  },
  nameContainer: {
    margin: 20,
  },
  metaData: {
    fontSize: 14,
    // marginLeft: 10,
  },
  metaDataPairContainer: {
    flexDirection: "row",
  },
});
function props(props: any) {
  throw new Error("Function not implemented.");
}

