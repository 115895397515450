import React from "react";
import { StyleSheet } from "react-native";
import { View, Text} from "../../constants/Themed";
import TimelineIcon from "../../../assets/icons/Timeline";
import Colors from '../../constants/Colors';
import useColorScheme from "../../hooks/useColorScheme";
import RecognitionLogoIndentIcon from "../../../assets/icons/RecognitionLogoIndent";
import { Feather } from "@expo/vector-icons";

const DrawerMenuItemAdmin = (props: any) => {
  const colorScheme = useColorScheme();
  return (
    <View style={styles.root}>
    <View style={styles.iconContainer}>
      {/* <RecognitionLogoIndentIcon /> */}
      {/* <HomeIcon color={props.color.color} /> */}
      <View style={{alignItems:'center', backgroundColor:'transparent'}}><Feather name="lock" size={20} color={props.color.color} /></View>
    </View>
    <View style={{backgroundColor: 'transparent'}}>
    <Text style={[styles.labelText, {color:Colors[colorScheme].text}]}>Administration</Text>
    </View>
    </View>
  );
};


export default DrawerMenuItemAdmin;

const styles = StyleSheet.create({
  root:{
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: "transparent",
  },
  iconContainer: {
    width: 30,
    height: 20,
    backgroundColor: "transparent",
  },
  labelText:{
    // color: Colors.palette.grey,
    fontSize: 16,
    paddingLeft: 5,
  },
});
