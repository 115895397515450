import {
  StyleSheet,
  Animated,
  FlatList,
  Pressable,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import { View, Text } from "../../constants/Themed";
import { BoldText } from "../../constants/StyledText";
import { RootTabScreenProps } from "../../navigation/types";
import Colors from "../../constants/Colors";
import * as WebBrowser from "expo-web-browser";
import DynamicBadge from "../../components/DynamicBadge";
import { Auth } from "aws-amplify";
import Constants from "expo-constants";
import { useEffect, useState, useRef } from "react";
import { Feather } from "@expo/vector-icons";
import useColorScheme from "../../hooks/useColorScheme";
// import Animated, {FadeIn, SlideInLeft, SlideInUp} from 'react-native-reanimated';
import CardOpportunity from "../../components/desktop/CardOpportunity";
import SearchBarDesktop from "../../components/desktop/SearchBar";
import { DataStore } from "@aws-amplify/datastore";
import { AwardedBadge } from "./../../models";

import { API, graphqlOperation } from "aws-amplify";
import HousePointsWeekly from "../../components/HousePointsWeekly";
import NewOpportunityForm from "../../components/NewOpportunityForm";
import { Picker } from "@react-native-picker/picker";
import CardOpportunityToEdit from "../../components/desktop/CardOpportunityToEdit";
// import * as mutations from './../../graphql/mutations';

//note, you have removed the line about limit from the top of this query (where it gets defined as an int) and added a limit of 2000. If you don't do this, you don't get all of the results!
export const listOpportunities = /* GraphQL */ `
  query ListOpportunities(
    $filter: ModelOpportunityFilterInput
   
    $nextToken: String
  ) {
    listOpportunities(filter: $filter, limit: 100000, nextToken: $nextToken) {
      items {
        id
        
        name
        
        logoURL
        location
        colour
        engagementLevel
        availableTo
        regularMonday
        regularTuesday
        regularWednesday
        regularThursday
        regularFriday
        regularStartTime
        
        dateType
        oneOffDate
        
        
        multipleStartDate
        multipleEndDate
        
        
        
       
       
        
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;


export default function BadgesList({ navigation }) {
  const { width } = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(true);
  const [masterData, setMasterData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [showForm, setShowForm] = useState(false);
  //the cloud query for a list (that has a filter applied here)

  const fetchOpportunitiesList = async () => {

    let nextToken = null;
    let items = [];


    do {
      const response = await API.graphql(
        graphqlOperation(listOpportunities, {
          filter: null, // Provide your filter if necessary
          nextToken: nextToken
        })
      );
  
      const { listOpportunities: data } = response.data;
      items = items.concat(data.items);
      nextToken = data.nextToken;
    } while (nextToken);




    // setBadgesRequested(!badgesRequested)
    // const response = await API.graphql(graphqlOperation(listOpportunities));

    setMasterData(items);

    setFilteredData(
      items.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      ) //sort alphateically
    );

    // setSortedData(response.data.listOpportunities.items
    // .sort((b,a) => a._lastChangedAt - b._lastChangedAt)

    //    //sort by date
    // );

    setIsLoading(false);

    

  };

  useEffect(() => {
    fetchOpportunitiesList();
    console.warn('filtered:', filteredData.length)
  }, []);

  const [search, setSearch] = useState("");
  const [yearWeWantState, setYearWeWantState] = useState("");

  const [currentLoggedInUser, setCurrentLoggedInUser] = useState();
  const [isDone, setIsDone] = useState(false);
  const [badgesRequested, setBadgesRequested] = useState(false);
  const colorScheme = useColorScheme();
  // const getUserDetails = async () => {
  //   const { attributes } = await Auth.currentAuthenticatedUser();
  //   setCurrentLoggedInUser(attributes)
  //   setIsDone(true)
  // }

  // useEffect(() => {
  //  getUserDetails();
  // }, []);

  const [searchBarToggle, setSearchBarToggle] = useState(true);

  // search button
  const ToggleSearchBar = () => {
    setSearchBarToggle(!searchBarToggle);
  };
  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <View
          style={{ right: (width - 1200) / 2, backgroundColor: "transparent" }}>
          <Pressable onPress={ToggleSearchBar}>
            <Feather
              name="search"
              size={24}
              color={
                searchBarToggle
                  ? Colors.palette.logoGreen
                  : Colors[colorScheme].buttons
              }
              style={{ marginRight: 10 }}
            />
          </Pressable>
        </View>
      ),
    });
  }, [ToggleSearchBar]);

  const searchBarAnim = useRef(new Animated.Value(-145)).current;
  useEffect(() => {
    if (searchBarToggle) {
      Animated.timing(searchBarAnim, {
        toValue: 0,
        duration: 150,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(searchBarAnim, {
        toValue: -70,
        duration: 150,
        useNativeDriver: true,
      }).start();
      setYearWeWantState("");
      setSearch("");
      setFilteredData(masterData);
    }
  }, [searchBarToggle]);

  console.warn(sortedData);

  // const [searchPhrase, setSearchPhrase] = useState("");
  const [clicked, setClicked] = useState(false);

  const FilterByYear = (year) => {
    if (yearWeWantState == year) {
      setYearWeWantState("");
    } else {
      setYearWeWantState(year);
      // console.warn('we want year', yearWeWantState)
    }
  };

  useEffect(() => {
    // console.warn('fired useeffect', yearWeWantState)
    searchFilter(search);
  }, [yearWeWantState]);

  const searchFilter = (text) => {
    if (text) {
      const newData = masterData.filter((item) => {
        const itemData = item.name ? item.name.toUpperCase() : "".toLowerCase();
        const textData = text.toUpperCase();
        const yearData = item.availableTo
          ? item.availableTo
          : "no avilableTo data";
        const yearWeWant = yearWeWantState;
        // console.warn(yearData)

        return itemData.indexOf(textData) > -1 && yearData.includes(yearWeWant);
      });

      setFilteredData(newData);
      setSearch(text);
    } else if (yearWeWantState) {
      // console.warn('else if fired', yearWeWantState)

      const newData = masterData.filter((item) => {
        const itemData = item.name ? item.name.toUpperCase() : "".toLowerCase();
        const textData = text.toUpperCase();
        const yearData = item.availableTo
          ? item.availableTo
          : "no avilableTo data";
        const yearWeWant = yearWeWantState;
        // console.warn(yearData)

        return itemData.indexOf(textData) > -1 && yearData.includes(yearWeWant);
      });

      setFilteredData(newData);
      setSearch(text);
    } else {
      setFilteredData(masterData);
      setSearch(text);
    }
  };

  return (
    <View
      style={[
        styles.root,
        { backgroundColor: Colors[colorScheme].almostBackground },
      ]}>
       <Text> {filteredData.length}</Text>
      {/* search bar */}
      <Animated.View
        style={{
          transform: [{ translateY: searchBarAnim }],
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "transparent",
          width: 1000,
        }}>
        <Picker
          style={{
            backgroundColor: Colors[colorScheme].background,
            borderWidth: 1,
            margin: 10,
            padding: 10,
            borderColor: Colors[colorScheme].edging,
            borderRadius: 8,
            fontSize: 16,
            fontFamily: "avenir-next",
            color: Colors[colorScheme].textLessEmphasised,
          }}
          // itemStyle={{fontFamily: 'avenir-next', color: 'red'}}
          selectedValue={yearWeWantState}
          onValueChange={(itemValue, itemIndex) =>
            setYearWeWantState(itemValue)
          }>
          <Picker.Item label="Year Group" value="" />
          <Picker.Item label="Reception" value="Reception" />
          <Picker.Item label="Year 1" value="1" />
          <Picker.Item label="Year 2" value="2" />
          <Picker.Item label="Year 3" value="3" />
          <Picker.Item label="Year 4" value="4" />
          <Picker.Item label="Year 5" value="5" />
          <Picker.Item label="Year 6" value="6" />
          <Picker.Item label="Year 7" value="7" />
          <Picker.Item label="Year 8" value="8" />
          <Picker.Item label="Year 9" value="9" />
          <Picker.Item label="Year 10" value="10" />
          <Picker.Item label="Year 11" value="11" />
        </Picker>

        {/* <Picker
style={{ borderWidth: 1, margin: 10, padding:10, borderColor: 'lightgrey', borderRadius: 8, fontSize: 16, fontFamily: 'avenir-next', color: Colors[colorScheme].textLessEmphasised }}
// itemStyle={{fontFamily: 'avenir-next', color: 'red'}}  
selectedValue={selectedLanguage}
  onValueChange={(itemValue, itemIndex) =>
    setDayWeWantState(itemValue)
  }>
  <Picker.Item label="Regular Day" value="" />
  <Picker.Item label="Monday" value="Monday" />
  <Picker.Item label="Tuesday" value="Tuesday" />
  <Picker.Item label="Wednesday" value="Wednesday" />
  <Picker.Item label="Thursday" value="Thursday" />
  <Picker.Item label="Friday" value="Friday" />
</Picker> */}
        <View style={{ backgroundColor: "transparent" }}>
          <SearchBarDesktop
            searchPhrase={search}
            setSearchPhrase={(text) => searchFilter(text)}
            clicked={clicked}
            setClicked={setClicked}
          />
        </View>

        {/* <Pressable onPress={()=>FilterByYear(9)}><Text>Year 19</Text></Pressable> */}
        {/* <Pressable onPress={()=>FilterByYear(10)}><Text>Year 10</Text></Pressable><Pressable onPress={()=>FilterByYear(11)}><Text>Year 11</Text></Pressable> */}
      </Animated.View>

      {isLoading ? (
        <View style={{ margin: 100, backgroundColor: "transparent" }}>
          <ActivityIndicator />
        </View>
      ) : (
        <FlatList
          data={filteredData}
          numColumns={3}
          // renderItem={renderItem}

          renderItem={({ item }) => (
            <CardOpportunityToEdit opportunity={item} />
          )}
          keyExtractor={(item) => item.id}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: "center",
    // justifyContent: "center",
    backgroundColor: "transparent",
    width: "100%",
    alignSelf: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
