import { Platform, StyleSheet, useWindowDimensions,} from 'react-native'
import React, { useEffect,useContext } from 'react'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { StatusBar } from 'expo-status-bar'
import MobileNavigation from '../navigation/Mobile'
import DesktopNavigation from '../navigation/Desktop'
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import useColorScheme from "../hooks/useColorScheme";
import { UserContext } from "../components/Context";
import { API } from "aws-amplify";

export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      pushToken
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

const NavigationCheck = () => {
    const windowSize = useWindowDimensions();
    const colorScheme = useColorScheme();
    const userDetailsFromUserContext = useContext(UserContext);
    const {id, _version} = userDetailsFromUserContext
    
    useEffect(()=> {
   const registerForPushNotificationsAsync = async () => {

        if (Device.isDevice) {


            const writeTokenToDatabase = async (tokenDetailsToUpload: { id: any; pushToken: string; _version: number }) => {
               
                const tryUpload = await API.graphql({ query: updateUser, variables: {input:tokenDetailsToUpload }});
                console.warn(tryUpload)
            }

          const { status: existingStatus } = await Notifications.getPermissionsAsync();
          let finalStatus = existingStatus;
          if (existingStatus !== 'granted') {
            const { status } = await Notifications.requestPermissionsAsync();
            finalStatus = status;
          }
          if (finalStatus !== 'granted') {
            alert('Failed to get push token for push notification!');
            return;
          }
          const token = (await Notifications.getExpoPushTokenAsync()).data;
    
          const tokenDetailsToUpload = {
            id: id,
            pushToken: token,
            _version: _version, //ok this was a ballache. you have to send the version with the update or it doesn't update - it just queries. 
          }
      
          writeTokenToDatabase(tokenDetailsToUpload)

        
        } else {
          alert('Must use physical device for Push Notifications');
        }
      
        if (Platform.OS === 'android') {
          Notifications.setNotificationChannelAsync('default', {
            name: 'default',
            importance: Notifications.AndroidImportance.MAX,
            vibrationPattern: [0, 250, 250, 250],
            lightColor: '#FF231F7C',
          });
        }
        return 
      };
      if (Platform.OS === 'android' || Platform.OS === 'ios') {
        registerForPushNotificationsAsync()
      } else {
        console.warn('skipping async function to get token')
      }
    },[])

  return (
    <SafeAreaProvider>
          {Platform.OS === "ios" ||
          Platform.OS === "android"
          || windowSize.width < 1200
           ? (
            <MobileNavigation colorScheme={colorScheme} />
          ) : (
            <DesktopNavigation colorScheme={colorScheme} />
          )}
          <StatusBar />
        </SafeAreaProvider>
  )
}

export default NavigationCheck

const styles = StyleSheet.create({})