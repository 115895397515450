import {
  Image,
} from "react-native";
import React, { useEffect, useState } from "react";
// import * as crypto from 'crypto';
// import * as crypto from 'crypto-browserify'
import sha256 from 'crypto-js/sha256';
import hmacSHA256 from 'crypto-js/hmac-sha256'
import Hex from 'crypto-js/enc-hex'


// import {
//   SecretsManagerClient,
//   GetSecretValueCommand,
// } from "@aws-sdk/client-secrets-manager";


const SignedURLImage = ({filter, fileKey}, ) => {

  const [signedURLInState, setSignedURLInState] = useState("");

  const getSignedURL = () => {

  //   const secret_name = "recognition/staging/serverlessimagehandler";

  //   const client = new SecretsManagerClient({
  //     region: "eu-west-2",
  //   });
    
  //   let response;
    
  //   try {
  //     response = await client.send(
  //       new GetSecretValueCommand({
  //         SecretId: secret_name,
  //         VersionStage: "AWSCURRENT", // VersionStage defaults to AWSCURRENT if unspecified
  //       })
  //     );
  //   } catch (error) {
  //     // For a list of exceptions thrown, see
  //     // https://docs.aws.amazon.com/secretsmanager/latest/apireference/API_GetSecretValue.html
  //     throw error;
  //   }
    
  //   const secret = response.SecretString;

  const apiUrl = 'https://d1cctrbswiuu24.cloudfront.net'
  const secret = "^'{`Cd4;wL'mU[7~s75M:Z-6-L%aX:Qb"
  // const filter = '/filters:quality(10)/filters:grayscale()/fit-in/300x500/'
  // const key = '/public/organisation-assets/carrmanor/staffPhotos/DWinn.jpg'; // Add the first '/' to path.
  const path = filter.concat(fileKey)
  // const signature = crypto
  //   .createHmac("sha256", secret)
  //   .update(path)
  //   .digest("hex")
  //   console.warn('SIGNATURE=', signature)
  //   const fullURL = apiUrl.concat(filter,fileKey,'?signature=',signature)
  //   setSignedURLInState(fullURL)
  //   console.warn('fullURL:', fullURL)
  // };


  const signature = Hex.stringify(hmacSHA256(path, secret))
  console.warn('SIGNATURE=', signature)
    const fullURL = apiUrl.concat(filter,fileKey,'?signature=',signature)
    setSignedURLInState(fullURL)
    console.warn('fullURL:', fullURL)
  };
  useEffect(() => {
    getSignedURL();
    console.warn('urlinstate:', signedURLInState)
    console.warn('filekey:', fileKey)
  }, []);

  return (
      <Image
        source={{ uri: signedURLInState }}
        style={{ flex: 1, height: '100%', width: '100%', resizeMode:'cover' }}
      />
  );
};

export default SignedURLImage;

