const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';

//to get this to work, you need to import 
// import useColorScheme from '../hooks/useColorScheme';
// and import Colors from '../constants/Colors';
// you then need to use the hook: const colorScheme = useColorScheme(); (which goes before your return)
// you can then reference the colour below using: color={Colors[colorScheme].textNotAsStrong} (for example)

export default {
  light: {
    text: '#3c3c3b', //AJL: changed from black to the same darkGrey found in the palette
    textLessEmphasised: '#575756',
    background: '#fff',
    // almostBackground: '#fdfdfd',
    almostBackground: '#f2f2f2',
    buttons: '#3c3c3b',
    almostButtons: '#f2f2f2',
    edging: '#ccc',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
    
  },
  dark: {
    text: '#fff',
    textLessEmphasised: '#919191',
    background: '#010101',
    almostBackground: '#121212',
    buttons: '#ccc',
    almostButtons: '#3c3c3b',
    edging: '#3c3c3b',
    tint: tintColorDark,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,

  },
  palette: {
    logoGreen: '#51cc85',
    logoBlue: '#0097aa',
    secondaryGreen: '#95eba5',
    secondaryBlue: '#1faaaa',
    darkGrey: '#3c3c3b',
    offWhite: '#f8f8f8',
    tertiaryPink: '#ed4f73',
    menuActive: '#06aca8',
    menuInactive: '#8cd4d1',
    grey: '#b0b0b0',
    robinson: '#77559c',
    trueman: '#f4c82d',
    tomlinson: '#49AF79',
    bremner: '#127abc',
    softRed: '#F47174',
  }
};
