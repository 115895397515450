import { StyleSheet, FlatList, TextInput, Pressable, useWindowDimensions, Animated} from 'react-native';
import { View, Text } from "../../constants/Themed";
import {useState, useRef, useEffect,useContext} from 'react';
import { RootTabScreenProps } from '../../navigation/types';
import DUMMYQUERYCONNECTIONS from '../../models/DummyQueryConnections.json';
import ThumbnailTimeline from '../../components/ThumbnailTimeline';
import { Feather } from "@expo/vector-icons";
import Colors from '../../constants/Colors';
import useColorScheme from "../../hooks/useColorScheme";
import  SearchBarDesktop from "../../components/desktop/SearchBar";
import { API, graphqlOperation } from "aws-amplify";
import CardTimeline from "../../components/CardTimeline";
import { BoldText } from "../../constants/StyledText";
import { UserContext } from "../../components/Context";

//note, you have removed the line about limit from the top of this query (where it gets defined as an int) and added a limit of 2000. If you don't do this, you don't get all of the results!
export const listUsers = /* GraphQL */ `
  query ListUsers($filter: ModelUserFilterInput, $nextToken: String) {
    listUsers(filter: $filter, limit: 9999, nextToken: $nextToken) {
      items {
        id
        surname
        aaa
        preferredFirstName
        type
        photoURL
        isActive
        Enrolments {
          items {
            groupingData1
            groupingData2
            groupingData3
            organisationID
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;


export const listConnections = /* GraphQL */ `
  query ListConnections(
    $filter: ModelConnectionFilterInput
   
    $nextToken: String
  ) {
    listConnections(filter: $filter, limit: 9999, nextToken: $nextToken) {
      items {
        id
        connectorID
        connecteeID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;



export default function TimelinesRoot({ navigation }: RootTabScreenProps<'ShoutOuts'>) {

  const userDetailsFromUserContext = useContext(UserContext);
  const {width} = useWindowDimensions();
  const [searchBarToggle, setSearchBarToggle] = useState(true)
  const colorScheme = useColorScheme();
  // const [usersList, setUsersList] = useState([]);
  // const [searchPhrase, setSearchPhrase] = useState("");
  const [clicked, setClicked] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [search, setSearch] = useState('');
  const [connectionsListData, setConnectionsListData] = useState([]);

  const fetchUsersList = async () => {
    const response = await API.graphql(
      graphqlOperation(listUsers, {
        filter: {
         
              type: { ne: "ALUMNI", eq: "CHILD" },
         
        },
      })
    );
    // if (!isCancelled.current) {
      // setUsersList(response.data.listUsers.items);
      setFilteredData(response.data.listUsers.items);
      setMasterData(response.data.listUsers.items);
    // }
  };
  // const [firstRender,setFirstRender] = useState(true); //run the query only when the user starts typing, not when the screen first loads
  

  const fetchConnectionsList = async () => {
    const response = await API.graphql(
      graphqlOperation(listConnections, {
        filter: {

          connectorID: {eq:userDetailsFromUserContext.id},
            },
          
      })
    );
    
    setConnectionsListData(response.data.listConnections.items);
  };

  
  useEffect(() => {
    
    // if (firstRender.current) {
    //   firstRender.current = false;
    // } else {
        fetchUsersList(); 
        fetchConnectionsList();
    // }
    // return () => {
      // isCancelled.current = true;
    // };
  }, [
    // search
  ]);
  // },[]);







const ToggleSearchBar = () => {
  setSearchBarToggle(!searchBarToggle)
} 


useEffect(() => {
  navigation.setOptions({
    headerRight:()=>(

    
      <View style={{right: (width - 1200)/2, backgroundColor: 'transparent' }}>
      <Pressable
      onPress={ToggleSearchBar}
      ><Feather
            name="search"
            size={24}
            color={searchBarToggle ?  Colors.palette.logoGreen : Colors[colorScheme].buttons}
            style={{ marginRight: 10 }}
          /></Pressable></View>

    )
  })
}, [ToggleSearchBar]);

  const searchBarAnim = useRef(new Animated.Value(-145)).current;
  useEffect(() => {
    if (searchBarToggle) {
      Animated.timing(searchBarAnim, {
        toValue: 0,
        duration: 150,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(searchBarAnim, {
        toValue: -70,
        duration: 150,
        useNativeDriver: true,
      }).start();
      setSearch('');
      setFilteredData(masterData);
    }
  }, [searchBarToggle]);

  




  // useEffect(() => {
  //   // console.warn(firstRender)
  //   // console.warn(firstRender.current)
  //   if (firstRender) {
  //     setFirstRender(false);
  //     // console.warn(firstRender)
  //   }
  // }, [clicked]);






  const numberOfColumns = 3 //AJL: You will want to make this dynamic at some point
  
  
  // const renderItem = ({item}: any) => {
  //   if (searchPhrase === "") {
  //     return <ThumbnailTimeline connection={item} />;
  //   }
  //   if (item.name.toUpperCase().includes(searchPhrase.toUpperCase().trim().replace(/\s/g, ""))) {
  //     return <ThumbnailTimeline connection={item} />;
  //   }
  
  // };
  

  const searchFilter = (text) => {
    if(text) {
      const newData = masterData.filter((item) => {
        const itemData = item.aaa ? item.aaa.toUpperCase() : ''.toLowerCase()
        const textData = text.toUpperCase();
        // console.warn(item.Enrolments.groupingData3)
        return itemData.indexOf(textData) > -1; 
      });
      //new
      // const newDataYear9Only = newData.filter((item) => )

      
      //end of new
      setFilteredData(newData); 
      setSearch(text);
    }
    else {
      setFilteredData(masterData);
      setSearch(text);
    }
  }




  
  return (
    <View style ={{flex: 1, width: '100%', backgroundColor: Colors[colorScheme].almostBackground}}>
    <View style={[styles.root, {backgroundColor: Colors[colorScheme].almostBackground}]}>



{/* search bar */}
<Animated.View style={{ transform: [{ translateY: searchBarAnim }] }}>
<SearchBarDesktop
  searchPhrase={search}
  setSearchPhrase={(text) => searchFilter(text)}
  clicked={clicked}
  setClicked={setClicked}

  />
</Animated.View>


<Animated.View style={{ transform: [{ translateY: searchBarAnim }], flex: 1 }}>








{/* Flatlist for the results that only displays when some search criteria has been typed. */}
{search ? 
    <FlatList
      data={filteredData}
      // horizontal={true}
      numColumns = {numberOfColumns}
      // renderItem={renderItem}
      renderItem={({ item }) => (
        <CardTimeline connection={item} />
      )}
      keyExtractor={(item) => item.id}
    />
    : connectionsListData.length > 0 ? 
    
    // <View style={{backgroundColor: 'transparent'}}>
    //     <BoldText
    //     style={{
    //       color: Colors[colorScheme].text,
    //       fontSize: 16,
    //       alignSelf: "center",
    //       padding: 15,
    //     }}
    //   >
    //     {userDetailsFromUserContext.title} {userDetailsFromUserContext.surname}'s Coaching Group
    //   </BoldText>
    // <View style={{width:'100%', flex:1, backgroundColor:'red'}}>
        <FlatList
          data={connectionsListData}
          numColumns={numberOfColumns+2}
          // horizontal
          renderItem={({ item }) => (
            <ThumbnailTimeline id={item.connecteeID} />
          )}
          // renderItem={renderItem}
          keyExtractor={(item) => item.id}
          key={numberOfColumns + 2}
        /> 
        //  </View>
        
        : null
    
    
    
    }
    
    
    
    </Animated.View>

  </View>
  </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: 'center',
    // backgroundColor: 'transparent',
    width: 1000,
    alignSelf: 'center',
  },
  
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});






