import { StyleSheet, Pressable, FlatList, ActivityIndicator } from "react-native";
import { View, Text } from "../../constants/Themed";
import Colors from "../../constants/Colors";
import { Feather } from "@expo/vector-icons";
import useColorScheme from "../../hooks/useColorScheme";
import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import ThumbnailShoutOut from '../../components/desktop/ThumbnailShoutOut';

export const listMoments = /* GraphQL */ `
  query ListMoments(
    $filter: ModelMomentFilterInput
    
    $nextToken: String
  ) {
    listMoments(filter: $filter, limit: 1000000, nextToken: $nextToken) {
      items {
        id
        dateUploaded
        dateMomentHappened
        caption
        contributorID
        Contributor {
            id
            preferredSurname
            preferredFirstName
            photoURL
            title
        }
        actuallyUploadedByID
        Media {
          items {
            id
            s3Key
            Tags {
              items
              {
                id
                userID
                User {
                  id
                  preferredSurname
                  preferredFirstName
                  photoURL
                  title
                }
              }
            }
          }
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        
      }
      nextToken
      startedAt
    }
  }
`;



export default function AdminMomentList({ navigation }) {
  const colorScheme = useColorScheme();
  const [isLoading, setIsLoading] = useState(true);
  const [masterData, setMasterData] = useState([]);
  const numberOfColumns = 4
  const fetchMomentList = async () => {
    const response = await API.graphql(
      graphqlOperation(listMoments)
    );
      const sortedData = response.data.listMoments.items.sort(
        function(a, b) {
          return new Date(b.dateMomentHappened) - new Date(a.dateMomentHappened);
      }
      )

    setMasterData(sortedData);

    // setFilteredData(response.data.listOpportunities.items
    //   .sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)) //sort alphateically
    //   );

      // setSortedData(response.data.listOpportunities.items
      // .sort((b,a) => a._lastChangedAt - b._lastChangedAt)
      //    //sort by date
      // );

    setIsLoading(false)
  };





  useEffect(() => {

      fetchMomentList();
    
  }, []);

  console.warn(masterData)


  if (masterData.length<1) {
    return <ActivityIndicator />;
    }



  return (
    <View
      style={[
        styles.root,
        { backgroundColor: Colors[colorScheme].almostBackground },
      ]}
    >

      <FlatList
      data={masterData}
      numColumns = {numberOfColumns}
      renderItem={({ item }) => <ThumbnailShoutOut shoutout={item} />} 
      keyExtractor={(item) => item.id}
      
    />
      {/* <Pressable
        onPress={() => navigation.navigate("AdminRoot")}
        style={{
          alignItems: "center",
          width: 120,
          backgroundColor: Colors.palette.logoGreen,
          padding: 10,
          margin: 50,
          borderRadius: 10,
        }}
      >
        <Feather name="lock" size={48} color={Colors[colorScheme].background} />
        <Text
          style={{ textAlign: "center", color: Colors[colorScheme].background }}
        >
          Return to Administration
        </Text>
      </Pressable> */}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    width: "100%",
    alignSelf: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
