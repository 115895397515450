import React from "react";
import Svg, { Path, Ellipse } from "react-native-svg";

const TruemanIcon = (color: any) => {


  return (
    
    <Svg id="Layer_1" data-name="Layer 1" viewBox="0 0 300 300">
      <Path  d="M233.7,57.5c-4.4,0.6-8.8,0.3-13.2-0.2c-2.2-0.3-4.5-0.4-6.8-0.4c-29.2,0-52.9,22.9-52.9,51.1
		c0,1.3,0,2.5,0.1,3.8c-0.5,2-1,3.5-1.3,4.3c-4,7.9-14.3,11.8-21.5,8.2c-7.2-3.6-10.2-14.5-4.6-21.3c0.4-0.5,0.8-1,1.3-1.4
		c0.2-0.2,0.3-0.3,0.5-0.5c0.3-0.3,0.6-0.6,0.9-0.9c0.2-0.2,0.4-0.3,0.6-0.5c0.3-0.2,0.6-0.5,0.9-0.7c0.2-0.2,0.5-0.3,0.7-0.5
		c0.3-0.2,0.6-0.4,0.9-0.7c0.3-0.2,0.5-0.4,0.8-0.5c0.3-0.2,0.6-0.4,1-0.6c0.3-0.2,0.6-0.4,0.9-0.6c0.4-0.2,0.7-0.5,1.1-0.7
		c0.5-0.3,0.9-0.6,1.4-0.9c0.3-0.2,0.6-0.4,0.9-0.6c0.3-0.2,0.7-0.4,1-0.7c0.3-0.2,0.6-0.4,0.9-0.6c0.3-0.2,0.7-0.5,1-0.7
		c0.3-0.2,0.6-0.4,0.9-0.6c0.3-0.2,0.7-0.5,1-0.7c0.3-0.2,0.6-0.4,0.8-0.6c0.3-0.3,0.7-0.5,1-0.8c0.3-0.2,0.6-0.5,0.8-0.7
		c0.3-0.3,0.6-0.6,1-0.9c0.3-0.2,0.5-0.5,0.8-0.7c0.3-0.3,0.6-0.7,0.9-1c0.2-0.3,0.5-0.5,0.7-0.8c0.3-0.4,0.6-0.7,0.9-1.1
		c0.2-0.3,0.5-0.5,0.7-0.8c0.3-0.4,0.6-0.9,0.9-1.3c0.2-0.3,0.4-0.5,0.6-0.8c0,0,0-0.1,0.1-0.1c0.8-1.3,1.7-2.5,2.6-3.8
		c0.2-0.3,0.4-0.6,0.5-0.9c2.5-4.8,5.5-20.7-1.3-24.8c0.2,5.7-4.8,8.5-9.4,10.8c-0.5,0.2-1,0.5-1.6,0.8
		C139,68.4,129,76.5,121.8,86.8c-2.4,3.4-4.6,7.1-6.1,10.6c-7.2,17-11.7,39.1-11.7,62.6c0,41.7,14.2,79.2,32.6,91
		c2.5,1.6,5.4,2.2,8.3,2.1c30.2-1.9,56.5-18.2,72.2-42c3.9-6,7.2-12.4,9.6-19.2c1.8-5,3.3-10.3,4.2-15.7c0.3-2.5,0.6-4.9,0.7-7.4
		c0.3-4.4,1.7-8.6,4.2-12.1c1-1.4,2.2-2.7,3.5-3.9c1.8-1.6,3.9-3,6.2-3.9c0.1,0,0.2-0.1,0.3-0.1c0,0-0.1,0-0.1,0
		c-2.4-0.3-5-0.5-7.5-0.5c-0.3,0-0.6,0-0.9,0c-2,0-4.5,0.1-7.1,0.3c-0.9,0.1-1.8,0.2-2.8,0.3c-7.8,1.3-17,5.3-20.5,9.8
		c-7.6,9.9-14.3,42.7-38.1,32.2c-1-0.5-2-1.1-3-1.7c-7.8-5.3-8.7-16.5-4.5-24.9c5.2-10.4,20.7-12.6,32.7-13.6
		c2.3-0.2,4.5-0.2,6.8-0.1c0.5,0,1,0,1.5,0c14.9,0,27-8.3,27-18.5c0-3.8-1.7-7.4-4.6-10.3c-6.1-6.3-4.6-16.7,3.3-20.3
		c9.4-4.2,22.5-5.2,27.3-2.1c-20.5-32.7-59.2-5-60.4-0.2c0-0.4,0-1,0.1-1.7c1.3-10.6,9.5-19,20-20.9c13-2.4,26-2.8,34.4-10.8
		c4.3-4,4.3-13.9,4.7-18.1c-0.7,0.8-1.4,1.5-2.1,2.2c-1.1,1-2.3,1.9-3.5,2.7c-1.2,0.8-2.4,1.5-3.7,2c-0.4,0.2-0.9,0.3-1.3,0.5
		c-0.4,0.2-0.9,0.4-1.3,0.5C239.6,56.5,236.6,57.2,233.7,57.5z M193,128.7c-3.8,3.8-9.2,5.5-13.6,2.4c-4.4-3.1-5.6-9.2-2.5-13.6
		c2.3-3.3,11.7-5.4,16.5-11.9C192.6,114.6,197,124.7,193,128.7z" fill={color.color}/>
	<Path  d="M104.1,238.6c4.9,7.4,12.8,12.5,21.6,13.7l0.1,0c-5.4-5.2-10.4-12.3-14.8-21.2c-9.6-19.4-14.8-44.7-14.8-71.2
		c0-26.5,5.3-51.8,14.8-71.2c4.5-9.1,9.6-16.3,15.2-21.6c0,0-0.1,0-0.1,0C117,68.5,109,73.8,104,81.5c-0.3,0.5-0.6,1-0.9,1.5
		c-13.1,20.6-20.3,48-20.3,77c0,29,7.2,56.4,20.3,77C103.4,237.6,103.7,238.1,104.1,238.6z" fill={color.color}/>
	<Path  d="M95.8,78.4c0.5-0.7,0.9-1.5,1.4-2.2c-30.7,15.3-51.8,47-51.8,83.7c0,36.5,20.9,68.1,51.5,83.5
		c-0.4-0.6-0.8-1.1-1.1-1.7c-13.9-22-21.6-51-21.6-81.6C74.3,129.3,81.9,100.4,95.8,78.4z" fill={color.color}/>
	<Path d="M241.3,105.8c-5.2,1.5-8.2,6.9-6.8,12.1c1.5,5.2,6.9,8.2,12.1,6.8c5.2-1.5,18.8-10.3,17-18
		C257.1,113.3,246.5,104.4,241.3,105.8z" fill={color.color}/>
	<Path d="M260.6,64.3c-1.1,2.9-2.6,5.7-5,7.9c-1.3,1.2-2.6,2.3-4,3.2c16.5,22.4,25.8,50.5,24.3,80.7
		C272.9,220.8,220.5,273,155.9,276c-73,3.3-133.3-55.8-131.9-128.4C25.2,81.3,78.4,26.8,144.5,24c26.8-1.1,51.8,6.1,72.7,19.3
		c6.3,4,13.9,5.5,21.2,3.8c1.9-0.5,3.6-1,4.7-1.6C217.6,22.8,183.9,9.3,147,10C73.8,11.6,13.5,70.3,10.2,143.4
		c-3.7,79.9,60,146,138.9,146.6c77,0.5,140.5-62.2,140.9-139.3C290.2,118.2,279.2,88.2,260.6,64.3z" fill={color.color}/>

    
    </Svg>
    
        
  );
};

export default TruemanIcon;
